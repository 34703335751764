import React, { useState, Fragment, useEffect }from 'react';
import { Outlet, Link } from "react-router-dom";
import axios from 'axios';
import { kakaoID } from './data/snsLogin';

function Header(){
    const [loginOk, setLoginOk] = useState(false);
    const [cardOk, setCardOk] = useState(false);
    const [adminOk, setAdminOk] = useState(false);
    const [menuBar, setMenuBar] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    // 한번 실행
    useEffect(()=>{
        // 카카오 로그인
        let kakaoLogin = localStorage.getItem('kakaoLogin')
        if(kakaoLogin){
            const urlParams = new URL(window.location.href).searchParams;
            const code = urlParams.get('code');
            if(code){
                axios.get(`https://babacard.co.kr/babacard/user/kakao/oauth?code=${code}`)
                .then((res)=>{
                    axios.defaults.headers.common['Authorization'] = `Bearer ${code}`;
                    const ACCESS_TOKEN = res.headers.authorization;
                    const KAKAO_ID = res.data.id;
                    localStorage.setItem('kakaoToken', ACCESS_TOKEN);
                    localStorage.setItem('kakaoID', KAKAO_ID);
                    window.location.href='./';
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
            }
            let kakaoToken = localStorage.getItem('kakaoToken');

            // if(kakaoLogin && kakaoID && kakaoToken){
            if(kakaoLogin && kakaoID !=='undefined' && kakaoToken !=='undefined'){
                setLoginOk(true);
                axios.get(`https://babacard.co.kr/babacard/api/v1/wedding/`, {
                    params:{
                        memberId:kakaoID
                    }
                })
                .then((res)=>{
                    if(res.data.length > 1){
                        if(res.data[res.data.length-1].weddingKey){
                            setCardOk(true);
                            localStorage.setItem('cardNo',res.data[res.data.length-1].weddingKey)
                        }
                    }else if(res.data[0].weddingKey){
                        setCardOk(true);
                        localStorage.setItem('cardNo',res.data[0].weddingKey)
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
            }
        }
        // 어드민 로그인
        if(sessionStorage.getItem('admin')){
            setAdminOk(true);
        }

    },[])

    useEffect(()=>{
        window.addEventListener('scroll', updateScroll);
    });
    let cardNo = localStorage.getItem('cardNo');
    let menuList = [
        // 기본
        {
            name:'청첩장 만들기',
            url:'/login'
        },
        {
            name:'로그인',
            url:'/login'
        },
    ]
    if(loginOk){
        if(cardOk){
            // 청첩장이 있을경우
            menuList = [
                {
                    name:'청첩장 이동',
                    url:`/card?no=${cardNo}`
                },
                {
                    name:'청첩장 관리',
                    url:'/modifyCard'
                },
                {
                    name:'청첩장 정보',
                    url:'/infoCard'
                },
                {
                    name:'로그아웃',
                    url:'/logout'
                },
            ]
        }else{
            // 로그인된 상태
            menuList = [
                {
                    name:'청첩장 만들기',
                    url:'/makeCard'
                },
                {
                    name:'로그아웃',
                    url:'/logout'
                },
            ]
        }
    }
    if(adminOk){
        // 관리자 로그인
        menuList = [
            {
                name:'회원관리',
                url:'/admin'
            },
            {
                name:'로그아웃',
                url:'/logout'
            },
        ]
    }

    return(
        <Fragment>  
            <header className={scrollPosition < 80 ? 'header' : 'header change'}>
                <h1 className='hide'>네비게이션</h1>
                <nav>
                    <Link to={'/'}>
                        <div className='logo_box'></div>
                    </Link>
                    <div className={menuBar ? 'menu_area active' : 'menu_area'} >
                        <div><a href='https://babacompany.co.kr'>바바컴퍼니</a></div>
                        {menuList.map((item, idx) => {
                            return(
                                <div key={idx}>
                                    <Link to={item.url}>{item.name}</Link>
                                </div>
                            )
                        })}
                        <Outlet />
                    </div>
                    <div className={menuBar ? 'menu_bar active' : 'menu_bar'} onClick={()=>{setMenuBar(!menuBar);}}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </nav>
            </header>
            <div className='block' style={{background:'var(--color-primary)'}}></div>
        </Fragment>
    )
}
export default Header;