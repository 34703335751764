export const handleSetting = (setting, setSetting, e) => {
    if(e.target.type === 'checkbox'){
        if(e.target.checked){
            setSetting({ ...setting, [e.target.name]: 'Y' });
        }else{
            setSetting({ ...setting, [e.target.name]: ' ' });
        }
    }else{
        setSetting({ ...setting, [e.target.name]: e.target.value });
    }
}