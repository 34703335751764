window.Indata = {
    mainSlideData:[
        {
            title:[
                '결혼에',
                '필요한 모든 것!',
                '바바에 다~있다!'
            ],
            description:[
                '신혼부부들이 선택한 바바컴퍼니,',
                '이제 모바일 청첩장도 바바카드로!',
            ],
            img:'./img/main/main1_img1.png'
        },
        {
            title:[
                '언제,어디서나',
                '바바카드로',
                '쉽고,간편하게!'
            ],
            description:[
                '다양한기능, 나만의 스타일로 디자인하세요!',
                '이제, 모바일청첩장은 바바카드로!',
            ],
            img:'./img/main/main1_img2.png'
        },
        {
            title:[
                '결혼식도',
                '모바일청첩장도',
                '역시 바바!'
            ],
            description:[
                '한번뿐인 결혼식을',
                '바바컴퍼니와 함께 더 특별하게 만들어보세요.',
            ],
            button:'바바컴퍼니 홈페이지',
            img:'./img/main/main1_img3.png'
        },
    ],
    memberList:[
        {
            label:'아이디',
            type:'text',
            id:'memberId',
        },
        {
            label:'이름',
            type:'text',
            id:'memberName',
        },
        {
            label:'비밀번호',
            type:'password',
            id:'memberPw',
        },
        {
            label:'비밀번호 확인',
            type:'password',
            id:'memberPwChk',
        },
        {
            label:'휴대전화',
            type:'text',
            id:'memberPhone',
        },
        {
            label:'예식날짜',
            type:'date',
            id:'memberDate',
        },
    ],
    personalAgree:`바바컴퍼니는 이용자들의 개인정보보호를 매우 중요시하며, 이용자가 바바컴퍼니의 서비스를 이용함과 동시에 온라인상에서 바바컴퍼니에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다.
이에 바바컴퍼니는 통신비밀보호법, 전기통신사업법, 정보통신망이용촉진등에관한법률 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정 및 정보통신부가 제정한 개인정보보호지침을 준수하고 있습니다.
회사는 개인정보 보호정책을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
바바컴퍼니는 개인정보보호정책을 홈페이지 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다.

바바컴퍼니의 개인정보 보호정책은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른 개인정보 보호정책의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다.

회원님께서는 바바컴퍼니 사이트 방문 시에 수시로 확인하시기 바랍니다.

바바컴퍼니의 개인정보 보호정책은 다음과 같은 내용을 담고 있습니다.

1. 개인정보 수집의 동의절차는?
바바컴퍼니는 귀하께서 바바컴퍼니의 이용약관의 내용에 대해「동의한다」체크버튼을 클릭하면 개인정보 수집 및 이용에 대하여 동의한 것으로 봅니다.

2. 개인정보의 수집목적 및 이용목적
바바컴퍼니이 회원님 개인의 정보를 수집하는 목적은 바바컴퍼니사이트를 통하여 회원님께 최적의 맞춤화된 서비스를 제공해드리기 위한 것입니다.
바바컴퍼니는 각종의 컨텐츠를 무료로 서비스해 드리고 있습니다.
이때 회원님께서 제공해주신 개인정보를 바탕으로 회원님께 보다 더 유용한 정보를 선택적으로 제공하는 것이 가능하게 됩니다.

3. 수집하는 개인정보 항목 및 수집방법 바바컴퍼니는 최초 회원가입을 하실 때 서비스 제공을 위해 가장 필수적인 개인정보를 받고 있습니다.
또한 회원 가입 이외에 이벤트나 추가회원정보를 통해 회원님의 관심분야에 대해 세분화된 항목의 정보를 수집하게 됩니다.
이는 회원님의 동의가 있거나 법령에서 규정하는 경우에 한해 수집되며, 회원님께 보다 다양하고 유용한 정보, 이벤트, 광고 등을 제공하기 위한 자료로 사용됩니다.

바바컴퍼니와 직접 연락을 취하는 경우에도 바바컴퍼니는 회원 본인인증을 위하여 개인정보를 요구할 수 있습니다.

바바컴퍼니에서 회원을 대상으로 각종 서비스를 제공하기 위해 제공받는 필수 회원 정보는 다음과 같습니다.

[ 개인정보 수집 목적 ]– 필수입력사항 : 성명, 비밀번호, 연락처
– 성명, 비밀번호 : 서비스 이용에 따른 본인식별 서비스의 제공
– 이메일주소 : 고지사항 안내, 의사소통 경로의 확보, 신규서비스 소개, 이벤트 정보 등 최신정보의 안내
– 전화번호 : 고지사항 안내, 본인의사확인, 불만처리, 청구서, 물품배송시 정확한 배송지의 확보
4. 개인정보의 보유 및 폐기
귀하가 바바컴퍼니의 회원으로서 바바컴퍼니이 제공하는 서비스를 받는 동안 회원님의 개인정보는 바바컴퍼니에서 계속 보유하며 서비스 제공을 위해 이용합니다.
다만, 바바컴퍼니의 회원이 ‘개인정보 열람, 정정(수정)’에서 설명한 절차와 방법에 따라 회원 본인이 직접 삭제하거나 수정한 정보, 가입해지를 요청한 경우에는 재생할 수 없는 방법에 의하여 하드디스크에서 완전히 삭제되며 어떠한 용도로도 열람 또는 이용할 수 없도록 처리됩니다.

귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공 받은 목적이 달성되면 파기하는 것을 윈칙으로 합니다.
다만, 아래의 경우 회원정보를 보관합니다.
그리고 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 보조할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
아울러 회원ID에 한해서는, 서비스 이용의 혼선 방지, 불법적 사용자에 대한 수사기관 수사 협조, 기타 안정적인 서비스 제공을 위해, 회원탈퇴 후에도 15일간 보유하게 됩니다.

– 계약 또는 청약철회 등에 관한 기록 : 5년
– 대금결제 및 재화등의 공급에 관한 기록 : 5년
– 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년

5. 개인정보의 제공 및 공유
바바컴퍼니는 회원님으로부터 수집한 개인정보를 회원님의 사전 동의 없이는 절대로 제3자와 함께 공유하거나 공개하지 않습니다.
다만, 바바컴퍼니 서비스 내용 중 회원님께서 희망하는 서비스가 제휴, 협력 혹은 기타 계약(이하 협력)에 의해, 추가가입이 필요한 경우에는 이를 미리 회원 여러분께 알려드리며, 이에 대한 회원님의 동의가 있거나 법에 의해 요구되는 경우에 한해 예외로 합니다.
이 경우, 회원님의 개인정보 중 일부분, 혹은 전체가 제3자에게 제공됨을 미리 알려드리며, 추가 가입 약관, 혹은 기타 형태의 내용 화면에서 ‘동의’ 혹은 ‘추가가입’ 등의 버튼을 눌러 회원님의 동의가 확인될 경우에만 회원님의 정보가 보다 편리한 서비스 활용을 위하여 협력사에 제공되며, 아래의 경우에는 예외로 합니다.

– 회원님께서 사전에 공개에 동의한 경우
– 서비스 제공에 따른 요금정산을 위하여 필요한 경우
– 바바컴퍼니 서비스 이용약관이나 기타 회원 서비스 등 이용약관 또는 정책을 위반한 경우
– 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우
– 서비스 이용에 따른 문의사항(민원)의 처리를 위하여 해당 문의사항의 처리에 필요한 개인정보를 제공하는 경우
– 이벤트 당첨으로 인한 경품의 배송의 경우 어떠한 경우에도 회원님의 동의가 없는 경우에는 추가적인 정보를 임의로 수집하거나 공유하지 않습니다.

6. 개인정보의 열람 및 정정(수정)
회원님은 언제든지 귀하는 개인정보를 웹사이트에 제공된 개인정보변경에 관한 메뉴를 통해 열람하시거나 정정(수정)하실 수 있습니다.
개인정보와 관련하여 불만이나 의견이 있으신 분은 개인정보 관리담당자에게 전화(02-1688-4787)로 의견을 주시면 접수 즉시 조치하고 처리결과를 통보해 드리겠습니다.

7. 쿠키(cookie)의 운용 및 활용
쿠키(cookie)란 인터넷 웹 페이지와 자신의 컴퓨터 사이에서 통신을 매개해주는 정보로 바바컴퍼니 서버에 회원님에 관한 정보를 사용자 컴퓨터에 저장하도록 허용하는 장치로 사용됩니다.
바바컴퍼니에서는 이러한 쿠키(cookie)를 이용하여 회원님의 브라우저 형식이나 서비스 이용 형태를 얻게되며, 이러한 쿠키 정보를 바탕으로 회원님께 유용하고 보다 편리한 맞춤서비스를 제공하는데 사용하게 됩니다.
쿠키는 회원님의 컴퓨터는 식별하지만 회원님을 개인적으로 식별하지는 않습니다.

회원님께서는 쿠키(cookie)에 대하여 사용여부를 선택할 수 있습니다.
웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용할 수 있고, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다.
다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 바바컴퍼니의 일부 서비스 이용이 어려울 수 있습니다.

8. 개인정보관련 기술적, 관리적 대책

바바컴퍼니는 회원 여러분이 제공한 개인정보에 대하여 훼손, 침해, 누설, 변조 되지 않도록 하기 위한 안전성 확보를 위해서 기술적, 관리적 대책을 마련하고 있습니다.
바바컴퍼니의 개인정보관리는 회원 여러분의 아이디와 비밀번호에 의하여 관리되고 있기 때문에 절대로 타인에게 아이디나 비밀번호를 공개하여서는 아니되며, 작업이 종료되면 반드시 로그아웃하시고, 웹브라우저 창을 닫아 주시기 바랍니다.
특히, 타인과 컴퓨터를 공동으로 사용하는 인터넷카페, PC방, 도서관 등의 장소에서 컴퓨터를 사용하시는 경우 각별한 주의를 부탁드립니다.

– 해킹이나 컴퓨터 바이러스 방지 장치
– 강력한 방화벽 구축 및 침입차단 시스템을 이용한 접근 통제 장치
– 개인정보에 대한 암호화
– 기타 안전성 확보를 위한 기술적 장치
– 사용자의 개인정보를 처리하는 시스템의 담당자를 지정하여 비밀번호를 부여하고 보안을 유지함

9. 개인정보 침해 방지를 위한 선언
가. 바바컴퍼니의 개인정보관련 취급자들은 위 사항을 준수하면서 다음과 같이 선언합니다.

(1) 이용자의 동의 없는 개인정보를 수집하지 않습니다.

(2) 과도한 개인정보를 수집하지 않습니다.

(3) 고지, 명시한 범위를 초과하는 목적 외 이용 또는 제3자에게 제공을 하지 않습니다.

(4) 개인정보에 대한 훼손, 침해, 누설 행위를 하지 않습니다.

(5) 개인정보에 대한 동의 철회, 열람, 정정 요구에 불응하는 행위를 하지 않습니다.

(6) 법정대리인의 동의 없는 아동 정보를 수집하지 않습니다.

(7) 타인의 개인정보를 훼손, 침해, 도용하지 않습니다.

10. 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처
바바컴퍼니는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 관리책임자 및 담당자를 지정, 운영하고 있습니다.

이와 관련하여 문의사항이 있으시면 신속하고 성실하게 답변하여 드리겠습니다.

[개인정보 관리 책임자]이 름 : 바바컴퍼니
전 화 : 02-1688-4787
e-Mail : babacompany@naver.com
기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터 (http://www.cyberagree.or.kr)
전화 : 서울 1336, 지방 (02)1336 으로 문의하실 수 있습니다.

감사합니다.

* 본 개인정보/청소년 보호정책은 2015년 2월1일자로 개정되었습니다.

* 본 보호정책은 적용일자 이전 가입자 또한 본 보호정책의 적용을 받습니다.`,
    audioList : ['분위기 있는 재즈바에서','여행가고 싶은 힐링음악','비오는날 피아노연주','사랑스럽게 고백하는 날','창밖을 보며 차 한잔 생각날때','몽롱한 피아노 소리','기분좋은 날 함께해요'],

    fontList : [
        {
            title_ko:'나눔명조',
            title_en:'Nanum Myeongjo',
        },
        {
            title_ko:'나눔손글씨 코코',
            title_en:'NanumKoKoCe-Regular',
        },
        {
            title_ko:'나눔손글씨 중학생',
            title_en:'Middleschool_student',
        },
        {
            title_ko:'고운바탕',
            title_en:'GowunBatang-Regular',
        },
        {
            title_ko:'엄마까투리',
            title_en:'Katuri',
        },
        {
            title_ko:'빙그레 싸만코',
            title_en:'BinggraeSamanco-Bold',
        },
        {
            title_ko:'더 페이스샵 잉크',
            title_en:'InkLipquid',
        },
        {
            title_ko:'고운밤',
            title_en:'Cafe24Oneprettynight',
        },
        {
            title_ko:'the 플라워',
            title_en:'UtoFlower',
        },
    ], 
    themeList: ['웨딩데이','백년가약','행복한날','부부의연','천생연분','일심동체','소중한날','알콩달콩 (대표사진이 필요합니다)', '애지중지 (대표사진이 필요합니다)', '해피니스', '러브홀릭 (대표사진이 필요합니다)', '특별한날', '너랑나랑 (대표사진이 필요합니다)', '평생지기 (대표사진이 필요합니다)'],
    greeting : [
        {
            content:`오랜 기다림 속에서 저희 두 사람,\n하나 되어 사랑의 결실을 맺게 되었습니다.\n참석하셔서 축복해 주시면\n큰 기쁨이겠습니다.`,
        },
        {
            content:`저희에게 오늘이 있기까지\n보내주신 따뜻한 사랑과 깊은 관심에\n진심으로 감사드립니다.\n저희 두 사람은 여러분의 축복을 받으며\n진실한 가약을 맺고자 합니다.\n부디 참석하시어 기쁨의 자리를\n빛내 주시길 바랍니다.`,
        },
        {
            content:`평생을 같이하고 싶은 사람을 만났습니다.\n서로 아껴주고 이해하며\n사랑 베풀며 살고 싶습니다.\n저희 약속 위에 따뜻한 격려로\n축복해 주셔서\n힘찬 출발의 디딤이 되어 주십시오.`,
        },
        {
            content:`기쁨이 가득한 날\n하나의 매듭이 되고\n하나의 길이 되어\n하나의 보금자리를 이루려 합니다.\n변함없는 믿음과 사랑으로\n축복해 주시면 감사하겠습니다.`
        },
        {
            content:`저희 두 사람이 사랑과 믿음으로\n한 가정을 이루게 되었습니다.\n바쁘시더라도 참석하셔서\n저희의 앞날을 축복해 주시고\n격려해 주시면 더없는 기쁨이 되겠습니다.`,
        },
        {
            content:`하나님의 사랑으로 만난 두 사람이\n이제 새로운 가정을 이루는\n아름다운 약속을 하려 합니다.\n언제나 아름답고 주위에 사랑을 나누는\n행복한 가정을 이루도록\n오셔서 축복해 주시고 지켜봐 주십시오.`,
        },
        {
            content:`눈부시게 푸른 하늘 아래\n저희 두 사람이 하나의 인생으로\n새 출발 하는\n언약의 시간을 갖고자 합니다.\n오셔서 축복해 주시면\n더없는 기쁨이 되겠습니다.`,
        },
    ],
    bankList : ['국민은행','카카오뱅크','신한은행','우리은행','농협은행','하나은행','기업은행','케이뱅크','새마을금고','SC제일은행','대구은행','부산은행','우체국','수협은행','토스뱅크','신협','씨티은행','경남은행','KDB산업은행','광주은행','전북은행','제주은행','지역수협'],

    admin:{
        id:'babacardAdmin',
        pw:'U2FsdGVkX1/3NGrLaYOCTu+WjAET6Ynf7xCYTTvsGNw=!'
    },
    fontSize : [ 2.8, 3.3, 3.3, 3, 2.8, 3.2, 3.2, 3, 3.2],
    fontSizeS : [ 2.5, 3, 3, 2.7, 2.6, 2.9, 2.9, 2.7, 2.9],
    fontSizeM : [ 2.8, 3.3, 3.3, 3, 2.8, 3.2, 3.2, 3, 3.2],
    fontSizeL : [ 3.2, 3.7, 3.7, 3.3, 3.2, 3.6, 3.6, 3.4, 3.6],
    snow: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14]
}
