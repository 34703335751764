import React, { Fragment, useEffect, useState } from "react";
import Header from './header'
import Footer from './footer'
import Upload from './utils/upload'
import Popup from 'reactjs-popup';
import toast, { Toaster } from 'react-hot-toast';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react/swiper-react';
import { Pagination, Navigation } from 'swiper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faCopy } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import AudioPlayer from 'react-h5-audio-player';
import { Select, CaretIcon, ModalCloseButton } from 'react-responsive-select';
// import ImageUploading from "react-images-uploading";
import 'swiper/swiper.min.css'; // core Swiper
import 'swiper/swiper-bundle.min.css'; // core Swiper
import {DISCORD_WEBHOOK, kakaoID} from './data/snsLogin'

import './data/data';
import 'reactjs-popup/dist/index.css';
import 'react-h5-audio-player/lib/styles.css';
import 'react-responsive-select/dist/react-responsive-select.css';
import {MemoEffect} from "./utils/effect";
import Effect2 from "./utils/effect2";
import { handleSetting } from "./js/function";
import { postSetting } from "./data/api";

export default function MakeCard(){
    let themeList = window.Indata.themeList;
    let bankList = window.Indata.bankList;
    let greetingList = window.Indata.greeting;
    let fontList = window.Indata.fontList;
    const fontSize = window.Indata.fontSize;

    // object 하나를 state로 사용
    const [member, setMember] = useState({
        theme : '',
        animation : '',
        greeting : ``,
        ordering : '0,1,2,3,4,5,6,7,8,9,10',
        video:'',
        font:'',
        bgm:'',
        thumb:'',
        galleryType:'',
    });

    let [audioSelect, setAudioSelect] = useState(0);

    const [groom, setGroom] = useState({
        type: 'groom',
        name : '',
        nickname : '',
        phone : '',
        bank : '',
        account : '',
        kakao:'',
        fatherDeceased : 'n',
        fatherName : '',
        fatherPhone : '',
        fatherBank : '',
        fatherAccount : '',
        fatherKakao : '',
        motherDeceased : 'n',
        motherName : '',
        motherPhone : '',
        motherBank : '',
        motherAccount : '',
        motherKakao : '',
    });

    const [bride, setBride] = useState({
        type: 'bride',
        name : '',
        nickname : '',
        phone : '',
        bank : '',
        account : '',
        kakao:'',
        fatherDeceased : 'n',
        fatherName : '',
        fatherPhone : '',
        fatherBank : '',
        fatherAccount : '',
        fatherKakao : '',
        motherDeceased : 'n',
        motherName : '',
        motherPhone : '',
        motherBank : '',
        motherAccount : '',
        motherKakao : '',
    });

    const [wedding, setWedding] = useState({
        place : '',
        date : '',
        time : '',
        detail : '',
        tel:'',
        address:'',
        zipCode:'',
        info:``,
    });
    
    const [setting, setSetting] = useState({
        zoom:'',
        fontSize:'',
        orderCouple:'',
        hideGreeting:'',
        hideCalendar:'',
        hideMessage:'',
        hideAttend:'',
        hideShare:'',
    });

    const [orderList, setOrderList] = useState([
        {
            title:'메인테마',
            order:0
        },
        {
            title:'인사말',
            order:1
        },
        {
            title:'연락하기',
            order:2
        },
        {
            title:'캘린더',
            order:3
        },
        {
            title:'갤러리',
            order:4
        },
        {
            title:'동영상',
            order:5
        },
        {
            title:'계좌정보',
            order:6
        },
        {
            title:'화환보내기',
            order:7
        },
        {
            title:'축하메세지',
            order:8
        },
        {
            title:'위치',
            order:9
        },
        {
            title:'참석여부',
            order:10
        },
    ]);
    // 카카오 ID 없으면 로그인으로 이동
    useEffect(()=>{
        const kakaoID = localStorage.getItem('kakaoID');
        if(!kakaoID || kakaoID === null || kakaoID === undefined){
            alert('로그인이 정상적으로 되지않았습니다.');
            window.location.href='./login';
        }
    })


    // 데이터 임시 저장
    const handleMember = (e) => {
        setMember({ ...member, [e.target.name]: e.target.value });
    };
    const [chkboxgf, setGfChkbox] = useState(false);
    const [chkboxgm, setGmChkbox] = useState(false);
    const [chkboxbf, setBfChkbox] = useState(false);
    const [chkboxbm, setBmChkbox] = useState(false);
    // 테마타입
    const [theme, setTheme] = useState(0);
    // 갤러리타입
    const [galleryType, setGalleryType] = useState('swipe');
    // 인사말 직접입력
    const [greetingEtc, setGreetingEtc] = useState('');

    const handleGreeing = (e) => {
        setGreetingEtc(e.target.value);
        setMember({ ...member, 'greeting': e.target.value });
        console.log(member)
    }

    const handleGallery = (e) => {
        setGalleryType(e.target.value);
    }

    const handleGroom = (e) => {
        if(e.target.name === 'fatherDeceased'){
            if(e.target.value === 'y'){
                setGroom({ ...groom, 'fatherDeceased': 'n' });
            }else{
                setGroom({ ...groom, 'fatherDeceased': 'y' });
            }
            setGfChkbox(!chkboxgf);
        }else if(e.target.name === 'motherDeceased'){
            if(e.target.value === 'y'){
                setGroom({ ...groom, 'motherDeceased': 'n' });
            }else{
                setGroom({ ...groom, 'motherDeceased': 'y' });
            }
            setGmChkbox(!chkboxgm);
        }else{
            setGroom({ ...groom, [e.target.name]: e.target.value });
        }
    }

    const handleBride = (e) => {
        if(e.target.name === 'fatherDeceased'){
            if(e.target.value === 'y'){
                setBride({ ...bride, 'fatherDeceased': 'n' });
            }else{
                setBride({ ...bride, 'fatherDeceased': 'y' });
            }
            setBfChkbox(!chkboxbf);
        }else if(e.target.name === 'motherDeceased'){
            if(e.target.value === 'y'){
                setBride({ ...bride, 'motherDeceased': 'n' });
            }else{
                setBride({ ...bride, 'motherDeceased': 'y' });
            }
            setBmChkbox(!chkboxbm);
        }else{
            setBride({ ...bride, [e.target.name]: e.target.value });
        }
    }

    const handleWedding = (e) => {
        setWedding({ ...wedding, [e.target.name]: e.target.value });
    }

    const handleBGM = (name, value) => {
        setAudioSelect(value);
        setMember({ ...member, [name]: value });
    }

    // const [images, setImages] = useState([]);
    // const [photoArr, setPhotoArr] = useState([]);
    // const maxNumber = 30;
    const [no, setNo] = useState(0);

    // const onChange = (imageList, addUpdateIndex) => {
    //     console.log(imageList, addUpdateIndex);
    //     setImages(imageList);

    //     imageList.map(item => {
    //         setPhotoArr((photoArr)=>{
    //             return [...photoArr, item]
    //         })
    //     })
    //     console.log(imageList)
    // };

    let memberJSON = {};
    // 로컬 임시저장
    const temSave = (type)=>{
        if(type === 'member'){
            memberJSON = { ...member}
            setTheme(member.theme)
            console.log(theme)
            localStorage.setItem('member',JSON.stringify(memberJSON));
        }

        if(type === 'thumb'){
            memberJSON = { ...member, thumb: 'asdasda'};
            localStorage.setItem('member',JSON.stringify(memberJSON));
        }

        if(type === 'photo'){
            setMember({...member, galleryType: galleryType});
            memberJSON = { ...member, galleryType: galleryType};
            localStorage.setItem('member',JSON.stringify(memberJSON));
        }

        if(type === 'info'){
            memberJSON = { groom:{...groom},bride:{...bride},place:{...wedding},}
            localStorage.setItem('info',JSON.stringify(memberJSON));
        }

        if(type === 'add'){
            for (const key in setting) {
                if(setting.hasOwnProperty(key)) {
                    if(setting[key]){
                        let settingJSON = {
                            memberId: kakaoID,
                            settingName: key,
                            settingValue: setting[key]
                        }
                        postSetting(JSON.stringify(settingJSON));
                    }
                }
            }
        }
    }

    const getWeddingKey = () => {
        memberJSON = {memberId: kakaoID, ...member, groom:{...groom}, bride:{...bride}, place:{...wedding},}
        axios.post(`https://babacard.co.kr/babacard/api/v1/wedding/`, JSON.stringify(memberJSON), {
            headers: { 
                "Content-Type": 'application/json',
            }
        }).then(function (res) {
            setNo(res.data);
        }).catch(function (error) {
            // 오류발생시 실행
        })
    }

    const PutSave = () => {
        let value = {
            weddingKey:no,
            ...member
        }
        localStorage.setItem('cardNo',no);
        axios.put(`https://babacard.co.kr/babacard/api/v1/wedding`, value, {
            headers: { 
                "Content-Type": 'application/json',
            },
        })
        .then(function (res) {
        }).catch(function (error) {
            // 오류발생시 실행
        })
        
        localStorage.removeItem('member');
        localStorage.removeItem('info');
    }

    // funtion
    // 클립보드 복사
    const handleCopyClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success('복사 성공');
        } catch (e) {
            toast('URL 복사 실패');
        }
    };
    const discord = () => {
        const msg = {
            "content": `새로운 청첩장이 생성되었습니다!\n신랑이름 : ${groom.name}\n신부이름 : ${bride.name}\n예식장 : ${wedding.place}\n예식날짜 : ${wedding.date}\nURL : https://babacard.co.kr/card?no=${no}
            `,
        }
        fetch(DISCORD_WEBHOOK, {
            "method":"POST",
            "headers" : {
                "content-type": "application/json"
            },
            "body": JSON.stringify(msg)
        })
    }
    // swiper 다음/이전 버튼
    const SwiperButtonNext = ({ name, children }) => {
        const swiper = useSwiper();
        return <button className="btn" onClick={() => { 
            switch(name){
                case 'theme' :
                    temSave('member');
                    if(member.theme === ''){
                        toast.error('테마를 선택해주세요');
                        return false;
                    }
                    swiper.slideNext();
                break;
                case 'info' :
                    temSave('info');
                    if(groom.name === ''){
                        toast.error('신랑이름을 넣어주세요');
                        return false;
                    }
                    if(bride.name === ''){
                        toast.error('신부이름을 넣어주세요');
                        return false;
                    }
                    if(wedding.date === '' || wedding.place === '' || wedding.address === '' || wedding.time === ''){
                        toast.error('예식장 정보를 넣어주세요');
                        return false;
                    }
                    getWeddingKey();
                    swiper.slideNext();
                break;
                case 'animation' :
                    console.log(member)
                    swiper.slideNext();
                break;

                case 'greeting' :
                    temSave('member');
                    swiper.slideNext();
                break;

                case 'thumb' :
                    temSave('thumb');
                    swiper.slideNext();
                break;

                case 'font' :
                    temSave('member');
                    swiper.slideNext();
                break;

                case 'bgm' :
                    temSave('member');
                    setAudioSelect(0);
                    swiper.slideNext();
                    break;

                case 'photo' :
                    temSave('photo');
                    swiper.slideNext();
                break;

                case 'video' :
                    temSave('member');
                    swiper.slideNext();
                break;

                case 'add' :
                    temSave('add');
                    swiper.slideNext();
                break;

                case 'order' :
                    temSave('order');
                    discord();
                    setTimeout(()=>{
                        PutSave();
                        toast.success('만들기 성공!');
                        swiper.slideNext();
                    }, 0)
                break;

                default:
                    swiper.slideNext()
                    break;
                }
            window.scrollTo(0, 0);
            }}>{children}</button>;
    };

    const SwiperButtonPrev = ({ children }) => {
        const swiper = useSwiper();
        return <button className="btn" onClick={() => { swiper.slidePrev(); window.scrollTo(0, 0);}}>{children}</button>;
    };

    const { daum } = window;

    // 다음지도
    const address = () => {
        new daum.Postcode({
            oncomplete: function(data) {
                var addr = data.address; // 최종 주소 변수
                let zipCode = data.zonecode;
                // 주소 정보를 해당 필드에 넣는다.
                document.getElementById("weddingAddress").value = addr;
                setWedding({ ...wedding, 'address': addr, 'zipCode': zipCode});
            }
        }).open();
    }

    // bgm player
    const Player = (props) => (
    <AudioPlayer
        autoPlay
        src = {`./audio/music${props.music}.mp3`}
        hasDefaultKeyBindings = {false}
        showJumpControls = {false}
        showDownloadProgress = {false}
        showFilledProgress = {false}
    />
    );

    const setOrderUp = (item) => {
        if(item.order > 1){
            let orderIndex = orderList.findIndex(order => order.order === item.order-1)
            let findIndex = orderList.findIndex(order => order.title === item.title)

            let copiedItems = [...orderList];
            copiedItems[findIndex].order = item.order-1;
            copiedItems[orderIndex].order = item.order+1;
    
            setOrderList(copiedItems);

            let orderArray = [];
            orderList.map((item) => {
                return(
                    orderArray.push(item.order)
                )
            })

            setMember({ ...member, ordering: orderArray.join(',')});
        }else{
            toast.error('맨 첫번째 입니다.')
        }
    }

    const setOrderDown = (item) => {
        if(item.order < 10){
            let orderIndex = orderList.findIndex(order => order.order === item.order+1)
            let findIndex = orderList.findIndex(order => order.title === item.title)
    
            let copiedItems = [...orderList];
            copiedItems[findIndex].order = item.order+1;
            copiedItems[orderIndex].order = item.order-1;
    
            setOrderList(copiedItems);

            let orderArray = [];
            orderList.map((item) => {
                return(
                    orderArray.push(item.order)
                )
            })

            setMember({ ...member, ordering: orderArray.join(',')});
        }else{
            toast.error('맨 마지막 입니다.')
        }
    }
    return(
        <Fragment>
            <Header />
            <div className="block"></div>
            <h1 className="hide">청첩장 만들기</h1>

            <Swiper
            slidesPerView={1}
            allowTouchMove={false}
            autoHeight={true}
            >
                {/* 테마선택 */}
                <SwiperSlide>
                    <section className="makeCard cardTheme">
                        <h2>테마선택</h2>
                        <h4>테마이미지를 클릭하면 확대됩니다.</h4>
                        <div className="cardContent">
                            <ul>
                                {themeList.map((item, idx)=>{
                                    return(
                                        <li key={item}>
                                            <Popup trigger={<img src={"./img/makeCard/theme/theme"+(idx+1)+".jpg"} width={'100%'} alt={'theme'+(idx+1)} />} position="center" modal nested>
                                                {close => (
                                                    <div className="modal">
                                                        <button className="close" onClick={close}>&times;</button>
                                                        <div className="content"> 
                                                            <img src={"./img/makeCard/theme/theme"+(idx+1)+".jpg"} alt={'theme'+(idx+1)} className="themePopup"/>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                            <label htmlFor={'theme'+(idx+1)}>{item}
                                                <input type="radio" name="theme" id={'theme'+(idx+1)} value={(idx+1)} onChange={handleMember}/>
                                            </label>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div>테마는 계속 추가중입니다...</div>
                        </div>
                        <div className="cardBtn">
                            <SwiperButtonNext name={'theme'}>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 애니메이션 */}
                <SwiperSlide>
                    <section className="makeCard cardAnimation">
                        <h2>에니메이션</h2>
                        <h4>에니메이션을 선택하면 미리볼수있습니다.</h4>
                        <Select
                            name="animation"
                            modalCloseButton={<ModalCloseButton />}
                            options={[
                                { value: '0', text: '효과 없음' },
                                { value: '3', text: '효과 1' },
                                { value: '2', text: '효과 2' },
                                { value: '4', text: '효과 3' },
                            ]}
                            caretIcon={<CaretIcon />}
                            selectedValue="0"
                            onChange={newValue => setMember({...member, animation: newValue.value})}
                        />
                        <div style={{
                            position:'relative',
                            display:'flex',
                            justifyContent:'center',
                            overflow:'hidden',
                            width:'100%',
                            maxWidth:'300px',
                            margin:'10px auto'
                            }}>
                            <img src={"./img/makeCard/theme/theme"+(theme)+".jpg"} width={'300px'} alt='' />
                            {member.animation !== '2' ?
                            <Effect2 ani={member.animation}/>:
                            <MemoEffect effect={member.animation} />}
                        </div>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name={'animation'}>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 정보 */}
                <SwiperSlide>
                    <section className="makeCard cardInfo">
                        <h2>예식정보</h2>
                        <div className="cardContent">
                            <h3>신랑 / 신부</h3>
                            <div>
                                <Popup trigger={<button className="btn btn_reverse"  style={{marginTop:0}}> 카카오페이URL 생성법 </button>} position="center" modal>
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="content" style={{display:'flex'}}> 
                                            <Swiper
                                                modules={[Pagination, Navigation]}
                                                pagination = {{ clickable: true }}
                                                slidesPerView={1}
                                            >
                                                <SwiperSlide>
                                                    <div className="kakao_pay_info">
                                                        <p>Step 1. 카카오톡 앱에서 하단 ... &lt;더보기&gt;를 터치한다</p> 
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="kakao_pay_info">
                                                        <p>Step 2. 상단에 카카오 pay 버튼을 터치!</p>
                                                        <img src="./img/makeCard/kakao1.jpeg" alt="" width={320}/>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="kakao_pay_info">
                                                        <p>Step 3. 카카오 pay 안에서 들어가면 전체 탭을 터치!</p>
                                                        <img src="./img/makeCard/kakao2.jpeg" alt="" width={320}/>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="kakao_pay_info">
                                                        <p>Step 4. 전체 탭에서 내려가면 송금 탭에 송금코드 터치!</p>
                                                        <img src="./img/makeCard/kakao3.jpeg" alt="" width={320}/>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="kakao_pay_info">
                                                        <p>Step 5. 생성된 QR코드 하단 맨 왼쪽에 링크 복사를 하면 끝!</p>
                                                        <img src="./img/makeCard/kakao4.jpeg" alt="" width={260}/>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                            <div style={{display:'flex', width:'100%', justifyContent:'space-evenly'}}>
                                <ul>
                                    <li><h4>신랑</h4></li>
                                    <li>
                                        <label htmlFor="groomName">이름 <small>[필수]</small></label>
                                        <input type="text" name="name" id="groomName" onChange={handleGroom}/>
                                    </li>
                                    <li>
                                        <label htmlFor="groomTel">연락처</label>
                                        <input type="text" name="phone" id="groomTel" placeholder="-까지 전부 입력해주세요" onChange={handleGroom}/>
                                    </li>
                                    <li>
                                        <label htmlFor="groomNickname">호칭</label>
                                        <input type="text" name="nickname" id="groomNickname" onChange={handleGroom} placeholder="예) 장남 / 차남"/>
                                    </li>
                                    <li className="makeCard_bankArea">
                                        <label htmlFor="groomBank">계좌정보</label>
                                        <select name="bank" id="groomBank" onChange={handleGroom}>
                                            <option value="">은행선택</option>
                                            {bankList.map((item, idx)=>{
                                                return(
                                                    <option value={item} key={idx}>{item}</option>
                                                )
                                            })}
                                        </select>
                                        <input type="text" name="account" id="groomAccount"  placeholder="-까지 전부 입력해주세요" onChange={handleGroom} className="account_input"/>
                                    </li>
                                    <li>
                                        <label htmlFor="groomKakao">카카오페이URL</label>
                                        <input type="text" name="kakao" id="groomKakao" onChange={handleGroom}/>
                                    </li>
                                </ul>

                                <ul>
                                    <li><h4>신부</h4></li>
                                    <li>
                                        <label htmlFor="brideName">이름 <small>[필수]</small></label>
                                        <input type="text" name="name" id="brideName" onChange={handleBride}/>
                                    </li>
                                    <li>
                                        <label htmlFor="brideTel">연락처</label>
                                        <input type="text" name="phone" id="brideTel" placeholder="-까지 전부 입력해주세요" onChange={handleBride}/>
                                    </li>
                                    <li>
                                        <label htmlFor="brideNickname">호칭</label>
                                        <input type="text" name="nickname" id="brideNickname" onChange={handleBride} placeholder="예) 장녀 / 차녀"/>
                                    </li>
                                    <li className="makeCard_bankArea">
                                        <label htmlFor="brideBank">계좌정보</label>
                                        <select name="bank" id="brideBank" onChange={handleBride}>
                                            <option value="">은행선택</option>
                                            {bankList.map((item, idx)=>{
                                                return(
                                                    <option value={item} key={idx}>{item}</option>
                                                )
                                            })}
                                        </select>
                                        <input type="text" name="account" id="brideAccount" placeholder="-까지 전부 입력해주세요" onChange={handleBride} className="account_input"/>
                                    </li>
                                    <li>
                                        <label htmlFor="brideKakao">카카오페이URL</label>
                                        <input type="text" name="kakao" id="brideKakao" onChange={handleBride}/>
                                    </li>
                                </ul>
                            </div>

                            <h3>혼주정보</h3>
                            <div style={{display:'flex', width:'100%', justifyContent:'space-evenly'}}>
                                <ul>
                                    <li><h4>신랑 아버지</h4></li>
                                    <li>
                                        <label htmlFor="groomFatherDeceased">고인여부</label>
                                        <input type="checkbox" name="fatherDeceased" id="groomFatherDeceased" value={groom.fatherDeceased} onChange={handleGroom} />
                                    </li>
                                    <li>
                                        <label htmlFor="groomFatherName">이름</label>
                                        <input type="text" name="fatherName" id="groomFatherName" onChange={handleGroom}/>
                                    </li>
                                    <li>
                                        <label htmlFor="groomFatherPhone">연락처</label>
                                        <input type="text" name="fatherPhone" id="groomFatherPhone" onChange={handleGroom} disabled={(chkboxgf)?true:false}/>
                                    </li>
                                    <li className="makeCard_bankArea">
                                        <label htmlFor="groomFatherBank">계좌정보</label>
                                        <select name="fatherBank" id="groomFatherBank" onChange={handleGroom} disabled={(chkboxgf)?true:false}>
                                            <option value="">은행선택</option>
                                            {bankList.map((item, idx)=>{
                                                return(
                                                    <option value={item} key={idx}>{item}</option>
                                                )
                                            })}
                                        </select>
                                        <input type="text" name="fatherAccount" id="groomFatherAccount" placeholder="-까지 전부 입력해주세요" onChange={handleGroom} className="account_input" disabled={(chkboxgf)?true:false}/>
                                    </li>
                                    <li>
                                        <label htmlFor="groomFatherKakao">카카오페이URL</label>
                                        <input type="text" name="fatherKakao" id="groomFatherKakao" onChange={handleGroom} disabled={(chkboxgf)?true:false}/>
                                    </li>
                                </ul>
                                <ul>
                                    <li><h4>신부 아버지</h4></li>
                                    <li>
                                        <label htmlFor="brideFatherDeceased">고인여부</label>
                                        <input type="checkbox" name="fatherDeceased" id="brideFatherDeceased" onChange={handleBride} value={bride.fatherDeceased}/>
                                    </li>
                                    <li>
                                        <label htmlFor="brideFatherName">이름</label>
                                        <input type="text" name="fatherName" id="brideFatherName" onChange={handleBride}/>
                                    </li>
                                    <li>
                                        <label htmlFor="brideFatherPhone">연락처</label>
                                        <input type="text" name="fatherPhone" id="brideFatherPhone" onChange={handleBride} disabled={(chkboxbf)?true:false}/>
                                    </li>
                                    <li className="makeCard_bankArea">
                                        <label htmlFor="brideFatherBank">계좌정보</label>
                                        <select name="fatherBank" id="brideFatherBank" onChange={handleBride} disabled={(chkboxbf)?true:false}>
                                            <option value="">은행선택</option>
                                            {bankList.map((item, idx)=>{
                                                return(
                                                    <option value={item} key={idx}>{item}</option>
                                                )
                                            })}
                                        </select>
                                        <input type="text" name="fatherAccount" id="brideFatherAccount" placeholder="- 까지 전부 입력해주세요" onChange={handleBride} className="account_input" disabled={(chkboxbf)?true:false}/>
                                    </li>
                                    <li>
                                        <label htmlFor="brideFatherKakao">카카오페이URL</label>
                                        <input type="text" name="fatherKakao" id="brideFatherKakao" onChange={handleBride} disabled={(chkboxbf)?true:false}/>
                                    </li>
                                </ul>
                            </div>

                            <div style={{display:'flex', width:'100%', justifyContent:'space-evenly'}}>
                                <ul>
                                    <li><h4>신랑 어머니</h4></li>
                                    <li>
                                        <label htmlFor="groomMotherDeceased">고인여부</label>
                                        <input type="checkbox" name="motherDeceased" id="groomMotherDeceased" value={groom.motherDeceased} onChange={handleGroom}/>
                                    </li>
                                    <li>
                                        <label htmlFor="groomMotherName">이름</label>
                                        <input type="text" name="motherName" id="groomMotherName" onChange={handleGroom}/>
                                    </li>
                                    <li>
                                        <label htmlFor="groomMotherPhone">연락처</label>
                                        <input type="text" name="motherPhone" id="groomMotherPhone" onChange={handleGroom} disabled={(chkboxgm)?true:false}/>
                                    </li>
                                    <li className="makeCard_bankArea">
                                        <label htmlFor="groomMotherBank">계좌정보</label>
                                        <select name="motherBank" id="groomMotherBank" onChange={handleGroom} disabled={(chkboxgm)?true:false}>
                                            <option value="">은행선택</option>
                                            {bankList.map((item, idx)=>{
                                                return(
                                                    <option value={item} key={idx}>{item}</option>
                                                )
                                            })}
                                        </select>
                                        <input type="text" name="motherAccount" id="groomMotherAccount" placeholder="- 까지 전부 입력해주세요" onChange={handleGroom} className="account_input" disabled={(chkboxgm)?true:false}/>
                                    </li>
                                    <li>
                                        <label htmlFor="groomMotherKakao">카카오페이URL</label>
                                        <input type="text" name="motherKakao" id="groomMotherKakao" onChange={handleGroom} disabled={(chkboxgm)?true:false}/>
                                    </li>
                                </ul>

                                <ul>
                                    <li><h4>신부 어머니</h4></li>
                                    <li>
                                        <label htmlFor="brideMotherDeceased">고인여부</label>
                                        <input type="checkbox" name="motherDeceased" id="brideMotherDeceased" onChange={handleBride} value={bride.motherDeceased} />
                                    </li>
                                    <li>
                                        <label htmlFor="brideMotherName">이름</label>
                                        <input type="text" name="motherName" id="brideMotherName" onChange={handleBride}/>
                                    </li>
                                    <li>
                                        <label htmlFor="brideMotherPhone">연락처</label>
                                        <input type="text" name="motherPhone" id="brideMotherPhone" onChange={handleBride} disabled={(chkboxbm)?true:false}/>
                                    </li>
                                    <li className="makeCard_bankArea">
                                        <label htmlFor="brideMotherBank">계좌정보</label>
                                        <select name="motherBank" id="brideMotherBank" onChange={handleBride} disabled={(chkboxbm)?true:false}>
                                            <option value="">은행선택</option>
                                            {bankList.map((item, idx)=>{
                                                return(
                                                    <option value={item} key={idx}>{item}</option>
                                                )
                                            })}
                                        </select>
                                        <input type="text" name="motherAccount" id="brideMotherAccount" placeholder="-까지 전부 입력해주세요" onChange={handleBride} className="account_input" disabled={(chkboxbm)?true:false}/>
                                    </li>
                                    <li>
                                        <label htmlFor="brideMotherKakao">카카오페이URL</label>
                                        <input type="text" name="motherKakao" id="brideMotherKakao" onChange={handleBride} disabled={(chkboxbm)?true:false}/>
                                    </li>
                                </ul>
                            
                            </div>

                            <h3>예식정보</h3>
                            <div style={{display:'flex', width:'100%', justifyContent:'space-evenly'}}>
                                <ul>
                                    <li>
                                        <label htmlFor="weddingDate">날짜 <small>[필수]</small></label>
                                        <input type="date" name="date" id="weddingDate" onChange={handleWedding}/>
                                    </li>
                                    <li>
                                        <label htmlFor="weddingTime">시간 <small>[필수]</small></label>
                                        <input type="time" name="time" id="weddingTime" onChange={handleWedding} step={900}/>
                                    </li>
                                    <li>
                                        <label htmlFor="weddingPlace">예식장명 <small>[필수]</small></label>
                                        <input type="text" name="place" id="weddingPlace" placeholder="예식장명" onChange={handleWedding}/>
                                    </li>
                                    <li>
                                        <label htmlFor="weddingDetail">층/홀/실</label>
                                        <input type="text" name="detail" id="weddingDetail" placeholder="3층 / 그랜드홀" onChange={handleWedding}/>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <label htmlFor="weddingAddress">주소 <small>[필수]</small></label>
                                        <input type="text" id="weddingAddress" placeholder="주소" readOnly onClick={address}/>
                                    </li>
                                    <li>
                                        <label htmlFor="weddingTel">예식장 연락처</label>
                                        <input type="tel" name="tel" id="weddingTel" placeholder="" onChange={handleWedding}/>
                                    </li>
                                    <li>
                                        <label htmlFor="weddingInfo">안내사항</label>
                                        <textarea name="info" id="weddingInfo" placeholder="주차장이 협소하오니 대중교통을 이용해주시기 바랍니다." onChange={handleWedding} />
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name={'info'}>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 인사말 */}
                <SwiperSlide>
                    <section className="makeCard cardGreeting">
                        <h2>인사말</h2>
                        <div className="cardContent">
                            <ul>
                                {greetingList.map((item, idx)=>{
                                    return(
                                        <li key={idx}>
                                            <p>{item.content}</p>
                                            <label htmlFor={'greetingRadio'+(idx+1)}>인사말{idx+1}<input type="radio" name="greeting" id={'greetingRadio'+(idx+1)} onChange={handleMember} value={item.content}/></label>
                                        </li>
                                    )
                                })}
                                <li>
                                    <textarea onChange={handleGreeing}></textarea>
                                    <label htmlFor='greetingRadioEtc'>직접입력<input type="radio" name="greeting" id='greetingRadioEtc' onChange={handleMember} value={greetingEtc}/></label>
                                </li>
                            </ul>
                        </div>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name='greeting'>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 폰트 */}
                <SwiperSlide>
                    <section className="makeCard cardFont">
                        <h2>폰트</h2>
                        <div className="cardContent">
                            <ul>
                                {fontList.map((item, idx)=>{
                                    return(
                                        <li key={idx}>
                                            <p style={{fontFamily:item.title_en,fontSize:`${fontSize[idx]-1.8}em`,textAlign:'center', lineHeight:'40px'}}>
                                                오랜 기다림 속에서 저희 두 사람,<br />
                                                하나 되어 사랑의 결실을 맺게 되었습니다.<br />
                                                참석하셔서 축복해 주시면 큰 기쁨이겠습니다.<br /> 
                                            </p>
                                            <label htmlFor={'font'+(idx+1)} style={{fontFamily:item.title_en,fontSize:`${fontSize[idx]-1.6}em`}}>
                                                {item.title_ko}
                                                <input type="radio" name="font" id={'font'+(idx+1)} onChange={handleMember} value={item.title_en} style={{width: '30px',outline: 'none', marginRight:'10px'}}/></label>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name='font'>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 배경음악 */}
                <SwiperSlide>
                    <section className="makeCard cardBGM">
                        <h2>배경음악</h2>
                        <h4>음악을 선택해주세요!</h4>
                        <div className="cardContent">
                            <Select
                                name="bgm"
                                modalCloseButton={<ModalCloseButton />}
                                options={[
                                    { value: '0', text: 'BGM을 선택해주세요.' },
                                    { value: '1', text: '분위기 있는 재즈바' },
                                    { value: '2', text: '빛이 들어오는 바닷가' },
                                    { value: '3', text: '비 오는 잔잔한 아침' },
                                    { value: '4', text: '사랑스럽게 고백하는 날' },
                                    { value: '5', text: '창밖을 보며 티타임' },
                                    { value: '6', text: '감성적인 새벽녁 피아노' },
                                    { value: '7', text: '기분좋은 날 함께해요' },
                                ]}
                                caretIcon={<CaretIcon />}
                                prefix=""
                                selectedValue="0"
                                onChange={newValue => {handleBGM('bgm',newValue.value)}}
                            />
                            <Player music={audioSelect}/>
                        </div>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name='bgm'>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>
                
                {/* 대표사진 */}
                <SwiperSlide>
                    <section className="makeCard cardGallery" style={{minHeight : '600px'}}>
                        <h2>대표사진</h2>
                        <h4>카카오톡 공유에 사용될 대표 사진입니다.</h4>
                        <Upload weddingKey={no} type={'thumb'} root={'make'}/>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 갤러리 */}
                <SwiperSlide>
                    <section className="makeCard cardGallery">
                        <h2>갤러리</h2>
                        <div className="cardContent">
                            <h3>갤러리 타입 설정하기</h3>
                            <h4>갤러리에서 사용될 타입을 선택해주세요.</h4>
                            <h4>슬라이드 타입으로 기본설정됩니다.</h4>
                            <div className="gallery_type_area">
                                <div>
                                    슬라이드 타입
                                    <div className="gallery_type_swipe"></div>
                                    <button className={(galleryType === 'swipe')?'btn':'btn btn_reverse'} value={'swipe'} onClick={handleGallery}>{(galleryType === 'swipe')?'선택됨':'선택하기'}</button>
                                </div>
                                <div>
                                    바둑판 타입
                                    <div className="gallery_type_cell">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <button className={(galleryType === 'cell')?'btn':'btn btn_reverse'}value={'cell'} onClick={handleGallery}>{(galleryType === 'cell')?'선택됨':'선택하기'}</button>
                                </div>
                            </div>
                            <div className="gallery_btn_area">
                                
                            </div>
                            <h3>갤러리 사진 업로드</h3>
                            <h4>최대 30장 이미지가 업로드 가능합니다.</h4>
                            <h4>jpg,png,jpeg 형식의 이미지만 가능합니다.</h4>
                        </div>
                        <Upload weddingKey={no} root={'make'}/>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name='photo'>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 동영상 */}
                <SwiperSlide>
                    <section className="makeCard cardVideo">
                        <h2>동영상</h2>
                        <div className="cardContent">
                            <label htmlFor="videoInput">유튜브 링크를 올려주세요</label>
                            <input type="text" name="video" id="videoInput" onChange={handleMember}/>
                            <div style={{textAlign:'center'}}>예시 &gt; https://www.youtube.com/watch?v=<p style={{color:'red'}}>rSihNLmSRP8</p> 뒤에 주소만 입력해주세요!</div>
                        </div>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name='video'>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 순서변경 */}
                <SwiperSlide>
                    <section className="makeCard cardOrder">
                        <h2>순서변경</h2>
                        <div className="cardContent">
                            <ul>
                                {orderList.map((item, idx)=>{
                                    if(idx === 0){
                                        return(
                                            <li key={idx} style={{order:item.order}}>{item.title}</li> 
                                        )
                                    }else{
                                        return(
                                            <li key={idx} style={{order:item.order}}>
                                                <div onClick={()=>{setOrderUp(item)}} className="order_up">
                                                    <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>
                                                </div>
                                                <div className="order_title">
                                                    {item.title}
                                                </div>
                                                <div onClick={()=>{setOrderDown(item, idx)}} className="order_down">
                                                    <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                                                </div>
                                                
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name='order'>청첩장 만들기</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>

                {/* 추가기능 */}
                <SwiperSlide>
                    <section className="makeCard addFunction">
                        <h2>추가기능</h2>
                        <ul>
                            <li>
                                <p>사진확대 on/off</p>
                                <div>
                                    <label htmlFor="zoomOn">
                                        <input type="radio" name="zoom" id="zoomOn" value={'on'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>on</label>
                                    <label htmlFor="zoomOff">
                                        <input type="radio" name="zoom" id="zoomOff" value={'off'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>off</label>
                                </div>
                            </li>
                            <li>
                                <p>글꼴크기 변경</p>
                                <div>
                                    <label htmlFor="fonts">
                                        <input type="radio" name="fontSize" id="fonts" value={'fonts'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>작게</label>
                                    <label htmlFor="fontm">
                                        <input type="radio" name="fontSize" id="fontm" value={'fontm'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>중간</label>
                                    <label htmlFor="fontl">
                                        <input type="radio" name="fontSize" id="fontl" value={'fontl'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>크게</label>
                                </div>
                            </li>
                            <li>
                                <p>신랑 / 신부 순서</p>
                                <div>
                                    <label htmlFor="orderGroom">
                                        <input type="radio" name="orderCouple" id="orderGroom" value={'orderGroom'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>신랑먼저</label>
                                    <label htmlFor="orderBride">
                                        <input type="radio" name="orderCouple" id="orderBride" value={'orderBride'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>신부먼저</label>
                                </div>
                            </li>
                            <li>
                                <p>구역숨김</p>
                                <div style={{
                                    display:'grid',
                                    gridTemplateColumns:'repeat(3, 1fr)'
                                }}>
                                    <label htmlFor="hideGreeting">
                                        <input type="checkbox" name="hideGreeting" id="hideGreeting" value={'hideGreeting'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>인사말</label>
                                    <label htmlFor="hideCalendar">
                                        <input type="checkbox" name="hideCalendar" id="hideCalendar" value={'hideCalendar'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>캘린더</label>
                                    <label htmlFor="hideMessage">
                                        <input type="checkbox" name="hideMessage" id="hideMessage" value={'hideMessage'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>축하메세지</label>
                                    <label htmlFor="hideAttend">
                                        <input type="checkbox" name="hideAttend" id="hideAttend" value={'hideAttend'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>참석여부</label>
                                    <label htmlFor="hideShare">
                                        <input type="checkbox" name="hideShare" id="hideShare" value={'hideShare'} onChange={(e)=>{handleSetting(setting, setSetting, e)}}/>공유</label>
                                </div>
                            </li>
                        </ul>
                        <div className="cardBtn">
                            <SwiperButtonPrev>이전</SwiperButtonPrev>
                            <SwiperButtonNext name='add'>다음</SwiperButtonNext>
                        </div>
                    </section>
                </SwiperSlide>
                
                {/* 마무리 */}
                <SwiperSlide>
                    <section className="makeCard cardFin">
                        <h2>청첩장생성 완료!</h2>
                        <div className="cardContent">
                            <div>
                                <h3>청첩장 URL</h3>
                                <div className="copy_area" onClick={()=>{handleCopyClipBoard('https://babacard.co.kr/card?no='+no)}}>
                                    <p>https://babacard.co.kr/card?no={no}</p>
                                    <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>    
                                </div>
                                <a href={'./card?no='+no}><button className="btn">청첩장 보기</button></a>
                                <a href="./modifyCard"><button className="btn">청첩장 관리</button></a>
                            </div>
                        </div>
                    </section>
                </SwiperSlide>
            </Swiper>

            <Footer />
            <Toaster 
                    position="bottom-center"
                    toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                        background: '#363636',
                        color: '#fff',
                        },
                    
                        // Default options for specific types
                        success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                        },
                    }}
                />
        </Fragment>
    )
}