import React, { Fragment, useEffect, useState } from "react";
import Header from './header'
import Footer from './footer'
import toast, { Toaster } from 'react-hot-toast';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Navigation, A11y } from 'swiper';
import Popup from 'reactjs-popup';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import axios from "axios";
import AudioPlayer from 'react-h5-audio-player';
import { Select, CaretIcon, ModalCloseButton } from 'react-responsive-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { kakaoID } from "./data/snsLogin";
import Upload from "./utils/upload";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import {MemoEffect} from "./utils/effect";
import { getSetting, modifyPlace, postSetting } from "./data/api";
import Effect2 from "./utils/effect2";
import { handleSetting } from "./js/function";

import './data/data'
import 'react-h5-audio-player/lib/styles.css';
import 'react-responsive-select/dist/react-responsive-select.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'reactjs-popup/dist/index.css';
import 'swiper/swiper.min.css'; // core Swiper
import 'swiper/swiper-bundle.min.css'; // core Swiper

export default function ModifyCard(){
    let no = localStorage.getItem('cardNo');
    let bankList = window.Indata.bankList;
    let greetingList = window.Indata.greeting;
    let fontList = window.Indata.fontList;
    let themeList = window.Indata.themeList;
    let [audioSelect, setAudioSelect] = useState(0);
    const [member, setMember] = useState({
        theme : '',
        animation : '',
        greeting : ``,
        ordering : '',
        video:'',
        font:'',
        bgm:'',
        thumb:'',
        galleryType:'',
    });
    
    const [groom, setGroom] = useState({
        type: 'groom',
        name : '',
        nickname : '',
        phone : '',
        bank : '',
        account : '',
        kakao:'',
        fatherDeceased : '',
        fatherName : '',
        fatherPhone : '',
        fatherBank : '',
        fatherAccount : '',
        fatherKakao : '',
        motherDeceased : '',
        motherName : '',
        motherPhone : '',
        motherBank : '',
        motherAccount : '',
        motherKakao : '',
    });

    const [bride, setBride] = useState({
        type: 'bride',
        name : '',
        nickname : '',
        phone : '',
        bank : '',
        account : '',
        kakao:'',
        fatherDeceased : '',
        fatherName : '',
        fatherPhone : '',
        fatherBank : '',
        fatherAccount : '',
        fatherKakao : '',
        motherDeceased : '',
        motherName : '',
        motherPhone : '',
        motherBank : '',
        motherAccount : '',
        motherKakao : '',
    });

    const [wedding, setWedding] = useState({
        place : '',
        date : '',
        time : '',
        detail : '',
        telNo:'',
        zipCode:'',
        address:'',
        info:``,
    });
    
    const [setting, setSetting] = useState({
        zoom:'',
        fontSize:'',
        orderCouple:'',
        hideGreeting:'',
        hideCalendar:'',
        hideMessage:'',
        hideAttend:'',
        hideShare:'',
        photoCover:'',
    });

    const [chkboxgf, setGfChkbox] = useState(false);
    const [chkboxgm, setGmChkbox] = useState(false);
    const [chkboxbf, setBfChkbox] = useState(false);
    const [chkboxbm, setBmChkbox] = useState(false);
    const [coverArr, setCoverArr] = useState([]);
    const [orderList, setOrderList] = useState([
        {
            title:'메인테마',
            order:0
        },
        {
            title:'인사말',
            order:1
        },
        {
            title:'연락하기',
            order:2
        },
        {
            title:'캘린더',
            order:3
        },
        {
            title:'갤러리',
            order:4
        },
        {
            title:'동영상',
            order:5
        },
        {
            title:'계좌정보',
            order:6
        },
        {
            title:'화환보내기',
            order:7
        },
        {
            title:'축하메세지',
            order:8
        },
        {
            title:'위치',
            order:9
        },
        {
            title:'참석여부',
            order:10
        },
    ]);

    // 데이터 임시 저장
    const handleMember = (type, value) => {
        if(type === 'bgm'){
            setAudioSelect(value);
            setMember({ ...member, bgm: value });
        }else if(type === 'theme'){
            setMember({ ...member, theme: (value)+1});
        }else if(type === 'video'){
            setMember({ ...member, video: value});
        }else if(type === 'greeting'){
            setMember({ ...member, greeting: value});
        }else if(type === 'font'){
            setMember({ ...member, font: value});
        }else if(type === 'gallery'){
            setMember({ ...member, galleryType: value});
        }else if(type === 'ani'){
            setMember({ ...member, animation: value});
        }
    };

    const handleGroom = (e) => {
        if(e.target.name === 'fatherDeceased'){
            if(e.target.value === 'y'){
                setGroom({ ...groom, 'fatherDeceased': 'n' });
            }else{
                setGroom({ ...groom, 'fatherDeceased': 'y' });
            }
            setGfChkbox(!chkboxgf);
        }else if(e.target.name === 'motherDeceased'){
            if(e.target.value === 'y'){
                setGroom({ ...groom, 'motherDeceased': 'n' });
            }else{
                setGroom({ ...groom, 'motherDeceased': 'y' });
            }
            setGmChkbox(!chkboxgm);
        }else{
            setGroom({ ...groom, [e.target.name]: e.target.value });
        }
    }

    const handleBride = (e) => {
        if(e.target.name === 'fatherDeceased'){
            if(e.target.value === 'y'){
                setBride({ ...bride, 'fatherDeceased': 'n' });
            }else{
                setBride({ ...bride, 'fatherDeceased': 'y' });
            }
            setBfChkbox(!chkboxbf);
        }else if(e.target.name === 'motherDeceased'){
            if(e.target.value === 'y'){
                setBride({ ...bride, 'motherDeceased': 'n' });
            }else{
                setBride({ ...bride, 'motherDeceased': 'y' });
            }
            setBmChkbox(!chkboxbm);
        }else{
            setBride({ ...bride, [e.target.name]: e.target.value });
        }
    }

    const handleWedding = (e) => {
        setWedding({ ...wedding, [e.target.name]: e.target.value });
    }

    const handleGreeting = (e) => {
        setMember({ ...member, greeting: e.target.value});
    }
    const { daum } = window;

    // 다음지도
    const address = () => {
        new daum.Postcode({
            oncomplete: function(data) {
                var addr = data.address; // 최종 주소 변수
                let zipCode = data.zonecode;
                // 주소 정보를 해당 필드에 넣는다.
                document.getElementById("weddingAddress").value = addr;
                setWedding({ ...wedding, 'address': addr, 'zipCode': zipCode});
            }
        }).open();
    }

    // 배열 만든거 post
    const postCover = (type = null) => {
        console.log('postCover coverArr >>>', coverArr)
        if(coverArr.length > 0){
            let settingJSON = {
                memberId: kakaoID,
                settingName: 'photoCover',
                settingValue: coverArr.join(',')
            }
            !type && postSetting(JSON.stringify(settingJSON)).then(res=>{toast.success('저장성공')
            });
        }else{
            let settingJSON = {
                memberId: kakaoID,
                settingName: 'photoCover',
                settingValue: ''
            }
            !type && postSetting(JSON.stringify(settingJSON)).then(res=>{toast.success('저장성공');
            setCoverArr([]);
            });
        }
    }

    const titleList = ['테마','예식정보(신랑)','예식정보(신부)','인사말','갤러리타입','대표사진','예식장','배경음악','효과','동영상','폰트','순서변경','추가기능','청첩장삭제'];
    //결과물, 1. 요청의 결과
    const [card, setCard] = useState(null);
    // API가 요청 중인지 아닌지, 2. 로딩상태
    const [loading, setLoading] = useState(false);
    const [isCoverDel, setIsCoverDel] = useState(false);
    //error, 3. 에러
    const [error, setError] = useState(null);
    // 포토 리스트
    const [photoKeyList, setPhotoKeyList] = useState([]);
    // 대표사진
    const [thumb, setThumb] = useState(null);

    // 삭제 스테이터스
    const [delChk, setDelChk] = useState(false);
    useEffect(() => {
        const fetchUsers = async () => {
        try {
            setCard(null);
            setError(null);
            setLoading(true); //로딩시작
            const res = await axios.get(
            `https://babacard.co.kr/babacard/api/v1/wedding/?memberId=${kakaoID}`
            );
            const fetchData = res.data[res.data.length-1];
            setCard(fetchData); //데이터 받아오고 setCard에 담기
            setGroom(fetchData.groom)
            setBride(fetchData.bride)
            setWedding(fetchData.place)
            setMember({ 
                ...fetchData,
                theme : parseInt(fetchData.theme),
            });
            // 순서 변경하기
            let copiedOrder = [...orderList];
            let ordering = fetchData.ordering
            if(ordering){
                copiedOrder.forEach((e,idx) => {
                    e.order = parseInt(ordering.split(',')[idx])
                })
            }else{
                copiedOrder.forEach((e,idx) => {
                    e.order = idx
                })
            }
            
            setOrderList(copiedOrder);
            setAudioSelect(parseInt(fetchData.bgm));
            fetchData.photoList.map(item => {
                return(
                    setPhotoKeyList(oldArray => [...oldArray, item.weddingPhotoKey])
                )
            })
            setThumb(fetchData.photoList.findIndex(e => e.isThumbnail === 'Y'));
            if(fetchData.groom.fatherDeceased === 'y' ){
                setGfChkbox(true)
            }
            if(fetchData.groom.motherDeceased === 'y' ){
                setGmChkbox(true)
            }
            if(fetchData.bride.fatherDeceased === 'y' ){
                setBfChkbox(true)
            }
            if(fetchData.bride.motherDeceased === 'y' ){
                setBmChkbox(true)
            }
            // 추가기능 불러오기
            getSetting(fetchData.memberKey).then((res)=>{
                if(res.data.length > 0){
                    let newSetting = { ...setting };
                    res.data.map((item)=>{       
                        return(
                            newSetting[item.settingName] = item.settingValue
                        )    
                    })
                    setSetting(newSetting);
                    if(newSetting.photoCover){
                        const arr = newSetting.photoCover.split(',');
                        setCoverArr(arr);
                    }
                }
            })
        } catch (e) {
            setError(e); //에러가 발생한 경우
        }
        setLoading(false); //로딩이 끝났다는 것을 확인
        };
        fetchUsers();

        // 직접입력 추가
        greetingList.unshift({
            content:'직접입력'
        })
    }, []);

    // 삭제용 훅
    useEffect(()=>{
        console.log('삭제용 훅 overArr >>>', coverArr)
        if(isCoverDel){
            postCover('del');
            setIsCoverDel(false);
        }
    },[isCoverDel])
    
    if (loading) return <div>로딩중...</div>;
    if (error) return goMain();
    
    function goMain(){
        alert('로그인 또는 청첩장을 만들어주세요!');

        setTimeout(()=>{
            window.location.href = './'
        },1000)
    }

    const Player = (props) => (
        <AudioPlayer
            src = {`./audio/music${props.music}.mp3`}
            hasDefaultKeyBindings = {false}
            showJumpControls = {false}
            showDownloadProgress = {false}
            showFilledProgress = {false}
        />
    )

    const putCoupleInfo = () => {
        delete groom.type;
        delete groom.weddingPersonKey;
        delete groom.weddingKey;
        delete bride.type;
        delete bride.weddingPersonKey;
        delete bride.weddingKey;
        let value = {
            weddingKey:card.weddingKey,
            groom: groom,
            bride: bride,
        }

        axios.put(`https://babacard.co.kr/babacard/api/v1/wedding/couple`, value, {
            headers: { 
                "Content-Type": 'application/json',
            },
        }
        )
        .then(()=>{
            toast.success('저장성공!');
            
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    }

    const putMember = () => {
        let value = {
            weddingKey:card.weddingKey,
            ...member
        }
        axios.put(`https://babacard.co.kr/babacard/api/v1/wedding`, value, {
            headers: { 
                "Content-Type": 'application/json',
            },
        }
        )
        .then((res)=>{
            toast.success('저장성공!');
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    }

    const putWedding = () => {
        delete wedding.weddingPlaceKey;
        let value = {
            weddingKey:card.weddingKey,
            ...wedding
        }
        modifyPlace(value).then(()=>{
            toast.success('저장성공!');
        })
    }

    const delCard = () => {
        if(delChk){
            if (window.confirm('정말 삭제합니까? 복구가 불가합니다.')) {
                axios.delete(`https://babacard.co.kr/babacard/api/v1/wedding/${member.weddingKey}`)
                .then(() => {
                    toast.success('삭제 성공!');
                    setTimeout(()=>{window.location.href='./'},1000)
                })
            }
        }else{
            toast.error('삭제 동의를 해주세요!');
        }
    }

    function KakaoPay(){
        return(
            <Fragment>
                <Popup trigger={<FontAwesomeIcon icon={faCircleQuestion}/>} position="center" modal>
                {close => (
                    <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content" style={{display:'flex'}}> 
                        <Swiper
                            modules={[Pagination, Navigation]}
                            pagination = {{ clickable: true }}
                            slidesPerView={1}
                        >
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 1. 카카오톡 앱에서 하단 ... &lt;더보기&gt;를 터치한다</p> 
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 2. 상단에 카카오 pay 버튼을 터치!</p>
                                    <img src="./img/makeCard/kakao1.jpeg" alt="" width={300}/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 3. 카카오 pay 안에서 들어가면 전체 탭을 터치!</p>
                                    <img src="./img/makeCard/kakao2.jpeg" alt="" width={300}/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 4. 전체 탭에서 내려가면 송금 탭에 송금코드 터치!</p>
                                    <img src="./img/makeCard/kakao3.jpeg" alt="" width={300}/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 5. 생성된 QR코드 하단 맨 왼쪽에 링크 복사를 하면 끝!</p>
                                    <img src="./img/makeCard/kakao4.jpeg" alt="" width={260}/>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        </div>
                    </div>
                )}
                </Popup>
            </Fragment>
        )
    }
    
    const setOrderUp = (item) => {
        if(item.order > 1){
            let orderIndex = orderList.findIndex(order => order.order === item.order-1)
            let findIndex = orderList.findIndex(order => order.title === item.title)

            let copiedItems = [...orderList];
            copiedItems[findIndex].order = item.order-1;
            copiedItems[orderIndex].order = item.order+1;
    
            setOrderList(copiedItems);

            let orderArray = [];
            orderList.map((item) => {
                return(
                    orderArray.push(item.order)
                )
            })

            setMember({ ...member, ordering: orderArray.join(',')});
        }else{
            toast.error('맨 첫번째 입니다.')
        }
    }

    const setOrderDown = (item) => {
        if(item.order < 10){
            let orderIndex = orderList.findIndex(order => order.order === item.order+1)
            let findIndex = orderList.findIndex(order => order.title === item.title)
    
            let copiedItems = [...orderList];
            copiedItems[findIndex].order = item.order+1;
            copiedItems[orderIndex].order = item.order-1;
    
            setOrderList(copiedItems);

            let orderArray = [];
            orderList.map((item) => {
                return(
                    orderArray.push(item.order)
                )
            })

            setMember({ ...member, ordering: orderArray.join(',')});
        }else{
            toast.error('맨 마지막 입니다.')
        }
    }

    const propsPhoto = async (text) => {
        if(text === 'true'){
            await axios.get(`https://babacard.co.kr/babacard/api/v1/wedding/?memberId=${kakaoID}`)
            .then((photoRes)=>{
                setPhotoKeyList([])
                photoRes.data[photoRes.data.length-1].photoList.map(item => {
                    return(
                        setPhotoKeyList(oldArray => [...oldArray, item.weddingPhotoKey])
                    )
                })
                setThumb(photoRes.data[photoRes.data.length-1].photoList.findIndex(e => e.isThumbnail === 'Y'));
            })
        }
    }

    // 선택된 카메라 아이디 배열로 만들어서 세팅값에 넣기
    const hanldeCover = (e) => {
        // 체크드 커버
        // 언체크드 컨테인
        if(e.target.checked){
            e.target.parentNode.nextSibling.style.backgroundSize = 'cover'
            if(!coverArr.includes(e.target.value)){
                setCoverArr([...coverArr,e.target.value]);
            }
        }else{
            e.target.parentNode.nextSibling.style.backgroundSize = 'contain'
            const filterArr = coverArr.filter(item => item !== e.target.value)
            setCoverArr(filterArr);

        }
    }

    const delPhoto = (item) => {
        console.log('item >>>', item)
        const oldCover = coverArr.filter(arr => Number(arr) !== Number(item))
        console.log('oldCover >>>', oldCover)
        setCoverArr(oldCover);
        axios.delete(`https://babacard.co.kr/babacard/api/v1/wedding/photo`, {
            params: {
                weddingPhotoKey : item
            }
        })
        .then(() => {
            axios.get(`https://babacard.co.kr/babacard/api/v1/wedding/?memberId=${kakaoID}`)
            .then((photoRes)=>{
                setPhotoKeyList([])
                setIsCoverDel(true);
                photoRes.data[photoRes.data.length-1].photoList.map(item => {
                    return(
                        setPhotoKeyList(oldArray => [...oldArray, item.weddingPhotoKey])
                    )
                })
                toast.success('삭제완료');
            })
        })
    }

    return(
        <Fragment>
            <Header />
            <section className="modifyCard">
                <h1 className="hide">청첩장 관리</h1>
                <h2>청첩장 수정</h2>
                <Table>
                    <Thead>
                        <Tr>
                            {titleList.map((item,idx)=>{
                                return(
                                    <Th key={idx}>{item}</Th>
                                )
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            {/* 테마수정 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested>
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="content"> 
                                            <Select
                                                name="theme"
                                                modalCloseButton={<ModalCloseButton />}
                                                options={
                                                    themeList.map((item, idx)=>{
                                                        return(
                                                            {value: idx, text: item}
                                                        )
                                                    })
                                                }
                                                caretIcon={<CaretIcon />}
                                                selectedValue={parseInt(member.theme)-1}
                                                onChange={newValue => {handleMember('theme', newValue.value)}}
                                            />
                                            <img src={`./img/makeCard/theme/theme${(member.theme)}.jpg`} alt="" width={'60%'} style={{display: 'block',margin: '10px auto', maxWidth: '250px', borderRadius:10, boxShadow:'2px 2px 7px #ccc'}}/>
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putMember();
                                                close();
                                            }}>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 신랑수정 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal editSwiper">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="content"> 
                                            <Swiper
                                                modules={{Navigation, Pagination, A11y}}
                                                slidesPerView={1}
                                                navigation={true}
                                            >
                                                <SwiperSlide className="editPopup">
                                                <h4>신랑</h4>
                                                <ul>
                                                    <li>
                                                        <label htmlFor="groomName">이름</label>
                                                        <input type="text" name="name" id="groomName" onChange={handleGroom} value={groom.name}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="groomTel">연락처</label>
                                                        <input type="text" name="phone" id="groomTel" placeholder="-까지 전부 입력해주세요" onChange={handleGroom} value={groom.phone}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="groomNickname">호칭</label>
                                                        <input type="text" name="nickname" id="groomNickname" onChange={handleGroom} placeholder="예) 장남 / 차남" value={groom.nickname}/>
                                                    </li>
                                                    <li className="makeCard_bankArea">
                                                        <label htmlFor="groomBank">계좌정보</label>
                                                        <select name="bank" id="groomBank" onChange={handleGroom} value={groom.bank}>
                                                            <option value="">은행선택</option>
                                                            {bankList.map((item, idx)=>{
                                                                return(
                                                                    <option value={item} key={idx}>{item}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <input type="text" name="account" id="groomAccount"  placeholder="-까지 전부 입력해주세요" onChange={handleGroom} className="account_input" value={groom.account}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="groomKakao">카카오페이 링크</label><KakaoPay />
                                                        <input type="text" name="kakao" id="groomKakao" onChange={handleGroom} value={groom.kakao}/>
                                                    </li>
                                                </ul>
                                                </SwiperSlide>
                                                <SwiperSlide  className="editPopup">
                                                    <h4>신랑 아버지</h4>
                                                    <ul>
                                                        <li>
                                                            <label htmlFor="groomFatherDeceased">고인여부</label>
                                                            <input type="checkbox" name="fatherDeceased" id="groomFatherDeceased" onChange={handleGroom} value={groom.fatherDeceased} defaultChecked={chkboxgf}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomFatherName">이름</label>
                                                            <input type="text" name="fatherName" id="groomFatherName" onChange={handleGroom} value={groom.fatherName}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomFatherPhone">연락처</label>
                                                            <input type="text" name="fatherPhone" id="groomFatherPhone" onChange={handleGroom} disabled={(chkboxgf)?true:false} value={groom.fatherPhone}/>
                                                        </li>
                                                        <li className="makeCard_bankArea">
                                                            <label htmlFor="groomFatherBank">계좌정보 </label>
                                                            <select name="fatherBank" id="groomFatherBank" onChange={handleGroom} value={groom.fatherBank} disabled={(chkboxgf)?true:false}>
                                                                <option value="">은행선택</option>
                                                                {bankList.map((item, idx)=>{
                                                                    return(
                                                                        <option value={item} key={idx}>{item}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <input type="text" name="fatherAccount" id="groomFatherAccount" placeholder="-까지 전부 입력해주세요" onChange={handleGroom} value={groom.fatherAccount} disabled={(chkboxgf)?true:false}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomFatherKakao">카카오페이</label><KakaoPay />
                                                            <input type="text" name="fatherKakao" id="groomFatherKakao" onChange={handleGroom} disabled={(chkboxgf)?true:false} value={groom.fatherKakao}/>
                                                        </li>
                                                    </ul>
                                                </SwiperSlide>
                                                <SwiperSlide  className="editPopup">
                                                    <h4>신랑 어머니</h4>
                                                    <ul>
                                                    <li>
                                                            <label htmlFor="groomMotherDeceased">고인여부</label>
                                                            <input type="checkbox" name="motherDeceased" id="groomMotherDeceased" onChange={handleGroom} value={groom.motherDeceased} defaultChecked={chkboxgm}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomMotherName">이름</label>
                                                            <input type="text" name="motherName" id="groomMotherName" onChange={handleGroom} value={groom.motherName}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomMotherPhone">연락처</label>
                                                            <input type="text" name="motherPhone" id="groomMotherPhone" onChange={handleGroom} disabled={(chkboxgm)?true:false} value={groom.motherPhone}/>
                                                        </li>
                                                        <li className="makeCard_bankArea">
                                                            <label htmlFor="groomMotherBank">은행 </label>
                                                            <select name="motherBank" id="groomMotherBank" onChange={handleGroom} value={groom.motherBank} disabled={(chkboxgm)?true:false}>
                                                                <option value="">---은행선택---</option>
                                                                {bankList.map((item, idx)=>{
                                                                    return(
                                                                        <option value={item} key={idx}>{item}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomMotherAccount">계좌</label>
                                                            <input type="text" name="motherAccount" id="groomMotherAccount" placeholder="- 까지 전부 입력해주세요" onChange={handleGroom} disabled={(chkboxgm)?true:false} value={groom.motherAccount}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomMotherKakao">카카오페이</label><KakaoPay />
                                                            <input type="text" name="motherKakao" id="groomMotherKakao" onChange={handleGroom} disabled={(chkboxgm)?true:false} value={groom.motherKakao}/>
                                                        </li>
                                                    </ul>
                                                </SwiperSlide>
                                            </Swiper>
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                if(!groom.name){
                                                    toast.error('신랑이름을 넣어주세요!');
                                                }else{
                                                    putCoupleInfo();
                                                    close();
                                                }
                                                }}>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 신부수정 */}
                            <Td>
                            <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal editSwiper">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="content"> 
                                            <Swiper
                                                modules={{Navigation, Pagination, A11y}}
                                                slidesPerView={1}
                                                navigation={true}
                                            >
                                                <SwiperSlide className="editPopup">
                                                <h4>신부</h4>
                                                <ul>
                                                    <li>
                                                        <label htmlFor="brideName">이름</label>
                                                        <input type="text" name="name" id="brideName" onChange={handleBride} value={bride.name}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="brideTel">연락처</label>
                                                        <input type="text" name="phone" id="brideTel" placeholder="-까지 전부 입력해주세요" onChange={handleBride} value={bride.phone}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="brideNickname">호칭</label>
                                                        <input type="text" name="nickname" id="brideNickname" onChange={handleBride} placeholder="예) 장남 / 차남" value={bride.nickname}/>
                                                    </li>
                                                    <li className="makeCard_bankArea">
                                                        <label htmlFor="brideBank">계좌정보</label>
                                                        <select name="bank" id="brideBank" onChange={handleBride} value={bride.bank}>
                                                            <option value="">은행선택</option>
                                                            {bankList.map((item, idx)=>{
                                                                return(
                                                                    <option value={item} key={idx}>{item}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <input type="text" name="account" id="brideAccount"  placeholder="-까지 전부 입력해주세요" onChange={handleBride} className="account_input" value={bride.account}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="brideKakao">카카오페이 링크</label><KakaoPay />
                                                        <input type="text" name="kakao" id="brideKakao" onChange={handleBride} value={bride.kakao}/>
                                                    </li>
                                                </ul>
                                                </SwiperSlide>
                                                <SwiperSlide  className="editPopup">
                                                    <h4>신부 아버지</h4>
                                                    <ul>
                                                        <li>
                                                            <label htmlFor="brideFatherDeceased">고인여부</label>
                                                            <input type="checkbox" name="fatherDeceased" id="brideFatherDeceased" onChange={handleBride} value={bride.fatherDeceased} defaultChecked={chkboxbf}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideFatherName">이름</label>
                                                            <input type="text" name="fatherName" id="brideFatherName" onChange={handleBride} value={bride.fatherName}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideFatherPhone">연락처</label>
                                                            <input type="text" name="fatherPhone" id="brideFatherPhone" onChange={handleBride} disabled={(chkboxbf)?true:false} value={bride.fatherPhone}/>
                                                        </li>
                                                        <li className="makeCard_bankArea">
                                                            <label htmlFor="brideFatherBank">계좌정보 </label>
                                                            <select name="fatherBank" id="brideFatherBank" onChange={handleBride} value={bride.fatherBank} disabled={(chkboxbf)?true:false}>
                                                                <option value="">은행선택</option>
                                                                {bankList.map((item, idx)=>{
                                                                    return(
                                                                        <option value={item} key={idx}>{item}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <input type="text" name="fatherAccount" id="brideFatherAccount" placeholder="-까지 전부 입력해주세요" onChange={handleBride} value={bride.fatherAccount} disabled={(chkboxbf)?true:false}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideFatherKakao">카카오페이</label><KakaoPay />
                                                            <input type="text" name="fatherKakao" id="brideFatherKakao" onChange={handleBride} disabled={(chkboxbf)?true:false} value={bride.fatherKakao}/>
                                                        </li>
                                                    </ul>
                                                </SwiperSlide>
                                                <SwiperSlide  className="editPopup">
                                                    <h4>신부 어머니</h4>
                                                    <ul>
                                                    <li>
                                                            <label htmlFor="brideMotherDeceased">고인여부</label>
                                                            <input type="checkbox" name="motherDeceased" id="brideMotherDeceased" onChange={handleBride} value={bride.motherDeceased} defaultChecked={chkboxbm}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideMotherName">이름</label>
                                                            <input type="text" name="motherName" id="brideMotherName" onChange={handleBride} value={bride.motherName}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideMotherPhone">연락처</label>
                                                            <input type="text" name="motherPhone" id="brideMotherPhone" onChange={handleBride} disabled={(chkboxbm)?true:false} value={bride.motherPhone}/>
                                                        </li>
                                                        <li className="makeCard_bankArea">
                                                            <label htmlFor="brideMotherBank">은행 </label>
                                                            <select name="motherBank" id="brideMotherBank" onChange={handleBride} value={bride.motherBank} disabled={(chkboxbm)?true:false} >
                                                                <option value="">---은행선택---</option>
                                                                {bankList.map((item, idx)=>{
                                                                    return(
                                                                        <option value={item} key={idx}>{item}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideMotherAccount">계좌</label>
                                                            <input type="text" name="motherAccount" id="brideMotherAccount" placeholder="- 까지 전부 입력해주세요" onChange={handleBride} disabled={(chkboxbm)?true:false}  value={bride.motherAccount}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideMotherKakao">카카오페이</label><KakaoPay />
                                                            <input type="text" name="motherKakao" id="brideMotherKakao" disabled={(chkboxbm)?true:false}  onChange={handleBride} value={bride.motherKakao}/>
                                                        </li>
                                                    </ul>
                                                </SwiperSlide>
                                            </Swiper>
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                if(!bride.name){
                                                    toast.error('신부이름을 넣어주세요!');
                                                }else{
                                                    putCoupleInfo();
                                                    close();
                                                }
                                                }}>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 인사말수정 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="content greeting"> 
                                                <Select
                                                    name="greeting"
                                                    modalCloseButton={<ModalCloseButton />}
                                                    options={
                                                        greetingList.map((item, idx)=>{
                                                            return(
                                                                {value: (item.content === '직접입력')?member.greeting:item.content,
                                                                text: item.content}
                                                            )
                                                        })
                                                    }
                                                    caretIcon={<CaretIcon />}
                                                    selectedValue={member.greeting}
                                                    onChange={newValue => handleMember('greeting', newValue.value)}
                                                />
                                                <textarea name="" id="" cols="30" rows="10" value={member.greeting} onChange={handleGreeting} style={{textAlign: 'center',
                                                lineHeight: '25px',
                                                fontSize: '16px',
                                                marginTop: '10px',
                                                border: '1px solid #ccc',
                                                borderRadius: '10px'}}></textarea>
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putMember();
                                                close();
                                            }
                                            }>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 갤러리타입수정 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="content"> 
                                            <div className="makeCard cardGallery modify">
                                                <div className="cardContent">
                                                    <div className="gallery_type_area">
                                                        <div>
                                                            슬라이드 타입
                                                            <div className="gallery_type_swipe"></div>
                                                            <button className={(member.galleryType === 'swipe')?'btn':'btn btn_reverse'} onClick={()=> handleMember('gallery','swipe')}>{(member.galleryType === 'swipe')?'선택됨':'선택하기'}</button>
                                                        </div>
                                                        <div>
                                                            바둑판 타입
                                                            <div className="gallery_type_cell">
                                                                {
                                                                    Array(9).fill(null).map((_,idx) => (
                                                                        <div key={idx}></div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <button className={(member.galleryType === 'cell')?'btn':'btn btn_reverse'} onClick={()=> handleMember('gallery','cell')}>{(member.galleryType === 'cell')?'선택됨':'선택하기'}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putMember();
                                                close();
                                            }}>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 대표사진 수정 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="header">청첩장 공유, 테마에 사용되는 이미지입니다.</div>
                                            <div className="content">
                                                {(photoKeyList[thumb])?
                                                <div style={{
                                                    background:`url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${photoKeyList[thumb]}) center/cover no-repeat`,
                                                    width:'280px',
                                                    height:'280px',
                                                    margin:'0 auto'
                                                }}>
                                                </div>
                                                :
                                                <Upload weddingKey={no} type={'thumbModify'} propsPhoto={propsPhoto}/>

                                                }
                                            {(photoKeyList[thumb])?
                                            <button className="btn editBtn" onClick={()=>{
                                                delPhoto(photoKeyList[thumb]);
                                                setTimeout(()=>{
                                                    window.location.reload();
                                                })
                                            }}>삭제</button>
                                            :
                                            ''
                                            }
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 예식장수정 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="content"> 
                                            <div className="editPopup">
                                                <ul>
                                                    <li>
                                                        <label htmlFor="weddingDate">날짜 <small>[필수]</small></label>
                                                        <input type="date" name="date" id="weddingDate" onChange={handleWedding} value={wedding.date || ''}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="weddingTime">시간 <small>[필수]</small></label>
                                                        <input type="time" name="time" id="weddingTime" onChange={handleWedding} step="900" value={wedding.time  || ''}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="weddingPlace">예식장명 <small>[필수]</small></label>
                                                        <input type="text" name="place" id="weddingPlace" placeholder="예식장명" onChange={handleWedding} value={wedding.place  || ''}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="weddingDetail">층/홀/실</label>
                                                        <input type="text" name="detail" id="weddingDetail" placeholder="3층 / 그랜드홀" onChange={handleWedding} value={wedding.detail  || ''} />
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li></li>
                                                    <li>
                                                        <label htmlFor="weddingAddress">주소 <small>[필수]</small></label>
                                                        <input type="text" id="weddingAddress" placeholder="주소" readOnly onClick={address} value={wedding.address  || ''}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="weddingTel">예식장 연락처</label>
                                                        <input type="tel" name="telNo" id="weddingTel" placeholder="" onChange={handleWedding} value={wedding.telNo  || ''}/>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="weddingInfo">안내사항</label>
                                                        <textarea name="info" id="weddingInfo" onChange={handleWedding} style={{height:60, border:'1px solid #ccc'}} value={wedding.info || ''}/>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                if(!wedding.date){
                                                    toast.error('예식날짜를 넣어주세요!');
                                                }else if(!wedding.time){
                                                    toast.error('예식시간을 넣어주세요!');
                                                }else if(!wedding.place){
                                                    toast.error('예식장소를 넣어주세요!');
                                                }else if(!wedding.address){
                                                    toast.error('예식장 주소를 넣어주세요!');
                                                }else{
                                                    putWedding();
                                                    close();
                                                }
                                            }
                                            }>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 배경음악수정 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="header">배경음악수정</div>
                                            <div className="content"> 
                                            <Select
                                                name="bgm"
                                                modalCloseButton={<ModalCloseButton />}
                                                options={[
                                                    { value: '0', text: 'BGM을 선택해주세요.' },
                                                    { value: '1', text: '분위기 있는 재즈바' },
                                                    { value: '2', text: '빛이 들어오는 바닷가' },
                                                    { value: '3', text: '비 오는 잔잔한 아침' },
                                                    { value: '4', text: '사랑스럽게 고백하는 날' },
                                                    { value: '5', text: '창밖을 보며 티타임' },
                                                    { value: '6', text: '감성적인 새벽녁 피아노' },
                                                    { value: '7', text: '기분좋은 날 함께해요' },
                                                ]}
                                                caretIcon={<CaretIcon />}
                                                prefix=""
                                                selectedValue={member.bgm}
                                                onChange={newValue => handleMember('bgm', newValue.value)}
                                            />
                                            <Player music={audioSelect}/>
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putMember();
                                                close();
                                            }}>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 애니메이션 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal animation">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="header">효과 수정</div>
                                            <div className="content"> 
                                            <Select
                                                name="animation"
                                                modalCloseButton={<ModalCloseButton />}
                                                options={[
                                                    { value: '0', text: '효과 없음' },
                                                    { value: '3', text: '효과 1' },
                                                    { value: '2', text: '효과 2' },
                                                    { value: '4', text: '효과 3' },
                                                ]}
                                                caretIcon={<CaretIcon />}
                                                selectedValue={member.animation}
                                                onChange={newValue => handleMember('ani', newValue.value)}
                                            />
                                            <div style={{
                                                position:'relative',
                                                display:'flex',
                                                justifyContent:'center',
                                                overflow:'hidden',
                                                width:'100%',
                                                maxWidth:'200px',
                                                margin:'10px auto'
                                                }}>
                                                <img src={`./img/makeCard/theme/theme${(member.theme)}.jpg`} alt="" width={'100%'} style={{display: 'block', maxWidth: '200px'}}/>
                                                {member.animation !== '2' ?
                                                <Effect2 ani={member.animation}/>:
                                                <MemoEffect effect={member.animation} />}
                                            </div>
                                            
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putMember();
                                                close();
                                            }}>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 동영상 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>&times;</button>
                                            <div className="header">동영상 링크 수정</div>
                                            <div className="content"> 
                                                <div>
                                                    <input type="text" name="video" id="" onChange={(e) => {handleMember('video', e.target.value)}} value={member.video} maxLength='11'style={{ margin: '20px auto',display: 'block'}}/>
                                                    <div style={{textAlign:'center'}}>예시 &gt; https://www.youtube.com/watch?v=<p style={{color:'red'}}>rSihNLmSRP8</p> 뒤에 주소만 입력해주세요!</div>
                                                </div>
                                            </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putMember();
                                                close();
                                            }}>수정</button></div>
                                        </div>
                                    )}
                                </Popup>
                            </Td>

                            {/* 폰트 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false}>
                                        {close => (
                                            <div className="modal">
                                                <button className="close" onClick={close}>&times;</button>
                                                <div className="header">폰트 수정</div>
                                                <div className="content"> 
                                                <Select
                                                    name="font"
                                                    modalCloseButton={<ModalCloseButton />}
                                                    options={
                                                        fontList.map((item)=>{
                                                            return(
                                                                {
                                                                    value: item.title_en, 
                                                                    text: item.title_ko, 
                                                                    markup: <span style={{fontFamily:item.title_en}}>{item.title_ko}</span>
                                                                }
                                                            )
                                                        })
                                                    }
                                                    caretIcon={<CaretIcon />}
                                                    selectedValue={member.font}
                                                    onChange={newValue => handleMember('font', newValue.value)}
                                                />
                                                <div style={{fontFamily:member.font, fontSize:20, textAlign:'center',margin:'20px 0', lineHeight:'30px'}}>
                                                    오랜 기다림 속에서 저희 두 사람<br />
                                                    하나 되어 사랑의 결실을 맺게 되었습니다.<br />
                                                    참석하셔서 축복해 주시면 큰 기쁨이겠습니다.
                                                </div>
                                                </div>
                                                <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                    putMember();
                                                    close();
                                                }}>수정</button></div>
                                            </div>
                                        )}
                                </Popup>
                            </Td>

                            {/* 순서변경 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested>
                                        {close => (
                                            <div className="modal">
                                                <button className="close" onClick={close}>&times;</button>
                                                <div className="content"> 
                                                <section className="makeCard cardOrder" style={{width:'100%',minHeight:'0'}}>
                                                    <ul>
                                                        {orderList.map((item, idx)=>{
                                                            if(idx === 0){
                                                                return(
                                                                    <li key={idx} style={{order:item.order, margin:'5px 0', height:35}}>{item.title}</li> 
                                                                )
                                                            }else{
                                                                return(
                                                                    <li key={idx} style={{order:item.order, margin:'5px 0', height:35}}>
                                                                        <div onClick={()=>{setOrderUp(item)}} className="order_up">
                                                                            <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>
                                                                        </div>
                                                                        <div className="order_title">
                                                                            {item.title}
                                                                        </div>
                                                                        <div onClick={()=>{setOrderDown(item)}} className="order_down">
                                                                            <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                                                                        </div>
                                                                        
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </ul>
                                                </section>
                                                </div>
                                                <div className="footer">
                                                    <button className="btn editBtn" onClick={()=>{
                                                        putMember();
                                                        close();
                                                    }}>수정</button>
                                                </div>
                                            </div>
                                        )}
                                </Popup>
                            </Td>

                            {/* 추가기능 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">수정</button>} position="center" modal nested>
                                        {close => (
                                            <div className="modal addFunction">
                                                <button className="close" onClick={close}>&times;</button>
                                                <div className="content"> 
                                                <ul>
                                                    <li>
                                                        <p>사진확대 on/off</p>
                                                        <div>
                                                            <label htmlFor="zoomOn">
                                                                <input type="radio" name="zoom" id="zoomOn" value={'on'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.zoom === 'on'?true:false}/>on</label>
                                                            <label htmlFor="zoomOff">
                                                                <input type="radio" name="zoom" id="zoomOff" value={'off'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.zoom === 'off'?true:false}/>off</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p>글꼴크기 변경</p>
                                                        <div>
                                                            <label htmlFor="fonts">
                                                                <input type="radio" name="fontSize" id="fonts" value={'fonts'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.fontSize === 'fonts'?true:false}/>작게</label>
                                                            <label htmlFor="fontm">
                                                                <input type="radio" name="fontSize" id="fontm" value={'fontm'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.fontSize === 'fontm'?true:false}/>중간</label>
                                                            <label htmlFor="fontl">
                                                                <input type="radio" name="fontSize" id="fontl" value={'fontl'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.fontSize === 'fontl'?true:false}/>크게</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p>신랑 / 신부 순서</p>
                                                        <div>
                                                            <label htmlFor="orderGroom">
                                                                <input type="radio" name="orderCouple" id="orderGroom" value={'orderGroom'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.orderCouple === 'orderGroom'?true:false}/>신랑먼저</label>
                                                            <label htmlFor="orderBride">
                                                                <input type="radio" name="orderCouple" id="orderBride" value={'orderBride'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.orderCouple === 'orderBride'?true:false}/>신부먼저</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p>구역숨김</p>
                                                        <div style={{
                                                            display:'grid',
                                                            gridTemplateColumns:'repeat(3, 1fr)'
                                                        }}>
                                                            <label htmlFor="hideGreeting">
                                                                <input type="checkbox" name="hideGreeting" id="hideGreeting" value={'hideGreeting'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.hideGreeting === 'Y'?true:false}/>인사말</label>
                                                            <label htmlFor="hideCalendar">
                                                                <input type="checkbox" name="hideCalendar" id="hideCalendar" value={'hideCalendar'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.hideCalendar === 'Y'?true:false}/>캘린더</label>
                                                            <label htmlFor="hideMessage">
                                                                <input type="checkbox" name="hideMessage" id="hideMessage" value={'hideMessage'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.hideMessage === 'Y'?true:false}/>축하메세지</label>
                                                            <label htmlFor="hideAttend">
                                                                <input type="checkbox" name="hideAttend" id="hideAttend" value={'hideAttend'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.hideAttend === 'Y'?true:false}/>참석여부</label>
                                                            <label htmlFor="hideShare">
                                                                <input type="checkbox" name="hideShare" id="hideShare" value={'hideShare'} onChange={(e)=>{handleSetting(setting, setSetting, e)}} checked={setting.hideShare === 'Y'?true:false}/>공유</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div className="footer">
                                                    <button className="btn editBtn" onClick={()=>{
                                                        for (const key in setting) {
                                                            if(setting.hasOwnProperty(key)) {
                                                                if(setting[key]){
                                                                    let settingJSON = {
                                                                        memberId: kakaoID,
                                                                        settingName: key,
                                                                        settingValue: setting[key]
                                                                    }
                                                                    postSetting(JSON.stringify(settingJSON)).then(res=>{console.log(res)});
                                                                }
                                                            }
                                                        }
                                                        toast.success('저장성공!');
                                                        close();
                                                    }}>수정</button>
                                                </div>
                                            </div>
                                        )}
                                </Popup>
                            </Td>

                            {/* 청첩장 삭제 */}
                            <Td>
                                <Popup trigger={<button className="btn modifyBtn">삭제</button>} position="center" modal nested>
                                        {close => (
                                            <div className="modal">
                                                <button className="close" onClick={close}>&times;</button>
                                                <div className="header">청첩장삭제</div>
                                                <div className="content"> 
                                                    <div>
                                                        <h2 style={{color:'red',textAlign:'center'}}>주의</h2>
                                                        <h2 style={{color:'red',textAlign:'center',lineHeight:'30px'}}>
                                                            삭제 후 복구가 불가능합니다.<br /> 
                                                            청첩장 전체 삭제를 원하시면 <br /> 
                                                            체크버튼을 누른후 삭제 버튼을 눌러주세요.
                                                        </h2>
                                                        <div
                                                        style={{
                                                            display:'flex',
                                                            justifyContent:'center',
                                                            alignItems:'center'
                                                        }}>
                                                            <input type="checkbox" name="" id="delChk" style={{width:'50px',outline:'none'}} onChange={()=>setDelChk(!delChk)}/>
                                                            <label htmlFor="delChk" style={{fontSize:'18px'}}>청첩장을 삭제합니다.</label>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                                <div className="footer">
                                                    <button className="btn editBtn" onClick={()=>{
                                                        delCard();
                                                    }}>삭제</button>
                                                </div>
                                            </div>
                                        )}
                                </Popup>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
                {photoKeyList && photoKeyList.length > 0 && 
                <>
                <h2>갤러리</h2>
                <div className="modifyGallery">
                    {photoKeyList.map((item, idx)=>{
                        return(
                            <div key={idx}>
                                <label htmlFor={`cover${idx}`}>
                                    <input type="checkbox" name={`cover${idx}`} id={`cover${idx}`} style={{width:20, marginRight:10}} onChange={hanldeCover} value={item} 
                                    checked={coverArr.includes(item.toString())? true : false}/>
                                    꽉 차게
                                </label>
                                <div style={{
                                    background:`url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${item}) no-repeat center/${coverArr.includes(item.toString())? 'cover' : 'contain'}`,
                                    width:'100px',
                                    height:'100px'
                                }}>
                                </div>
                                <div className="btnArea">
                                    <button onClick={()=>delPhoto(item)} className='gallery_btn'>삭제</button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="btn cover" onClick={()=>{postCover()}}>사진크기저장</div>
                </>}
                <div className="block"></div>
                <h2>갤러리 업로드</h2>
                <Upload weddingKey={no} type={'photoModify'} propsPhoto={propsPhoto} list={photoKeyList.length}/>
            </section>
            <Footer />
            <Toaster 
                    position="bottom-center"
                    toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                        background: '#363636',
                        color: '#fff',
                        },
                    
                        // Default options for specific types
                        success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                            },
                        },

                        error: {
                            style: {
                                background: 'red',
                            },
                        },
                    }}
                />
        </Fragment>
    )
}