import React from "react";
import '../data/data'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faHeart } from "@fortawesome/free-solid-svg-icons";


export default function Effect2 (props) {
    const snow = window.Indata.snow;
    let img = ''
    if(props.ani === '3'){
        img = <FontAwesomeIcon icon={faCircle} />
        return(
        <div>
            {snow.map((idx)=>{
                return(
                    <div key={idx} className='snowflake' style={{
                        fontSize:`${Math.floor(Math.random()*15+5)}px`,
                        opacity:`${Math.random()+0.3}`,
                        filter:'blur(2px)',
                        boxShadow:'0 0 2px #2b2b2b'
                    }}>{img}</div>
                )
            })}
        </div>
        )
    }else if(props.ani === '4'){
        img = <FontAwesomeIcon icon={faHeart} />
        return(
        <div>
            {snow.map((idx)=>{
                return(
                    <div key={idx} className='snowflake' style={{
                        fontSize:`${Math.floor(Math.random()*15+10)}px`,
                        opacity:`${Math.random()+0.3}`,
                        color:'#f99393'
                    }}>{img}</div>
                )
            })}
        </div>
        )
    }else if(props.ani === '5'){
        return(
        <div>
            
        </div>
        )
    }
    
}