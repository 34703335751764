export default function Logout(){
    // 카카오로그인 로컬스토리지 삭제
    localStorage.removeItem('kakaoLogin');
    localStorage.removeItem('kakaoToken');
    localStorage.removeItem('kakaoID');
    localStorage.removeItem('cardNo');

    // 어드민로그인 로컬스토리지 삭제
    sessionStorage.removeItem('admin');

    window.location.href='./'
}