import React, { Fragment, useRef, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import imageCompression from 'browser-image-compression';
import ProgressBar from "@ramonak/react-progress-bar";

const Upload = (props) => {
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState('');
    const inputRef = useRef(null);
    const formRef = useRef(null);
    const [src, setSrc] = useState([]);
    const [per, setPer] = useState([]);
    const maxLength = props.list || 0;

    const [isUploading, setIsUploading] = useState(true);

    const handleClick = () => inputRef && inputRef.current && inputRef.current.click();

    const handleFiles = (e) => {
        if(e.target.files && e.target.files.length > 0){
            const oldFile = files;
            if(e.target.files.length > 0){
                Array.from(e.target.files).forEach(item => {
                    oldFile.push(item)
                })
            }

            setFiles(oldFile)
            const temp = [];
            Array.from(e.target.files).map((item) => {
                return(
                        temp.push(URL.createObjectURL(item))
                    )
                })
            setSrc(temp.concat(src))
            inputRef.current.value = null
            // console.log('temp >>>', temp)
        }
    };

    const getImgUpload = async (image, idx) => {
        const options = {
            maxSizeMB: 0.6,
            onProgress: (progress) => {
                setPer(prev => {
                    const arr = [...prev];
                    arr[idx] = progress;
                    return arr;
                });
            }
        };
        try{
            const resizingBlob = await imageCompression(image, options);
            const resizingFile = new File([resizingBlob], image.name, { type: image.type });
            return resizingFile;
        }
        catch(error){
            console.error('Error during image compression:', error);
            throw error;
        }
    };

    const [makeDone, setMakeDone] = useState(false)

    const handleSubmit = async (e) => {
        console.log('asdadasdklnaslkdnalksndklasd')
        if(isUploading){
            setIsUploading(false)
            e.preventDefault();
            if(files.length > 0 && files.length + maxLength < 31) {
                const formData = new FormData();
                formData.append('weddingKey', props.weddingKey);
                if(props.type === 'thumb' || props.type === 'thumbModify'){
                    formData.append('isThumbnail', 'Y');
                }
                else{
                    formData.append('isThumbnail', 'N');
                }
                const date = new Date();
                
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 두 자리로 만듭니다.
                const day = date.getDate().toString().padStart(2, '0');
                
                const formattedDate = `${year}-${month}-${day}`;

                setPer(Array(src.length).fill(null));

                Promise.all(files.map((item, idx)=> getImgUpload(item, idx))).then(async(covertImgList) => {
                    covertImgList.forEach(file => 
                        {
                            const randomNum = Math.floor(Math.random() * 10000000);
                            formData.append('photos', file, `${formattedDate}_${randomNum}_${file.name}`)
                        }
                    );
                    // for (const entry of formData.entries()) {
                    //     if (entry[1] instanceof File) {
                    //     console.log(`${entry[0]}:`);
                    //     console.log(`  name: ${entry[1].name}`);
                    //     console.log(`  size: ${entry[1].size} bytes`);
                    //     console.log(`  type: ${entry[1].type}`);
                    //     console.log(`  lastModifiedDate: ${entry[1].lastModifiedDate}`);
                    //     } else {
                    //     console.log(`${entry[0]}: ${entry[1]}`);
                    //     }
                    // }

                    setMessage('변환된 이미지를 업로드중입니다. 잠시만 기다려주세요!')
                    await axios.post('https://babacard.co.kr/babacard/api/v1/wedding/photo/', formData)
                        .then(data => {
                            if(props.root === 'make'){
                                setMessage(data.data.message);
                                setMakeDone(true)
                                setIsUploading(true)
                                toast.success('업로드 성공!');
                            }
                            else{
                                setMessage(data.data.message);
                                setSrc([]);
                                if(props.type === 'thumbModify'){
                                    sendThumb();
                                }
                                if(props.type === 'photoModify'){
                                    sendThumb();
                                }
                                toast.success('업로드 성공!');
                                setIsUploading(true)
                                setFiles([]);
                                setSrc([]);
                                formRef.current && formRef.current.reset();
                                window.location.reload();
                            }
                        })
                        .catch((error) => setMessage('Error'));
                }).catch(error => {
                    // 에러 핸들링
                    console.error('Error during image upload:', error);
                });
            }
            else{
                alert('갤러리는 최대 30개 까지 가능합니다.\n기존 갤러리를 삭제 해주시거나, 새로운 파일을 제거해주세요')
            }
        }
    }

    // 삭제
    const delSrc = (item) => {
        let idx = src.findIndex((el) => el === item);
        let temp = src.filter((element) => element !== item);
        let fileTemp = files.filter((element) => element !== files[idx]);
        setSrc(temp);
        setFiles(fileTemp);
    }

    // 부모 데이터 보내기
    const sendThumb = (e) => {
        props.propsPhoto('true');
    }

    if(props.type === 'thumb'){
        return(
            <form ref={formRef}>
            <div className="upload-box file_upload_btn" onClick={handleClick}
                style={{
                    background: `url(${src}) center/cover no-repeat`
                }}>
                {(src.length !== 0)?'':'클릭해주세요!'}
            </div>
            {(src.length !== 0)?
            <div style={{display:'flex',justifyContent:'center'}}>
                <button type='button' className="btn" onClick={()=>{
                    setSrc([]);
                    setFiles([]);
                    setMakeDone(false)
                    setIsUploading(true)
                    setMakeDone(false)
                    }}
                    style={{width:'100%',maxWidth:'300px'}}>삭제</button>
            </div>
            :''}
            <input type="file" ref={inputRef} onChange={handleFiles} style={{ display: 'none' }} accept="image/*"/>
            {
                !isUploading &&
                <ProgressBar 
                    completed={per[0]} 
                    bgColor={'#FFD06B'} 
                    width='100%' 
                    labelSize='10px'
                />
            }
            <div style={{display : 'flex', justifyContent : 'center'}}>{message}</div>
            {
                !makeDone && 
                <div style={{display:'flex',justifyContent:'center'}}>
                    {isUploading ? 
                        <button type="submit" className={(src.length !== 0)?'btn':'hide'} 
                        style={{width : props.type !== 'photoModify' ? '' : '50%'}} 
                        onClick={handleSubmit}>업로드</button>:
                        <button className='btn'>업로드 중입니다...</button>
                    }
                </div>
            }
            </form>
        )
    }else if(props.type === 'thumbModify'){
        return(
            <form ref={formRef}>
            <div className="upload-box file_upload_btn" onClick={handleClick}
                style={{
                    background: `url(${src}) center/cover no-repeat`
                }}>
                {(src.length !== 0)?'':'클릭해주세요!'}
            </div>
            {(src.length !== 0)?
            <div style={{display:'flex',justifyContent:'center'}}>
                <button type='button' className="btn" onClick={()=>{
                    setSrc([]);
                    setFiles([]);
                    }}
                    style={{width:'100%',maxWidth:'300px'}}>삭제</button>
            </div>
            :''}
            <input type="file" ref={inputRef} onChange={handleFiles} style={{ display: 'none' }} accept="image/*"/>
            {
                !isUploading &&
                <ProgressBar 
                    completed={per[0]} 
                    bgColor={'#FFD06B'} 
                    width='100%' 
                    labelSize='10px'
                />
            }
            <div style={{display : 'flex', justifyContent : 'center'}}>{message}</div>
            <div style={{display:'flex',justifyContent:'center'}}>
                {isUploading ? 
                    <button type="submit" className={(src.length !== 0)?'btn':'hide'} 
                    style={{width : props.type !== 'photoModify' ? '' : '50%'}} 
                    onClick={handleSubmit}>업로드</button>:
                    <button className='btn'>업로드 중입니다...</button>
                }
            </div>
            </form>
        )
    }else{
        return (
            <form ref={formRef}>
            <div className="upload-box file_upload_btn" onClick={handleClick}>
                클릭해주세요!
            </div>

            <input type="file" ref={inputRef} onChange={handleFiles} style={{ display: 'none' }} multiple accept="image/*"/>
            <div style={{
                display:'flex',
                overflowX: 'scroll',
                height : props.type !== 'photoModify' ? '230px' : 'auto',
                margin: '30px 0'}}>
                {src.map((item,idx)=>{
                    return(
                        <Fragment key={idx}>
                            <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
                                {
                                    !isUploading &&
                                    <ProgressBar 
                                        key={idx} 
                                        completed={per[idx]} 
                                        bgColor={'#FFD06B'} 
                                        width='100px' 
                                        labelSize='10px'
                                    />
                                }
                                <div style={{
                                    height:'100px',
                                    width:'100px',
                                    background: `url(${item}) center/cover no-repeat`    
                                }}>
                                </div>
                                <div>
                                    <div><button type='button' className="gallery_btn" onClick={()=>{delSrc(item)}} style={{marginBottom:'20px'}}>삭제</button></div>
                                </div>
                            </div>
                        </Fragment>
                    )
                })}
            </div>
            <div style={{display : 'flex', justifyContent : 'center'}}>{message}</div>
            <div style={{height : props.type !== 'photoModify' ? '50px' : 'auto', display:'flex',justifyContent:'center'}}>
            {isUploading ? 
                <button type="submit" className={(src.length !== 0)?'btn':'hide'} 
                style={{width : props.type !== 'photoModify' ? '' : '50%'}} 
                onClick={handleSubmit}>업로드</button>:
                <button className='btn'>업로드 중입니다...</button>
            }
            </div>
            </form>
        )
    }
}

export default Upload;