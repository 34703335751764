import React from 'react';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CardFooter(){
    return(
        <footer style={{maxWidth:500,display:'flex',flexDirection:'column',alignItems: 'center'}}>
            <div className='logo_box' onClick={()=>{window.location.href='./'}}></div>
            <div>
                <small style={{display:'block',textAlign:'center'}}>&copy; COPYRIGHT 2017 (주)바바컴퍼니<br />BABACOMPANY ALL RIGHTS RESERVED.</small>
            </div>
            <div className='backtotop' onClick={()=>{window.scrollTo({top:0, left:0, behavior:'smooth'});}}>
                <FontAwesomeIcon icon={faCaretUp} />
            </div>
        </footer>
    )

}