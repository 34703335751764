import React, { Fragment, useState } from "react";
import './data/data';
import toast, { Toaster } from 'react-hot-toast';
import { encrypt, decrypt } from "./utils/encdec";

export default function LoginAdmin(){
    const [login, setLogin] = useState({
        id:'',
        pw:'',
    });
    const admin = window.Indata.admin;

    const handleInput = (e) => {
        if(e.target.name === 'pw'){
            setLogin({...login, 'pw': encrypt(e.target.value,"secret key") });
        }else{
            setLogin({...login, [e.target.name]: e.target.value });
        }
    }

    const adminLogin = () => {
        if(admin.id === login.id){
            if(decrypt(admin.pw,"secret key") === decrypt(login.pw, "secret key")){
                sessionStorage.setItem('admin',true);
                toast.success('정상 로그인되었습니다.');
                setTimeout(()=>{window.location.href='./'},1000)
            }else{
                toast.error('로그인 실패');
            }
        }else{
            toast.error('로그인 실패');
        }
    }
    return(
        <Fragment>
            <div style={{display:'flex',flexDirection:'column', width:'100%', alignItems:'center', justifyContent:'center', height:'100vh'}}>
                <h2>바바카드 어드민 로그인</h2>
                <input type="text" name="id" id="id" onChange={handleInput}/>
                <input type="password" name="pw" id="pw" onChange={handleInput} onKeyDown={(e) => {if(e.key === 'Enter'){adminLogin()}}}/>
                <button className="btn" onClick={adminLogin}>로그인</button>
            </div>
            <Toaster 
                    position="bottom-center"
                    toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                        background: '#363636',
                        color: '#fff',
                        },
                    
                        // Default options for specific types
                        success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                        },
                    }}
                />
        </Fragment>
    )
}