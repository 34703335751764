import React, { Fragment, useEffect, useState } from "react";
import Header from './header'
import Footer from './footer'
import toast, { Toaster } from 'react-hot-toast';
import { attendanceChk, replyChk, replyDel } from "./data/api";


export default function InfoCard() {
    const no = localStorage.getItem('cardNo');
    const [attendanceList, setAttendanceList] = useState();
    const [replyList, setReplyList] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null);
        setLoading(true); //로딩시작

        attendanceChk(no).then((res)=>{
            setAttendanceList(res.data);
        })

        replyChk(no).then((res)=>{
            setReplyList(res.data);
        })
        setLoading(false);
    }, []);

    if (loading) return <div>로딩중...</div>;
    if (error) return null;
    if (!replyList) return null;

    const delMessage = (item) => {
        if(window.confirm('정말 삭제합니까? 복구가 불가합니다.')) {
            const data = {
                weddingReplyKey : parseInt(item.weddingReplyKey),
                password:'nimda',
            }
            
            replyDel(data).then(()=>{
                replyChk(no).then((res)=>{
                    setReplyList(res.data);
                    toast.success('삭제성공!');
                })
            })
        }
    }
    return(
        <Fragment>
            <Header />
            <section>
                <h1 className="hide">청첩장</h1>
                <h2 style={{textAlign:'center', margin:'40px 0 20px'}}>참석여부</h2>
                <ul className="attendance_list">
                    <li>
                        <div>이름</div>
                        <div>휴대전화</div>
                        <div>참석여부</div>
                        <div>식사여부</div>
                    </li>
                    <div style={{textAlign:'center'}}>{(attendanceList && attendanceList.length === 0)?'참석여부 정보가 없습니다.':''}</div>
                    {attendanceList && attendanceList.map((item,idx)=>{
                        return(
                            <li key={idx}>
                                <div>{item.name}</div>
                                <div>{item.phone}</div>
                                <div>{(item.attended === 't')?'참석':'불참석'}</div>
                                <div>{item.meals}명</div>
                            </li>
                        )
                    })}
                </ul>
                <h2 style={{textAlign:'center', margin:'40px 0 20px'}}>축하메세지</h2>
                <ul className="reply_list">
                    <li>
                        <div>이름</div>
                        <div>메세지</div>
                        <div>삭제</div>
                    </li>
                    <div style={{textAlign:'center'}}>{(replyList.length === 0)?'축하메세지 정보가 없습니다.':''}</div>
                    {replyList.map((item,idx)=>{
                        return(
                            <li key={idx}>
                                <div>{item.name}</div>
                                <div>{item.content}</div>
                                <div onClick={()=>delMessage(item)}>x</div>
                            </li>
                        )
                    })}
                </ul>
            </section>
            <Footer />
            <Toaster 
                    position="bottom-center"
                    toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                        background: '#363636',
                        color: '#fff',
                        },
                    
                        // Default options for specific types
                        success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                        },

                        error: {
                        style: {
                            background: 'red',
                        },
                        },
                    }}
                />
        </Fragment>
    )
}