import React, { Fragment, useEffect,useState } from "react";
import Header from './header'
import Footer from './footer'
import Popup from 'reactjs-popup';
import toast, { Toaster } from 'react-hot-toast';
import './data/data'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination } from 'swiper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

import 'swiper/swiper.min.css'; // core Swiper
import 'swiper/swiper-bundle.min.css'; // core Swiper
import 'reactjs-popup/dist/index.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { CaretIcon, ModalCloseButton, Select } from "react-responsive-select";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Player from "./utils/player";
import { showMember, modifyPlace, attendanceChk, replyDel, replyChk } from "./data/api";

export default function Admin(){
    const themeList = window.Indata.themeList;
    let greetingList = window.Indata.greeting;
    let fontList = window.Indata.fontList;
    let bankList = window.Indata.bankList;
    const [member, setMember] = useState({
        theme : '',
        animation : '',
        greeting : ``,
        ordering : '',
        video:'',
        font:'',
        bgm:'',
        thumb:'',
        galleryType:'',
    });
    
    const [groom, setGroom] = useState({
        type: 'groom',
        name : '',
        nickname : '',
        phone : '',
        bank : '',
        account : '',
        kakao:'',
        fatherDeceased : '',
        fatherName : '',
        fatherPhone : '',
        fatherBank : '',
        fatherAccount : '',
        fatherKakao : '',
        motherDeceased : '',
        motherName : '',
        motherPhone : '',
        motherBank : '',
        motherAccount : '',
        motherKakao : '',
    });

    const [bride, setBride] = useState({
        type: 'bride',
        name : '',
        nickname : '',
        phone : '',
        bank : '',
        account : '',
        kakao:'',
        fatherDeceased : '',
        fatherName : '',
        fatherPhone : '',
        fatherBank : '',
        fatherAccount : '',
        fatherKakao : '',
        motherDeceased : '',
        motherName : '',
        motherPhone : '',
        motherBank : '',
        motherAccount : '',
        motherKakao : '',
    });

    const [wedding, setWedding] = useState({
        place : '',
        date : '',
        time : '',
        detail : '',
        telNo:'',
        address:'',
        info:``,
    });

    let adminToken = sessionStorage.getItem('admin');
    if(!adminToken){
        alert('로그인해주세요!');
        window.location.href='./loginAdmin'
        return false;
    }
    let cardCategoryList = ['Pk','청첩장이동','신랑이름','신부이름','신랑측','신부측','청첩장','예식정보','식사확인', '축하메세지','삭제'];
    
    const [orderList, setOrderList] = useState([
        {
            title:'메인테마',
            order:0
        },
        {
            title:'인사말',
            order:1
        },
        {
            title:'연락하기',
            order:2
        },
        {
            title:'캘린더',
            order:3
        },
        {
            title:'갤러리',
            order:4
        },
        {
            title:'동영상',
            order:5
        },
        {
            title:'계좌정보',
            order:6
        },
        {
            title:'화환보내기',
            order:7
        },
        {
            title:'축하메세지',
            order:8
        },
        {
            title:'위치',
            order:9
        },
        {
            title:'참석여부',
            order:10
        },
    ]);

    const [attendanceList, setAttendanceList] = useState();
    const [replyList, setReplyList] = useState();
    const [chkboxgf, setGfChkbox] = useState(false);
    const [chkboxgm, setGmChkbox] = useState(false);
    const [chkboxbf, setBfChkbox] = useState(false);
    const [chkboxbm, setBmChkbox] = useState(false);
    let [audioSelect, setAudioSelect] = useState(0);
    //결과물, 1. 요청의 결과
    const [memberData, setMemberData] = useState(null)
    // API가 요청 중인지 아닌지, 2. 로딩상태
    const [loading, setLoading] = useState(false);
    //error, 3. 에러
    const [error, setError] = useState(null);
     // 한번 실행
    useEffect(()=>{
        const fetchMember = async () => {
            try {
                setMemberData(null);
                setError(null);
                setLoading(true); //로딩시작
                showMember().then((res)=>{
                    setMemberData(res.data.sort((function(a, b) {
                        return b.weddingKey - a.weddingKey;
                    })));
                })
            } catch (e) {
                setError(e); //에러가 발생한 경우
            }
            setLoading(false); //로딩이 끝났다는 것을 확인
            };
            fetchMember();
    },[])

    if (loading) return <div>로딩중...</div>;
    if (error) return <div>에러 발생</div>;
    if (!memberData) return null;

    // 청첩장 삭제
    function delCard(key){
        if(window.confirm('정말 삭제 하시겠습니까? 복구 불가능합니다.')){
            // 청첩장 삭제
            axios.delete(`https://babacard.co.kr/babacard/api/v1/wedding/${key}`)
            .then(function (res) {
                toast.success('삭제 성공!');
                setTimeout(()=>{window.location.reload()},200)
                // response  
            }).catch(function (error) {
                // 오류발생시 실행
            })
        }
    }

    const putMember = (key) => {
        let value = {
            weddingKey:key,
            ...member
        }
        axios.put(`https://babacard.co.kr/babacard/api/v1/wedding`, value, {
            headers: { 
                "Content-Type": 'application/json',
            },
        }
        )
        .then((res)=>{
            toast.success('저장성공!');
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    }

    // 데이터 임시 저장
    const handleMember = (type, value) => {
        if(type === 'bgm'){
            setAudioSelect(value);
            setMember({ ...member, bgm: value });
        }else if(type === 'theme'){
            setMember({ ...member, theme: (value)+1});
        }else if(type === 'video'){
            setMember({ ...member, video: value});
        }else if(type === 'greeting'){
            setMember({ ...member, greeting: value});
        }else if(type === 'font'){
            setMember({ ...member, font: value});
        }else if(type === 'gallery'){
            setMember({ ...member, galleryType: value});
        }else if(type === 'ani'){
            setMember({ ...member, animation: value});
        }
    };

    const handleGreeting = (e) => {
        setMember({ ...member, greeting: e.target.value});
    }

    const putCoupleInfo = (item, key, type) => {
        delete item.groom.type;
        delete item.groom.weddingPersonKey;
        delete item.groom.weddingKey;
        delete item.bride.type;
        delete item.bride.weddingPersonKey;
        delete item.bride.weddingKey;
        let value = {}
        if(type === 'groom'){
            value = {
                weddingKey: key,
                groom: groom,
                bride: item.bride,
            }
            console.log('groom')
        }else{
            value = {
                weddingKey: key,
                groom: item.groom,
                bride: bride,
            }
        }
        
        axios.put(`https://babacard.co.kr/babacard/api/v1/wedding/couple`, value, {
            headers: { 
                "Content-Type": 'application/json',
            },
        }
        )
        .then(()=>{
            toast.success('저장성공!');
            
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    }

    const settingOrder = (item) => {
        if(item.ordering){
            let orderTemp = item.ordering.split(',');
            orderList.map((order, idx) => {
                return(
                    order.order = Number(orderTemp[idx])
                )
            })
        }else{
            
        }
    }

    const setOrderUp = (item) => {
        if(item.order > 1){
            let orderIndex = orderList.findIndex(order => order.order === item.order-1)
            let findIndex = orderList.findIndex(order => order.title === item.title)

            let copiedItems = [...orderList];
            copiedItems[findIndex].order = item.order-1;
            copiedItems[orderIndex].order = item.order+1;
    
            setOrderList(copiedItems);

            let orderArray = [];
            orderList.map((item) => {
                return(
                    orderArray.push(item.order)
                )
            })

            setMember({ ...member, ordering: orderArray.join(',')});
        }else{
            toast.error('맨 첫번째 입니다.')
        }
    }

    const setOrderDown = (item) => {
        if(item.order < 10){
            let orderIndex = orderList.findIndex(order => order.order === item.order+1)
            let findIndex = orderList.findIndex(order => order.title === item.title)
    
            let copiedItems = [...orderList];
            copiedItems[findIndex].order = item.order+1;
            copiedItems[orderIndex].order = item.order-1;
    
            setOrderList(copiedItems);

            let orderArray = [];
            orderList.map((item) => {
                return(
                    orderArray.push(item.order)
                )
            })

            setMember({ ...member, ordering: orderArray.join(',')});
        }else{
            toast.error('맨 마지막 입니다.')
        }
    }

    // 고인체크
    const chk = (item) => {
        if(item.fatherDeceased === "y" ){
            setGfChkbox(true)
        }
        if(item.motherDeceased === 'y' ){
            setGmChkbox(true)
        }
        if(item.fatherDeceased === 'y' ){
            setBfChkbox(true)
        }
        if(item.motherDeceased === 'y' ){
            setBmChkbox(true)
        }
    }

    // 신랑수정
    const handleGroom = (e) => {
        if(e.target.name === 'fatherDeceased'){
            if(e.target.value === 'y'){
                setGroom({ ...groom, 'fatherDeceased': 'n' });
            }else{
                setGroom({ ...groom, 'fatherDeceased': 'y' });
            }
            setGfChkbox(!chkboxgf);
        }else if(e.target.name === 'motherDeceased'){
            if(e.target.value === 'y'){
                setGroom({ ...groom, 'motherDeceased': 'n' });
            }else{
                setGroom({ ...groom, 'motherDeceased': 'y' });
            }
            setGmChkbox(!chkboxgm);
        }else{
            setGroom({ ...groom, [e.target.name]: e.target.value });
            console.log(groom)
        }
    }

    // 신부수정
    const handleBride = (e) => {
        if(e.target.name === 'fatherDeceased'){
            if(e.target.value === 'y'){
                setBride({ ...bride, 'fatherDeceased': 'n' });
            }else{
                setBride({ ...bride, 'fatherDeceased': 'y' });
            }
            setBfChkbox(!chkboxbf);
        }else if(e.target.name === 'motherDeceased'){
            if(e.target.value === 'y'){
                setBride({ ...bride, 'motherDeceased': 'n' });
            }else{
                setBride({ ...bride, 'motherDeceased': 'y' });
            }
            setBmChkbox(!chkboxbm);
        }else{
            setBride({ ...bride, [e.target.name]: e.target.value });
            console.log(bride)
        }
    }

    // 예식장 수정 INPUT
    const handleWedding = (e) => {
        setWedding({ ...wedding, [e.target.name]: e.target.value });
    }

    const { daum } = window;

    // 다음지도
    const address = () => {
        new daum.Postcode({
            oncomplete: function(data) {
                var addr = data.address; // 최종 주소 변수
                let zipCode = data.zonecode;
                // 주소 정보를 해당 필드에 넣는다.
                document.getElementById("weddingAddress").value = addr;
                setWedding({ ...wedding, 'address': addr, 'zipCode': zipCode});
            }
        }).open();
    }

    // 예식장 수정
    const putWedding = (key) => {
        delete wedding.weddingPlaceKey;
        let value = {
            weddingKey: key,
            ...wedding
        }
        modifyPlace(value).then(()=>{
            showMember().then((res)=>{
                setMemberData(res.data);
                toast.success('저장성공!');
            })
        })
    }

    // 식사확인
    const attendance = (key) => {
        attendanceChk(key).then(res=>{
            setAttendanceList(res.data)
        })
    }

    // 축하메세지
    const reply = (key) => {
        replyChk(key).then(res=>{
            setReplyList(res.data)
        })
    }

    // 축하메세지 삭제
    const delMessage = (item, key) => {
        if(window.confirm('정말 삭제합니까? 복구가 불가합니다.')) {
            const data = {
                weddingReplyKey : parseInt(item.weddingReplyKey),
                password:'nimda',
            }
            
            replyDel(data).then(()=>{
                replyChk(key).then((res)=>{
                    setReplyList(res.data);
                    toast.success('삭제성공!');
                })
            })
        }
    }

    function KakaoPay(){
        return(
            <Fragment>
                <Popup trigger={<FontAwesomeIcon icon={faCircleQuestion}/>} position="center" modal>
                {close => (
                    <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content" style={{display:'flex'}}> 
                        <Swiper
                            modules={[Pagination, Navigation]}
                            pagination = {{ clickable: true }}
                            slidesPerView={1}
                        >
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 1. 카카오톡 앱에서 하단 ... &lt;더보기&gt;를 터치한다</p> 
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 2. 상단에 카카오 pay 버튼을 터치!</p>
                                    <img src="./img/makeCard/kakao1.jpeg" alt="" width={300}/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 3. 카카오 pay 안에서 들어가면 전체 탭을 터치!</p>
                                    <img src="./img/makeCard/kakao2.jpeg" alt="" width={300}/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 4. 전체 탭에서 내려가면 송금 탭에 송금코드 터치!</p>
                                    <img src="./img/makeCard/kakao3.jpeg" alt="" width={300}/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="kakao_pay_info">
                                    <p>Step 5. 생성된 QR코드 하단 맨 왼쪽에 링크 복사를 하면 끝!</p>
                                    <img src="./img/makeCard/kakao4.jpeg" alt="" width={260}/>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        </div>
                    </div>
                )}
                </Popup>
            </Fragment>
        )
    }


    return(
        <Fragment>
            <Header />
            <section>
                <div className="block"></div>
                <h1>관리자페이지</h1>
                <div>
                    <h2>청첩장목록</h2>
                    <Table>
                        <Thead>
                            <Tr>
                                {cardCategoryList.map((item,idx)=>{
                                    return(
                                        <Th key={idx}>{item}</Th>
                                    )
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {memberData.map((item,idx)=>{
                                return(
                                    <Tr key={idx}>
                                        <Td>{item.weddingKey}</Td>
                                        <Td><a href={`https://babacard.co.kr/card?no=${item.weddingKey}`}><button className="btn adminBtn">이동</button></a></Td>
                                        <Td>{item.groom && item.groom.name}</Td>
                                        <Td>{item.bride && item.bride.name}</Td>
                                        {/* 신랑 수정 */}
                                        <Td>
                                        <Popup 
                                        onOpen={()=>{setGroom({...item.groom}); chk(item.groom); console.log(groom)}}
                                        trigger={<button className="btn adminBtn">수정</button>} position="center" modal nested>
                                        {close => (
                                            <div className="modal">
                                                <button className="close" onClick={close}>&times;</button>
                                                <div className="header">신랑측</div>
                                                <div className="content"> 
                                                <Swiper
                                                    modules={{Navigation}}
                                                    slidesPerView={1}
                                                    navigation={true}
                                                >
                                                    <SwiperSlide className="editPopup">
                                                    <h4>신랑</h4>
                                                    <ul>
                                                        <li>
                                                            <label htmlFor="groomName">이름</label>
                                                            <input type="text" name="name" id="groomName" onChange={handleGroom} defaultValue={item.groom && item.groom.name}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomTel">연락처</label>
                                                            <input type="text" name="phone" id="groomTel" placeholder="-까지 전부 입력해주세요" onChange={handleGroom} defaultValue={item.groom && item.groom.phone}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomNickname">호칭</label>
                                                            <input type="text" name="nickname" id="groomNickname" onChange={handleGroom} placeholder="예) 장남 / 차남" defaultValue={item.groom && item.groom.nickname}/>
                                                        </li>
                                                        <li className="makeCard_bankArea">
                                                            <label htmlFor="groomBank">계좌정보</label>
                                                            <select name="bank" id="groomBank" onChange={handleGroom} defaultValue={item.groom && item.groom.bank}>
                                                                <option value="">은행선택</option>
                                                                {bankList.map((item, idx)=>{
                                                                    return(
                                                                        <option defaultValue={item} key={idx}>{item}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <input type="text" name="account" id="groomAccount"  placeholder="-까지 전부 입력해주세요" onChange={handleGroom} className="account_input" defaultValue={item.groom && item.groom.account}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="groomKakao">카카오페이 링크</label><KakaoPay />
                                                            <input type="text" name="kakao" id="groomKakao" onChange={handleGroom} defaultValue={item.groom && item.groom.kakao}/>
                                                        </li>
                                                    </ul>
                                                    </SwiperSlide>
                                                    <SwiperSlide  className="editPopup">
                                                        <h4>신랑 아버지</h4>
                                                        <ul>
                                                            <li>
                                                                <label htmlFor="groomFatherDeceased">고인여부</label>
                                                                <input type="checkbox" name="fatherDeceased" id="groomFatherDeceased" onChange={handleGroom} value={item.groom && item.groom.fatherDeceased} checked={chkboxgf}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="groomFatherName">이름</label>
                                                                <input type="text" name="fatherName" id="groomFatherName" onChange={handleGroom} defaultValue={item.groom && item.groom.fatherName}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="groomFatherPhone">연락처</label>
                                                                <input type="text" name="fatherPhone" id="groomFatherPhone" onChange={handleGroom} disabled={(chkboxgf)?true:false} defaultValue={item.groom && item.groom.fatherPhone}/>
                                                            </li>
                                                            <li className="makeCard_bankArea">
                                                                <label htmlFor="groomFatherBank">계좌정보 </label>
                                                                <select name="fatherBank" id="groomFatherBank" onChange={handleGroom} defaultValue={item.groom && item.groom.fatherBank} disabled={(chkboxgf)?true:false}>
                                                                    <option value="">은행선택</option>
                                                                    {bankList.map((item, idx)=>{
                                                                        return(
                                                                            <option value={item} key={idx}>{item}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                <input type="text" name="fatherAccount" id="groomFatherAccount" placeholder="-까지 전부 입력해주세요" onChange={handleGroom} defaultValue={item.groom && item.groom.fatherAccount} disabled={(chkboxgf)?true:false}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="groomFatherKakao">카카오페이</label><KakaoPay />
                                                                <input type="text" name="fatherKakao" id="groomFatherKakao" onChange={handleGroom} disabled={(chkboxgf)?true:false} defaultValue={item.groom && item.groom.fatherKakao}/>
                                                            </li>
                                                        </ul>
                                                    </SwiperSlide>
                                                    <SwiperSlide  className="editPopup">
                                                        <h4>신랑 어머니</h4>
                                                        <ul>
                                                        <li>
                                                                <label htmlFor="groomMotherDeceased">고인여부</label>
                                                                <input type="checkbox" name="motherDeceased" id="groomMotherDeceased" onChange={handleGroom} value={item.groom && item.groom.motherDeceased} checked={chkboxgm}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="groomMotherName">이름</label>
                                                                <input type="text" name="motherName" id="groomMotherName" onChange={handleGroom} defaultValue={item.groom && item.groom.motherName}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="groomMotherPhone">연락처</label>
                                                                <input type="text" name="motherPhone" id="groomMotherPhone" onChange={handleGroom} disabled={(chkboxgm)?true:false} defaultValue={item.groom && item.groom.motherPhone}/>
                                                            </li>
                                                            <li className="makeCard_bankArea">
                                                                <label htmlFor="groomMotherBank">은행 </label>
                                                                <select name="motherBank" id="groomMotherBank" onChange={handleGroom} defaultValue={item.groom && item.groom.motherBank} disabled={(chkboxgm)?true:false}>
                                                                    <option value="">---은행선택---</option>
                                                                    {bankList.map((item, idx)=>{
                                                                        return(
                                                                            <option value={item} key={idx}>{item}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="groomMotherAccount">계좌</label>
                                                                <input type="text" name="motherAccount" id="groomMotherAccount" placeholder="- 까지 전부 입력해주세요" onChange={handleGroom} disabled={(chkboxgm)?true:false} defaultValue={item.groom && item.groom.motherAccount}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="groomMotherKakao">카카오페이</label><KakaoPay />
                                                                <input type="text" name="motherKakao" id="groomMotherKakao" onChange={handleGroom} disabled={(chkboxgm)?true:false} defaultValue={item.groom && item.groom.motherKakao}/>
                                                            </li>
                                                        </ul>
                                                    </SwiperSlide>
                                                </Swiper>
                                                </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putCoupleInfo(item, item.weddingKey, 'groom');
                                                close();
                                            }}>수정</button></div>
                                        </div>
                                        )}
                                        </Popup>
                                        </Td>

                                        {/* 신부 수정 */}
                                        <Td>
                                        <Popup 
                                        onOpen={()=>{setBride({...item.bride}); chk(item.bride); console.log(bride)}}
                                        trigger={<button className="btn adminBtn">수정</button>} position="center" modal nested>
                                        {close => (
                                            <div className="modal">
                                                <button className="close" onClick={close}>&times;</button>
                                                <div className="header">신부측</div>
                                                <div className="content"> 
                                                <Swiper
                                                    modules={{Navigation}}
                                                    slidesPerView={1}
                                                    navigation={true}
                                                >
                                                    <SwiperSlide className="editPopup">
                                                    <h4>신부</h4>
                                                    <ul>
                                                        <li>
                                                            <label htmlFor="brideName">이름</label>
                                                            <input type="text" name="name" id="brideName" onChange={handleBride} defaultValue={item.bride && item.bride.name}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideTel">연락처</label>
                                                            <input type="text" name="phone" id="brideTel" placeholder="-까지 전부 입력해주세요" onChange={handleBride} defaultValue={item.bride && item.bride.phone}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideNickname">호칭</label>
                                                            <input type="text" name="nickname" id="brideNickname" onChange={handleBride} placeholder="예) 장녀 / 차녀" defaultValue={item.bride && item.bride.nickname}/>
                                                        </li>
                                                        <li className="makeCard_bankArea">
                                                            <label htmlFor="brideBank">계좌정보</label>
                                                            <select name="bank" id="brideBank" onChange={handleBride} defaultValue={item.bride && item.bride.bank}>
                                                                <option value="">은행선택</option>
                                                                {bankList.map((item, idx)=>{
                                                                    return(
                                                                        <option defaultValue={item} key={idx}>{item}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <input type="text" name="account" id="brideAccount"  placeholder="-까지 전부 입력해주세요" onChange={handleBride} className="account_input" defaultValue={item.bride && item.bride.account}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="brideKakao">카카오페이 링크</label><KakaoPay />
                                                            <input type="text" name="kakao" id="brideKakao" onChange={handleBride} defaultValue={item.bride && item.bride.kakao}/>
                                                        </li>
                                                    </ul>
                                                    </SwiperSlide>
                                                    <SwiperSlide  className="editPopup">
                                                        <h4>신부 아버지</h4>
                                                        <ul>
                                                            <li>
                                                                <label htmlFor="brideFatherDeceased">고인여부</label>
                                                                <input type="checkbox" name="fatherDeceased" id="brideFatherDeceased" onChange={handleBride} value={item.bride && item.bride.fatherDeceased} checked={chkboxbf}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="brideFatherName">이름</label>
                                                                <input type="text" name="fatherName" id="brideFatherName" onChange={handleBride} defaultValue={item.bride && item.bride.fatherName}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="brideFatherPhone">연락처</label>
                                                                <input type="text" name="fatherPhone" id="brideFatherPhone" onChange={handleBride} disabled={(chkboxbf)?true:false} defaultValue={item.bride && item.bride.fatherPhone}/>
                                                            </li>
                                                            <li className="makeCard_bankArea">
                                                                <label htmlFor="brideFatherBank">계좌정보 </label>
                                                                <select name="fatherBank" id="brideFatherBank" onChange={handleBride} defaultValue={item.bride && item.bride.fatherBank} disabled={(chkboxbf)?true:false}>
                                                                    <option value="">은행선택</option>
                                                                    {bankList.map((item, idx)=>{
                                                                        return(
                                                                            <option value={item} key={idx}>{item}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                <input type="text" name="fatherAccount" id="brideFatherAccount" placeholder="-까지 전부 입력해주세요" onChange={handleBride} defaultValue={item.bride && item.bride.fatherAccount} disabled={(chkboxbf)?true:false}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="brideFatherKakao">카카오페이</label><KakaoPay />
                                                                <input type="text" name="fatherKakao" id="brideFatherKakao" onChange={handleBride} disabled={(chkboxbf)?true:false} defaultValue={item.bride && item.bride.fatherKakao}/>
                                                            </li>
                                                        </ul>
                                                    </SwiperSlide>
                                                    <SwiperSlide  className="editPopup">
                                                        <h4>신부 어머니</h4>
                                                        <ul>
                                                        <li>
                                                                <label htmlFor="brideMotherDeceased">고인여부</label>
                                                                <input type="checkbox" name="motherDeceased" id="brideMotherDeceased" onChange={handleBride} value={item.bride && item.bride.motherDeceased} checked={chkboxbm}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="brideMotherName">이름</label>
                                                                <input type="text" name="motherName" id="brideMotherName" onChange={handleBride} defaultValue={item.bride && item.bride.motherName}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="brideMotherPhone">연락처</label>
                                                                <input type="text" name="motherPhone" id="brideMotherPhone" onChange={handleBride} disabled={(chkboxbm)?true:false} defaultValue={item.bride && item.bride.motherPhone}/>
                                                            </li>
                                                            <li className="makeCard_bankArea">
                                                                <label htmlFor="brideMotherBank">은행 </label>
                                                                <select name="motherBank" id="brideMotherBank" onChange={handleBride} defaultValue={item.bride && item.bride.motherBank} disabled={(chkboxbm)?true:false}>
                                                                    <option value="">---은행선택---</option>
                                                                    {bankList.map((item, idx)=>{
                                                                        return(
                                                                            <option value={item} key={idx}>{item}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="brideMotherAccount">계좌</label>
                                                                <input type="text" name="motherAccount" id="brideMotherAccount" placeholder="- 까지 전부 입력해주세요" onChange={handleBride} disabled={(chkboxbm)?true:false} defaultValue={item.bride && item.bride.motherAccount}/>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="brideMotherKakao">카카오페이</label><KakaoPay />
                                                                <input type="text" name="motherKakao" id="brideMotherKakao" onChange={handleBride} disabled={(chkboxbm)?true:false} defaultValue={item.bride && item.bride.motherKakao}/>
                                                            </li>
                                                        </ul>
                                                    </SwiperSlide>
                                                </Swiper>
                                                </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putCoupleInfo(item, item.weddingKey, 'bride');
                                                close();
                                            }}>수정</button></div>
                                        </div>
                                        )}
                                        </Popup>
                                        </Td>
                                        
                                        {/* 청첩장 수정 */}
                                        <Td>
                                        <Popup 
                                        onOpen={()=>{setMember({...item, 'bgm':item.bgm}); settingOrder(item); }}
                                        trigger={<button className="btn adminBtn">수정</button>} position="center" modal nested>
                                        {close => (
                                            <div className="modal">
                                                <button className="close" onClick={close}>&times;</button>
                                                <div className="header">청첩장 수정</div>
                                                <div className="content"> 
                                                <Swiper
                                                    modules={{Navigation}}
                                                    slidesPerView={1}
                                                    navigation={true}
                                                >
                                                <SwiperSlide className="editPopup">
                                                <h4>테마</h4>
                                                <Select
                                                name="theme"
                                                    modalCloseButton={<ModalCloseButton />}
                                                    options={
                                                        themeList.map((item, idx)=>{
                                                            return(
                                                                {value: idx, text: item}
                                                            )
                                                        })
                                                    }
                                                    caretIcon={<CaretIcon />}
                                                    selectedValue={parseInt(member.theme)-1}
                                                    onChange={newValue => {handleMember('theme', newValue.value)}}
                                                />
                                                <img src={`./img/makeCard/theme/theme${(member.theme)}.jpg`} alt="" width={'60%'} style={{display: 'block',margin: '10px auto', maxWidth: '250px', borderRadius:10, boxShadow:'2px 2px 7px #ccc'}}/>
                                                </SwiperSlide>
                                                <SwiperSlide className="editPopup">
                                                <h4>효과</h4>
                                                </SwiperSlide>
                                                <SwiperSlide className="editPopup">
                                                <h4>인사말수정</h4>
                                                <div className="content greeting"> 
                                                    <Select
                                                        name="greeting"
                                                        modalCloseButton={<ModalCloseButton />}
                                                        options={
                                                            greetingList.map((item, idx)=>{
                                                                return(
                                                                    {value: (item.content === '직접입력')?member.greeting:item.content,
                                                                    text: item.content}
                                                                )
                                                            })
                                                        }
                                                        caretIcon={<CaretIcon />}
                                                        selectedValue={member.greeting}
                                                        onChange={newValue => handleMember('greeting', newValue.value)}
                                                    />
                                                    <textarea name="" id="" cols="30" rows="10" value={member.greeting} onChange={handleGreeting} style={{textAlign: 'center',
                                                    lineHeight: '25px',
                                                    fontSize: '16px',
                                                    marginTop: '10px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '10px'}}></textarea>
                                                </div>
                                                </SwiperSlide>
                                                <SwiperSlide className="editPopup">
                                                <h4>갤러리타입</h4>
                                                <div className="makeCard cardGallery modify">
                                                    <div className="cardContent">
                                                        <div className="gallery_type_area">
                                                            <div>
                                                                슬라이드 타입
                                                                <div className="gallery_type_swipe"></div>
                                                                <button className={(member.galleryType === 'swipe')?'btn':'btn btn_reverse'} onClick={()=> handleMember('gallery','swipe')}>{(member.galleryType === 'swipe')?'선택됨':'선택하기'}</button>
                                                            </div>
                                                            <div>
                                                                바둑판 타입
                                                                <div className="gallery_type_cell">
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <button className={(member.galleryType === 'cell')?'btn':'btn btn_reverse'} onClick={()=> handleMember('gallery','cell')}>{(member.galleryType === 'cell')?'선택됨':'선택하기'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </SwiperSlide>
                                                <SwiperSlide className="editPopup">
                                                <h4>대표사진</h4>
                                                </SwiperSlide>
                                                <SwiperSlide className="editPopup">
                                                <h4>배경음악</h4>
                                                <Select
                                                    name="bgm"
                                                    modalCloseButton={<ModalCloseButton />}
                                                    options={[
                                                        { value: '0', text: 'BGM을 선택해주세요.' },
                                                        { value: '1', text: '분위기 있는 재즈바' },
                                                        { value: '2', text: '빛이 들어오는 바닷가' },
                                                        { value: '3', text: '비 오는 잔잔한 아침' },
                                                        { value: '4', text: '사랑스럽게 고백하는 날' },
                                                        { value: '5', text: '창밖을 보며 티타임' },
                                                        { value: '6', text: '감성적인 새벽녁 피아노' },
                                                        { value: '7', text: '기분좋은 날 함께해요' },
                                                    ]}
                                                    caretIcon={<CaretIcon />}
                                                    prefix=""
                                                    selectedValue={item.bgm}
                                                    onChange={newValue => handleMember('bgm', newValue.value)}
                                                />
                                                {item.bgm &&
                                                audioSelect &&
                                                audioSelect !== '0' &&
                                                <Player music={audioSelect}/>}
                                                
                                                </SwiperSlide>
                                                <SwiperSlide className="editPopup">
                                                <h4>폰트</h4>
                                                <Select
                                                    name="font"
                                                    modalCloseButton={<ModalCloseButton />}
                                                    options={
                                                        fontList.map((item)=>{
                                                            return(
                                                                {
                                                                    value: item.title_en, 
                                                                    text: item.title_ko, 
                                                                    markup: <span style={{fontFamily:item.title_en}}>{item.title_ko}</span>
                                                                }
                                                            )
                                                        })
                                                    }
                                                    caretIcon={<CaretIcon />}
                                                    selectedValue={member.font}
                                                    onChange={newValue => handleMember('font', newValue.value)}
                                                />
                                                <div style={{fontFamily:member.font, fontSize:20, textAlign:'center',margin:'20px 0', lineHeight:'30px'}}>
                                                    오랜 기다림 속에서 저희 두 사람<br />
                                                    하나 되어 사랑의 결실을 맺게 되었습니다.<br />
                                                    참석하셔서 축복해 주시면 큰 기쁨이겠습니다.
                                                </div>
                                                </SwiperSlide>
                                                <SwiperSlide className="editPopup">
                                                <h4>순서변경</h4>
                                                <section className="makeCard cardOrder" style={{width:'100%',minHeight:'0'}}>
                                                    <ul>
                                                        {orderList.map((item, idx)=>{
                                                            if(idx === 0){
                                                                return(
                                                                    <li key={idx} style={{order:item.order, margin:'5px 0', height:35}}>{item.title}</li> 
                                                                )
                                                            }else{
                                                                return(
                                                                    <li key={idx} style={{order:item.order, margin:'5px 0', height:35}}>
                                                                        <div onClick={()=>{setOrderUp(item)}} className="order_up">
                                                                            <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>
                                                                        </div>
                                                                        <div className="order_title">
                                                                            {item.title}
                                                                        </div>
                                                                        <div onClick={()=>{setOrderDown(item)}} className="order_down">
                                                                            <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                                                                        </div>
                                                                        
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </ul>
                                                </section>
                                                </SwiperSlide>
                                                </Swiper>
                                                </div>
                                            <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                putMember(item.weddingKey);
                                                close();
                                            }}>수정</button></div>
                                        </div>
                                        )}
                                        </Popup>
                                        </Td>

                                        {/* 예식장 수정 */}
                                        <Td>
                                        <Popup trigger={<button className="btn adminBtn">수정</button>} position="center" modal nested closeOnDocumentClick={false} 
                                        onOpen={()=>{setWedding({...item.place});}}>
                                        {close => (
                                            <div className="modal">
                                                <button className="close" onClick={close}>&times;</button>
                                                <div className="content"> 
                                                <div className="editPopup">
                                                    <ul>
                                                        <li>
                                                            <label htmlFor="weddingDate">날짜 <small>[필수]</small></label>
                                                            <input type="date" name="date" id="weddingDate" onChange={handleWedding} value={wedding.date || ''}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="weddingTime">시간 <small>[필수]</small></label>
                                                            <input type="time" name="time" id="weddingTime" onChange={handleWedding} step="900" value={wedding.time  || ''}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="weddingPlace">예식장명 <small>[필수]</small></label>
                                                            <input type="text" name="place" id="weddingPlace" placeholder="예식장명" onChange={handleWedding} value={wedding.place  || ''}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="weddingDetail">층/홀/실</label>
                                                            <input type="text" name="detail" id="weddingDetail" placeholder="3층 / 그랜드홀" onChange={handleWedding} value={wedding.detail  || ''} />
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li></li>
                                                        <li>
                                                            <label htmlFor="weddingAddress">주소 <small>[필수]</small></label>
                                                            <input type="text" id="weddingAddress" placeholder="주소" readOnly onClick={address} value={wedding.address  || ''}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="weddingTel">예식장 연락처</label>
                                                            <input type="tel" name="telNo" id="weddingTel" placeholder="" onChange={handleWedding} value={wedding.telNo  || ''}/>
                                                        </li>
                                                        <li>
                                                            <label htmlFor="weddingInfo">안내사항</label>
                                                            <textarea name="info" id="weddingInfo" onChange={handleWedding} style={{height:30, border:'1px solid #ccc'}} value={wedding.info || ''}/>
                                                        </li>
                                                    </ul>
                                                </div>
                                                
                                                </div>
                                                <div className="footer"><button className="btn editBtn" onClick={()=>{
                                                    if(!wedding.date){
                                                        toast.error('예식날짜를 넣어주세요!');
                                                    }else if(!wedding.time){
                                                        toast.error('예식시간을 넣어주세요!');
                                                    }else if(!wedding.place){
                                                        toast.error('예식장소를 넣어주세요!');
                                                    }else if(!wedding.address){
                                                        toast.error('예식장 주소를 넣어주세요!');
                                                    }else{
                                                        putWedding(item.weddingKey);
                                                        close();
                                                    }
                                                }
                                                }>수정</button></div>
                                            </div>
                                        )}
                                    </Popup>
                                        </Td>

                                        {/* 식사확인 */}
                                        <Td>
                                            <Popup trigger={<button className="btn adminBtn">수정</button>} position="center" modal nested onOpen={()=>{attendance(item.weddingKey)}}>
                                                    {close => (
                                                        <div className="modal">
                                                            <button className="close" onClick={close}>&times;</button>
                                                            <div className="content"> 
                                                            <ul className="attendance_list">
                                                                <li>
                                                                    <div>이름</div>
                                                                    <div>휴대전화</div>
                                                                    <div>참석여부</div>
                                                                    <div>식사여부</div>
                                                                </li>
                                                                <div style={{textAlign:'center'}}>{(attendanceList && attendanceList.length === 0)?'참석여부 정보가 없습니다.':''}</div>
                                                                {attendanceList && attendanceList.map((item,idx)=>{
                                                                    return(
                                                                        <li key={idx}>
                                                                            <div>{item.name}</div>
                                                                            <div>{item.phone}</div>
                                                                            <div>{(item.attended === 't')?'참석':'불참석'}</div>
                                                                            <div>{item.meals}명</div>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                            </div>
                                                        </div>
                                                    )}
                                            </Popup>
                                        </Td>

                                        {/* 참석메세지 */}
                                        <Td>
                                            <Popup trigger={<button className="btn adminBtn">수정</button>} position="center" modal nested onOpen={()=>{reply(item.weddingKey)}}>
                                                    {close => (
                                                    <div className="modal">
                                                        <button className="close" onClick={close}>&times;</button>
                                                        <div className="content"> 
                                                        <ul className="reply_list">
                                                            <li>
                                                                <div>이름</div>
                                                                <div>메세지</div>
                                                                <div>삭제</div>
                                                            </li>
                                                            <div style={{textAlign:'center'}}>{(replyList && replyList.length === 0)?'축하메세지 정보가 없습니다.':''}</div>
                                                            {replyList && replyList.map((items,idx)=>{
                                                                return(
                                                                    <li key={idx}>
                                                                        <div>{items.name}</div>
                                                                        <div>{items.content}</div>
                                                                        <div onClick={()=>delMessage(items, item.weddingKey)}>x</div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                        </div>
                                                    </div>
                                                    )}
                                            </Popup>
                                        </Td>

                                        {/* 삭제 */}
                                        <Td onClick={()=>delCard(item.weddingKey)}>X</Td>
                                    </Tr>
                                )
                            })}
                            
                        </Tbody>
                    </Table>
                </div>
            </section>
            <Footer />
            <Toaster 
                    position="bottom-center"
                    toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                        background: '#363636',
                        color: '#fff',
                        },
                    
                        // Default options for specific types
                        success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                        },
                    }}
                />
        </Fragment>
    )
}