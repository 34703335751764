export const REDIRECT_URL = 'https://babacard.co.kr';
// export const REDIRECT_URL = 'http://localhost:3000';
export const REST_API_KEY = '0df34970d05be3eabe6e683e4ee6d3c7';
export const ACCESS_KEY = 'e4c148790c6d030b787ee8ca638efad3';
export const kakaoID = localStorage.getItem('kakaoID');
// 아이폰
// export const kakaoID = '2599728003';
// 갤럭시
// export const kakaoID = '2583914321';
export const DISCORD_WEBHOOK = 'https://discord.com/api/webhooks/1060402276461723781/yVFGyInjTsw9h53Agan8vafv1pfEHGp_4XPRRGvoUVj7wQuZke9ZDbX469Q3YHmwlQzj';
export const DISCORD_WEBHOOK2 = 'https://discord.com/api/webhooks/1137900414373736468/-USUSrSdZF3_1O5YVbgkcmeOPvyiDlBn9se1hmf1BMvQCnNef6an3UdHnHfn2QAMvDQR';