import React from 'react';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Footer(){
    return(
        <footer>
            <div className='logo_box' onClick={()=>{window.location.href='./'}}></div>
            <div>
                <address>(주)바바컴퍼니 서울특별시 강남구 논현로 824, B1 (동양빌딩)</address>
            </div>
            <div>
                <dl className="footer_info">
                    <dt>FAX :</dt>
                    <dd>02-557-1060</dd>
                    <dt>사업자등록번호 :</dt>
                    <dd>783-81-01419</dd>
                    <dt>개인정보 관리책임자 :</dt>
                    <dd>바바컴퍼니</dd>
                </dl>
            </div>
            <div>
                <small style={{textAlign:'center',display:'block'}}>&copy; COPYRIGHT 2017 (주)바바컴퍼니<br className='mobile_br'/>BABACOMPANY ALL RIGHTS RESERVED.</small>
            </div>
            <div className='backtotop' onClick={()=>{window.scrollTo({top:0, left:0, behavior:'smooth'});}}>
                <FontAwesomeIcon icon={faCaretUp} />
                <p>top</p>
            </div>
        </footer>
    )

}


export default Footer;