import axios from 'axios';

const BASIC_URL = 'https://babacard.co.kr/babacard/api/v1/wedding/';
const SETTING_URL = 'https://babacard.co.kr/babacard/api/v1/setting'
export const DISCORD_URL = 'https://discord.com/api/webhooks/1135925248114565141/3c2szBgVM87uP4JG6O2qfmXaKgeJyvdAsgS9aooJqX1-LYlXPO3gDiR8qNKyPQ2_IKqe'
const header = { 
    "Content-Type": 'application/json',
}

// 어드민 전체 맴버 데이터 가져오기
export const showMember = async()=> {
    const url = BASIC_URL
    return await axios.get(url, {params:{memberId:'baba_admin'}});
}

// 예식정보 수정
export const modifyPlace = async(value) => {
    const url = `${BASIC_URL}place`
    return await axios.put(url, value, {headers: header});
}

// 식사확인
export const attendanceChk = async(no) => {
    const url = `${BASIC_URL}${no}/attendance`
    return await axios.get(url, {params:{weddingKey:no}});
}

// 메세지확인
export const replyChk = async(no) => {
    const url = `${BASIC_URL}${no}/reply`
    return await axios.get(url, {params:{weddingKey:no}});
}

//메세지 삭제
export const replyDel = async(data) => {
    const url = `${BASIC_URL}reply`
    return await axios.delete(url, {data:data, headers: header});
}

// 세팅 생성
export const postSetting = async(value) => {
    const url = `${SETTING_URL}`
    return await axios.post(url, value, {headers: header});
}

// 세팅 조회
export const getSetting = async(key) => {
    const url = `${SETTING_URL}`
    return await axios.get(url, {params:{memberKey:key}});
}