import React, { Fragment, useState, useEffect } from "react";
import Calendar from 'react-calendar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faCircleNotch, faMessage, faSquarePhone } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Popup from 'reactjs-popup';
import toast, { Toaster } from 'react-hot-toast';
import CardFooter from './cardFooter';
import {Map, MapMarker} from 'react-kakao-maps-sdk';
import './data/data';
import axios from 'axios';
import Player from './utils/player'
import { shareKakao } from './shareKakaoLink'

import 'react-calendar/dist/Calendar.css';
import 'reactjs-popup/dist/index.css';
import Gallery from "./utils/gallery";
import {MemoEffect} from "./utils/effect";
import Effect2 from "./utils/effect2";
import { getSetting, DISCORD_URL } from "./data/api";
import { DISCORD_WEBHOOK2 } from "./data/snsLogin";

export default function Card(){
    // no 값 가져오기
    const urlParams = new URL(window.location.href).searchParams;
    const no = urlParams.get('no');
    const { kakao } = window;
    const [mapState, setMapState] = useState({
        // 지도의 초기 위치
        center: { lat: 37.49676871972202, lng: 127.02474726969814 },
    });
    //결과물, 1. 요청의 결과
    const [member, setMember] = useState(null);
    // 축하메세지 리스트
    const [replyList, setReplyList] = useState([]);
    // API가 요청 중인지 아닌지, 2. 로딩상태
    const [loading, setLoading] = useState(false);
    //error, 3. 에러
    const [error, setError] = useState(null);
    // 화환 스테이트
    const [flower, setFlower] = useState();
    // 화환 경조사
    const [celebrate, SetCelebrate] = useState(null);
    // 은행계좌 스테이트
    const [accountBtn, setAccountBtn] = useState('');
    // 참석여부 동의 버튼
    const [agreeBtn, setAgreeBtn] = useState(false);
    const [agreeChk, setAgreeChk] = useState(false);
    // 식사여부 동의 버튼
    const [mealChk, setMealChk] = useState(false);
    // 축하메세지 입력 스테이트
    const [reply, setReply] = useState({
        replyWriter:'',
        replyPw:'',
        replyContent:'',
    });
    // 축하메세지 삭제 페스워드
    const [replyDelPw, setReplyDelPw] = useState(null)
    // 참석여부 정보 스테이트
    const [attendance, setAttendance] = useState({
        attendance_name:'',
        attendance_phone:'',
        attendance_attended:'',
        attendance_meals:'',
    });
    // 포로리스트
    const [photoKeyList, setPhotoKeyList] = useState([]);
    // 대표사진 가져오기
    const [thumb, setThumb] = useState(0);
    // 순서
    const [ordering, SetOrdering] = useState([]);
    // 초기화 비구조화
    const { replyWriter, replyPw, replyContent } = reply;
    const { attendance_name, attendance_phone, attendance_attended, attendance_meals} = attendance;
    // 추가기능 변수
    const [setting, setSetting] = useState({
        zoom:'',
        fontSize:'',
        orderCouple:'',
        hideGreeting:'',
        hideCalendar:'',
        hideMessage:'',
        hideAttend:'',
        hideShare:'',
    });
    // 폰트크기 조절
    const [fontSize, setFontSize] = useState(window.Indata.fontSizeM);
    // 포토 커버
    const [coverArr, setCoverArr] = useState([]);
    // 전송버튼시 input 초기화
    const onReset = (type) => {
        switch(type){
            case 'reply':
                setReply({
                    replyWriter: "",
                    replyPw: "",
                    replyContent: "",
                });
            break;

            case 'attendance':
                setAttendance({
                    attendance_name:'',
                    attendance_phone:'',
                    attendance_attended:'',
                    attendance_meals:'',
                    agree:'',
                });
                setAgreeBtn(false);
                setAgreeChk(false);
                setMealChk(false);
            break;

            default:
            break;
        }
    };    
    
    const handleInputChange = (type, e) => {
        if(type === 'replyDelPw'){
            setReplyDelPw(e.target.value);
        }else if(type === 'reply'){
            setReply({ ...reply, [e.target.name]: e.target.value });
        }else if(type === 'attendance'){
            setAttendance({ ...attendance, [e.target.name]: e.target.value });
        }
    };

    // axios
    useEffect(() => {
        const fetchData = async () => {
        try {
            setMember(null);
            setError(null);
            setLoading(true); //로딩시작
            const memberRes = await axios.get(
            `https://babacard.co.kr/babacard/api/v1/wedding/${no}`
            );

            console.log('memberRes >>>', memberRes.data)
            if(!memberRes.data.place.zipCode){
                setMember({...memberRes.data, 'zipCode':0 });
            }else{
                setMember(memberRes.data); //데이터 받아오고 setUser에 담기
            }
            SetOrdering(memberRes.data.ordering.split(','));
            memberRes.data.photoList.map((item) => {
                return(
                    setPhotoKeyList(oldArray => [...oldArray, item.weddingPhotoKey])
                )
            })
            let thumbNum = memberRes.data.photoList.findIndex(e => e.isThumbnail === 'Y');
            if(thumbNum === -1){
                setThumb(0);
            }else{
                setThumb(thumbNum);
            }
            setReplyList(null);
            const replyRes = await axios.get(
                `https://babacard.co.kr/babacard/api/v1/wedding/${no}/reply`, {
                params: { 
                    weddingKey : no,
                }
            })
            if(replyRes.data){
                setReplyList(replyRes.data);
            }else{
                setReplyList([{}]);
            }
            // 세팅 불러오기
            getSetting(memberRes.data.memberKey).then((res)=>{
                console.log('add setting', res.data)
                if(res.data.length > 0){
                    let newSetting = { ...setting };
                    res.data.map((item)=>{           
                        return(
                            newSetting[item.settingName] = item.settingValue
                        )
                    })
                    if(newSetting.zoom === 'on'){
                        document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=5.0')
                    }
                    // 순서 선택안하면 기본 신랑
                    if(newSetting.orderCouple === ''){
                        newSetting.orderCouple = 'orderGroom';
                    }
                    // 폰트 사이즈
                    if(newSetting.fontSize === 'fonts'){
                        setFontSize(window.Indata.fontSizeS);
                    }
                    if(newSetting.fontSize === 'fontl'){
                        setFontSize(window.Indata.fontSizeL);
                    }
                    if(newSetting.photoCover){
                        const arr = newSetting.photoCover.split(',');
                        setCoverArr(arr);
                    }
                    setSetting(newSetting);
                }
            })
            // 주소를 좌표로
            const geocoder = new kakao.maps.services.Geocoder();
            let callback = function(result, status) {
            if (status === kakao.maps.services.Status.OK) {
                const newSearch = result[0]
                setMapState({
                center: { lat: newSearch.y, lng: newSearch.x }
                })
            }
            };
            geocoder.addressSearch(`${memberRes.data.place.address}`, callback);

        } catch (e) {
            console.log(e)
            setError(e); //에러가 발생한 경우
        }
        setLoading(false); //로딩이 끝났다는 것을 확인
        };

        fetchData();
        const script = document.createElement("script");
        script.src = "https://developers.kakao.com/sdk/js/kakao.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    if (loading) return <div>로딩중...</div>;
    if (error) return goMain();
    if (!member) return '에러2';

    function goMain(){
        alert('존재하지 않는 청첩장입니다!');
        window.location.href='./'
    }
    // funtion
    // 클립보드 복사
    const handleCopyClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success('복사 성공');
        } catch (e) {
            toast('URL 복사 실패');
        }
    };

    // 디데이 계산
    function dDay(weddingDay){
        let dday = new Date(weddingDay).getTime();
        let msg = ''
        let today = new Date().getTime();
        let gap = dday - today;
        let day = Math.ceil(gap / (1000 * 60 * 60 * 24));

        console.log('day >>>>>>>', day)
        if(day > 0){
            msg = "D-DAY " + day + "일 남았어요!";
        }else if(day < 0){
            msg = "D-DAY " + day + "일 예식이 지났어요!";
        }else{
            msg = "D-DAY " + day + "일 당일이에요!";
        }
        return(msg);
    }

    // 현재 날짜 비교
    const checkDate = (weddingDay) => {
        let dday = new Date(weddingDay).getTime();
        let today = new Date().getTime();
        let gap = dday - today;
        let day = Math.ceil(gap / (1000 * 60 * 60 * 24));

        if(day === -0 || day === 0){
            return true;
        }else if(day < 0){
            return false;
        }else{
            return true;
        }
    } 

    // 디스코드
    const discord = async () => {
        const msg = {
            "content": `화환보내기 클릭! \n ${flower.name} \n 신랑 : ${member.groom.name}  \n 신부 : ${member.bride.name} \n URL : https://babacard.co.kr/card?no=${no}
            `,
        }
        await fetch(DISCORD_WEBHOOK2, {
            "method":"POST",
            "headers" : {
                "content-type": "application/json"
            },
            "body": JSON.stringify(msg)
        }).then( async ()=>{
            await fetch(DISCORD_URL, {
                "method":"POST",
                "headers" : {
                    "content-type": "application/json"
                },
                "body": JSON.stringify(msg)
        }).then(res => {
            console.log(res);
            window.location.href = `https://18112018.mo.im/category/A1?receiver_name=${flower.name || ''}&receiver_hp=${flower.phone || ''}&&receiver_num=${member.place.telNo || ''}&receiver_addr=${encodeURI(encodeURIComponent(member.place.address || ''))}&receiver_zipcode=${member.place.zipCode || ''}&delivery_date=${member.place.date || ''}&delivery_time=${flowerTime(member.place.time) || ''}%EC%8B%9C%EA%B2%BD&ribbon_right=${celebrate ? encodeURI(encodeURIComponent(celebrate)): ''}`
        })
        })
        
    }
    // 오전 오후
    const setAmPm = (time) => {
        let hour = time.substring(0,2);
        if(parseInt(hour) > 12){
            let num = parseInt(hour) - 12;
            time = time.replace(hour,num);
            if(num >= 10){
                
            }else{
                time = '0' + time;
            }
            return time = time.concat('PM');
        }else if(parseInt(hour) === 12){
            return time = time.concat('PM');
        }else{
            return time = time.concat('AM');
        }
    }

    // 요일 계산기
    function getDayOfWeek(date){
        const week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const kstDate = new Date(new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));
        const dayOfWeek = week[kstDate.getDay()];
        
        return dayOfWeek;
    }

    // 달력 클릭 막기
    const disabledDates = ['2022-12-13'];
    function tileDisabled({ date, view }) {
        // Disable tiles in month view only
        if (view === 'month') {
          // Check if a date React-Calendar wants to check is on the list of disabled dates
        return disabledDates[0];
        }
    }
    
    // 축하메세지 작성
    const postReply = () => {
        const replyData = {
            weddingKey : no,
            name:reply.replyWriter,
            password:reply.replyPw,
            content:reply.replyContent
        }
        axios.post(`https://babacard.co.kr/babacard/api/v1/wedding/reply`, JSON.stringify(replyData), {
            headers: { 
                "Content-Type": 'application/json',
            }
        }).then(res=>{
            axios.get(
                `https://babacard.co.kr/babacard/api/v1/wedding/${no}/reply`, {
                params: { 
                    weddingKey : no,
                }
            }
            ).then(response=>{
                setReplyList(response.data)
                toast.success('작성성공!');
            })
        })
    }

    // 축하메세지 삭제
    const delReply = (item) => {
        axios.delete(`https://babacard.co.kr/babacard/api/v1/wedding/reply`, {
            data:{
                weddingReplyKey : parseInt(item.weddingReplyKey),
                password:replyDelPw,
            },
            headers: { 
                "Content-Type": 'application/json',
            }
        }).then(res=>{
            axios.get(
                `https://babacard.co.kr/babacard/api/v1/wedding/${no}/reply`, {
                params: { 
                    weddingKey : no,
                }
            }
            ).then(response=>{
                setReplyList(response.data)
                toast.success('삭제성공!');
            })
        }).catch((e)=>{
            if(e.response.status === 409){
                toast.error('비밀번호가 다릅니다.')
            }
        })
    }
    // 참석여부 포스트
    const postattendance = () =>{
        const value = {
            weddingKey: no,
            name:attendance.attendance_name,
            phone:attendance.attendance_phone,
            attended:attendance_attended,
            meals:attendance.attendance_meals,
        }
        axios.post(`https://babacard.co.kr/babacard//api/v1/wedding/attendance`, JSON.stringify(value), {
            headers: { 
                "Content-Type": 'application/json',
            }
        }).then(res => {
            toast.success('전송완료!')
        })
    }

    //화환보내기
    const handleFlower = (e) => {
        let type = e.target.value;
        if(type === 'groom'){
            setFlower(member.groom);
        }else{
            setFlower(member.bride);
        }
    }

    // 화환보내기 시간 1시간 전으로
    const flowerTime = (time) => {
        let num = parseInt(time.substring(0,2))-1;
        let hour = time.substring(0,2);
        return time = time.replace(hour,num)
    }

    // 화환보내기 경조사어 셀렉트
    const handleCelebrate = (e) => {
        SetCelebrate(e.target.value)
    }

    const fontList = window.Indata.fontList;
    const fontIndex = fontList.findIndex(obj => obj.title_en === member.font)
    const theme = [
        // 테마1
        `
            <h2>우리 결혼합니다.</h2>
            <h3 style="font-family:${member.font};font-size:${fontSize[fontIndex]}em">${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
            <h3 style="font-family:${member.font};font-size:${fontSize[fontIndex]}em">${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3>
            <div class="wedding_data" style="font-family:${member.font};font-size:${fontSize[fontIndex]-1.5}em" >${member.place.date} ${getDayOfWeek(member.place.date)} ${member.place.time}</div>
            <div class="wedding_data" style="font-family:${member.font};font-size:${fontSize[fontIndex]-1.5}em" >${member.place.place} ${member.place.detail}</div>
        `,
        // 테마2
        `
        <div class="content_area">
            <div>
                <h2>INVITATION</h2>
            </div>
            <div>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <img src="./img/card/theme/theme6_middle.svg" alt="" class="and" />
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3>
            </div>
            <div>
                <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
        // 테마3
        `
        <div class="content_area">
            <div>
                <h2>WEDDING INVITATION</h2>
            </div>
            <div style="position:relative">
                <div class="middle"></div>
                <svg>
                    <path id="curve" d="M 15 105 A 80 30 0 1 1 195 105" />
                    <text x="105" y="0" text-anchor="middle" >
                    <textPath xlink:href="#curve">
                        ${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name} ♥ ${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}
                    </textPath>
                    </text>
                </svg>
            </div>
            <div>
                <div class="wedding_data">${member.place.date} </div>
                <div class="wedding_data">${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
        // 테마4
        `
        <div class="content_area">
            <div>
                <h3>${member.place.date}</h3>
                <h3 style="font-family:"GowunBatang-Regular">${setting.orderCouple === 'orderGroom'? member.groom.name.substring(1) : member.bride.name.substring(1)} & ${setting.orderCouple === 'orderGroom'? member.bride.name.substring(1) : member.groom.name.substring(1)}</h3> 
            </div>
            <div>
                <div class="wedding_data">${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
        // 테마5
        `
        <div class="content_area">
            <div>우리 결혼합니다</div>
            <div>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name} <img src="./img/card/theme/theme5_and.svg" alt="" class="and" width="60px"/> ${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3> 
            </div>
            <div>
                <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
        // 테마6
        `
        <div class="content_area">
            <div>
                <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                    <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
            <div style="display:flex; flex-direction: column">
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <img src="./img/card/theme/theme6_middle.svg" alt="" class="and"/>
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3> 
            </div>
            <div>
                <div class="theme6_bottom"><img src="./img/card/theme/theme6_bottom.svg" alt="" width="100%"/>
            </div>
        </div>
        `,
        // 테마7
        `
        <div class="content_area">
            <div>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <img src="./img/card/theme/theme6_middle.svg" alt="" class="and"/>
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3> 
            </div>
            <div>
                <img src="./img/card/theme/theme7_middle.svg" alt="" width="100%"/>
            </div>
            <div>
            <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
        </div>
        </div>
        `,
        // 테마8
        `
        <div class="content_area">
            <div style="background:url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${photoKeyList[thumb]}) center/cover no-repeat; width:100%; height:60vh"></div>
            <div>
                <h4>${no === '186' ? '처음과 같이 이제와 항상 영원히' : '평생 함께, 언제나 한결같이'}</h4>
                <div><h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3><img src="./img/card/theme/theme5_and.svg" alt="" class="and"/><h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3></div>
            </div>
            <div>
                <div class="wedding_data" style="color : black; font-size: 18px">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data" style="color : black; font-size: 18px">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
        // 테마9
        `
        <div class="content_area">
            <div style="background:url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${photoKeyList[thumb]}) center/cover no-repeat; width:100%; height:100%"></div>
            <div>
                <img src="./img/card/theme/theme9_middle.svg" alt="" class="and"/>
            </div>
            <div>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3>
            </div>
            <div>
            <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
        </div>
        </div>
        `,
        // 테마10
        `
        <div class="content_area">
            <div>
                <p>아름다운</p>
                <p>우리의 시작</p>
                <p>축복해주세요</p>
            </div>
            <div>
                <p>${member.place.date}</p>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3>
            </div>
            <div>
                <div class="wedding_data"> ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place}</div>
                <div class="wedding_data">${member.place.detail}</div>
            </div>
        </div>
        `,
        // 테마11
        `
        <div class="content_area">
            <div style="background:url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${photoKeyList[thumb]}) center/cover no-repeat;">
                <img src="./img/card/theme/theme11_middle.svg" alt="" />
            </div>
            <div>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3>
            </div>
            <div class="divider"></div>
            <div>
                <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
         // 테마12
        `
        <div class="content_area">
            <div>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <img src="./img/card/theme/theme6_middle.svg" alt="" class="and" />
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3>
            </div>
            <div>
                <img src="./img/card/theme/theme12_middle.png" alt="" />
            </div>
            <div>
                <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
         // 테마13
        `
        <div class="content_area">
            <div>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <img src="./img/card/theme/theme6_middle.svg" alt="" class="and" />
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3>
            </div>
            <div style="background:url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${photoKeyList[thumb]}) center/cover no-repeat; width:100%;"></div>
            <div>
                <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
         // 테마14
        `
        <div class="content_area">
            <h2>우리 결혼합니다</h2>
            <div style="background:url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${photoKeyList[thumb]}) center/cover no-repeat;"></div>
            <div>
                <h3>${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}</h3>
                <span>*</span>
                <h3>${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}</h3>
            </div>
            <div>
                <div class="wedding_data">${member.place.date} ${getDayOfWeek(member.place.date)} ${setAmPm(member.place.time)}</div>
                <div class="wedding_data">${member.place.place} ${member.place.detail}</div>
            </div>
        </div>
        `,
    ]
    
    return(
        <Fragment>
            <h1 className="hide">청첩장</h1>
            <section className={`card theme${member.theme}`} style={{fontFamily:`${member.font}!important`, fontSize:`${fontSize[fontIndex]}em!important`}}>
                {/* 메인 */}
                <div className='sec1' style={{order:'0', background: 'url("./img/card/theme/theme' + member.theme + '_main.jpg") center/cover no-repeat', position:'relative', overflow:'hidden'}}>
                    <div style={{display: 'flex',alignItems: 'center', flexDirection: 'column', height:'100%', width:'100%', justifyContent: 'center'}} dangerouslySetInnerHTML={{__html:theme[member.theme-1]}} />
                </div>

                {/* BGM */}
                <span className={(member.bgm)?'bgm_btn':'hide'}>
                    <Player music={member.bgm}/>
                </span>

                {/* 애니메이션 */}
                {member.animation !== '2' && <div style={{
                    position:'fixed',
                    height:'100vh',
                    width: '100%',
                    zIndex: '999',
                    pointerEvents: 'none',
                    margin:'0',
                    maxWidth:'500px'
                }}><Effect2 ani={member.animation}/></div>}
                {member.animation === '2' && <MemoEffect effect={member.animation}/>}

                {/* 인사말 */}
                {setting.hideGreeting !== 'Y' &&
                <div className='sec2' style={{order:ordering[1]}}>
                    <div style={{width:'300px',margin:'0 auto'}}>
                        <img src="./img/card/greeting_deco.svg" alt="인사말 데코"/>
                    </div>
                    <h2>Invitation</h2>
                    <h3 style={{marginTop:0, marginBottom:20}}>초대합니다</h3>
                    <pre style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.5}em`, color:`var(--color-theme${member.theme})`}}>{member.greeting}</pre>
                </div>
                }

                {/* New 컨택트 */}
                <div className={(!member.groom.phone && !member.groom.fatherPhone && !member.groom.motherPhone && !member.bride.phone && !member.bride.fatherPhone && !member.bride.motherPhone )?'hide':'sec3'} style={{order:ordering[2]}}>
                {/* <p>{JSON.stringify(member)}</p> */}
                    <div style={{
                        border:'1px solid #ccc',
                        padding:'40px 0',
                        borderLeft:'none',
                        borderRight:'none',
                        display:'flex',
                        flexDirection:'column',
                        gap: no === '186' ? '12px' : '20px'}}>
                        <ul style={setting.orderCouple === 'orderGroom'?{order:1}:{order:2}}>
                            <li>{(member.groom.fatherDeceased === 'y')?'故':''}{member.groom.fatherName}</li>
                            <li><FontAwesomeIcon icon={faCircleNotch} /></li>
                            <li>{(member.groom.motherDeceased === 'y')?'故':''}{member.groom.motherName}</li>
                            <li>의</li>
                            <li>{member.groom.nickname}</li>
                            <li>{member.groom.name}</li>
                        </ul>
                        {
                            no === '186' &&
                            <ul style={{order:1}}>
                                <li style={{fontSize:12}}>바오로</li>
                                <li></li>
                                <li style={{fontSize:12}}>루치아</li>
                                <li></li>
                                <li></li>
                                <li style={{fontSize:12}}>요셉</li>
                            </ul>
                        }
                        <ul style={setting.orderCouple === 'orderGroom'?{order:2}:{order:1}}>
                            <li>{(member.bride.fatherDeceased === 'y')?'故':''}{member.bride.fatherName}</li>
                            <li><FontAwesomeIcon icon={faCircleNotch} /></li>
                            <li>{(member.bride.motherDeceased === 'y')?'故':''}{member.bride.motherName}</li>
                            <li>의</li>
                            <li>{member.bride.nickname}</li>
                            <li>{member.bride.name}</li>
                        </ul>
                        {
                            no === '186' &&
                            <ul style={{order:2}}>
                                <li style={{fontSize:12}}>미카엘</li>
                                <li></li>
                                <li style={{fontSize:12}}>미카엘라</li>
                                <li></li>
                                <li></li>
                                <li style={{fontSize:12}}>카타리나</li>
                            </ul>
                        }
                    </div>
                    <Popup trigger={<button className="btn contact_btn">연락하기</button>} position="center" modal nested closeOnDocumentClick={false}>
                        {close => (
                            <div className="modal">
                                <button className="close" onClick={close}>&times;</button>
                                <div className="header">연락하기</div>
                                <div className="content">
                                    <div className={`callArea theme${member.theme}`} style={{display:'flex',flexDirection:'column'}}>
                                        {/* 연락부분 */}
                                        {/* 신랑 */}
                                        <ul style={setting.orderCouple === 'orderGroom'?{order:1}:{order:2}}>
                                            <li className={(!member.groom.fatherPhone)?'hide':''}>
                                                <span>{(member.groom.fatherDeceased === 'y')?'故':''}{member.groom.fatherName}</span>
                                                <p>신랑아버지</p>
                                                <div className={(member.groom.fatherDeceased === 'y' || !member.groom.fatherPhone)?'hide':''}>
                                                    <a href={'tel:'+member.groom.fatherPhone}>
                                                        <FontAwesomeIcon icon={faSquarePhone} />
                                                    </a>
                                                    <a href={'sms:'+member.groom.fatherPhone}>
                                                        <FontAwesomeIcon icon={faMessage} width={28}/>
                                                    </a>
                                                </div>
                                            </li>
                                            <li className={(!member.groom.motherPhone)?'hide':''}>
                                                <span>{(member.groom.motherDeceased === 'y')?'故':''}{member.groom.motherName}</span>
                                                <p>신랑어머니</p>
                                                <div className={(member.groom.motherDeceased === 'y' || !member.groom.motherPhone)?'hide':''}>
                                                    <a href={'tel:'+member.groom.motherPhone}>
                                                        <FontAwesomeIcon icon={faSquarePhone} />
                                                    </a>
                                                    <a href={'sms:'+member.groom.motherPhone}>
                                                        <FontAwesomeIcon icon={faMessage} width={28}/>
                                                    </a>
                                                </div>
                                            </li>
                                            <li className={(!member.groom.phone)?'hide':''}>
                                                <span>{member.groom.name}</span>
                                                <p>신랑</p>
                                                <div>
                                                    <a href={'tel:'+member.groom.phone}>
                                                        <FontAwesomeIcon icon={faSquarePhone} />
                                                    </a>
                                                    <a href={'sms:'+member.groom.phone}>
                                                        <FontAwesomeIcon icon={faMessage} width={28}/>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                        {/* 신부 */}
                                        <ul style={setting.orderCouple === 'orderGroom'?{order:2}:{order:1}}>
                                            <li className={(!member.bride.fatherPhone)?'hide':''}>
                                                <span>{(member.bride.fatherDeceased === 'y')?'故':''}{member.bride.fatherName}</span>
                                                <p>신부아버지</p>
                                                <div className={(member.bride.fatherDeceased === 'y' || !member.bride.fatherPhone)?'hide':''}>
                                                    <a href={'tel:'+member.bride.fatherPhone}>
                                                        <FontAwesomeIcon icon={faSquarePhone} />
                                                    </a>
                                                    <a href={'sms:'+member.bride.fatherPhone}>
                                                        <FontAwesomeIcon icon={faMessage} width={28}/>
                                                    </a>
                                                </div>
                                            </li>
                                            <li className={(!member.bride.motherPhone)?'hide':''}>
                                                <span>{(member.bride.motherDeceased === 'y')?'故':''}{member.bride.motherName}</span>
                                                <p>신부어머니</p>
                                                <div className={(member.bride.motherDeceased === 'y' || !member.bride.motherPhone)?'hide':''}>
                                                    <a href={'tel:'+member.bride.motherPhone}>
                                                        <FontAwesomeIcon icon={faSquarePhone} />
                                                    </a>
                                                    <a href={'sms:'+member.bride.motherPhone}>
                                                        <FontAwesomeIcon icon={faMessage} width={28}/>
                                                    </a>
                                                </div>
                                            </li>
                                            <li className={(!member.bride.phone)?'hide':''}>
                                                <span>{member.bride.name}</span>
                                                <p>신부</p>
                                                <div className={(!member.bride.phone)?'hide':''}>
                                                    <a href={'tel:'+member.bride.phone}>
                                                        <FontAwesomeIcon icon={faSquarePhone} />
                                                    </a>
                                                    <a href={'sms:'+member.bride.phone}>
                                                        <FontAwesomeIcon icon={faMessage} width={28}/>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>

                {/* 캘린더 */}
                {setting.hideCalendar !== 'Y' &&
                <div className='sec4' style={{order:ordering[3]}}>
                    <h2>Calendar</h2>
                    <h3 style={{marginTop:0, marginBottom:20}}>달력</h3>
                    <div className='calendar-container'>
                        <Calendar 
                            defaultValue={new Date(new Date(member.place.date).toLocaleString('en-US', { timeZone: 'Asia/Seoul' }))}
                            range={false}
                            selectRange={false}
                            calendarType="US" 
                            formatDay={(locale, date) => moment(date).format("D")}
                            navigationLabel={null}
                            showNeighboringMonth={false}
                            tileDisabled={tileDisabled}
                            minDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
                            maxDetail="month"
                        />
                    </div>
                    <img src="https://cdn-icons-png.flaticon.com/512/2682/2682341.png" alt="" width={40}/>
                    <h4 style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.6}em`, marginTop:'10px'}}>{dDay(new Date(member.place.date))}</h4>
                </div>}

                {/* 갤러리 */}
                <div className={(photoKeyList.length)?'sec5':'hide'} style={{order:ordering[4]}} >
                    <h2>Gallery</h2>
                    <h3 style={{marginTop:0, marginBottom:20}}>갤러리</h3>
                    <Gallery photoKeyList={photoKeyList} type={member.galleryType} coverArr={coverArr}/>
                </div>

                {/* 비디오 */}
                <div className={(member.video)?'sec6':'sec6 hide'} style={{order:ordering[5]}}>
                    <h2>Video</h2>
                    <h3 style={{marginTop:0, marginBottom:20}}>비디오</h3>
                    <iframe width={'100%'} height={300} src={`https://www.youtube.com/embed/${member.video}`} allowFullScreen title='video' allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>

                {/* 계좌정보 */}
                <div className={(!member.groom.bank && !member.groom.fatherBank && !member.groom.motherBank && !member.bride.bank && !member.bride.fatherBank && !member.bride.motherBank)?'hide':'sec7'} style={{order:ordering[6]}}>
                    <img src="https://cdn-icons-png.flaticon.com/512/2569/2569749.png" alt="" width={40}/>
                    <h2>A Celebration of love</h2>
                    <h3 style={{marginTop:0, marginBottom:20}}>마음전하실곳</h3>
                    <div>
                        {
                            no === '186' ?
                            <div className="account_area" style={{display:'flex'}}>
                                <div className={(!member.groom.bank && !member.groom.fatherBank && !member.groom.motherBank)?'hide':'account_category'} onClick={()=>{
                                    if(accountBtn !== 'g'){
                                        setAccountBtn('g');
                                    }else{
                                        setAccountBtn(null);
                                    }
                                }} style={setting.orderCouple === 'orderGroom'?{order:1}:{order:2}}>신랑측 <FontAwesomeIcon icon={(accountBtn==='g')?faCaretUp:faCaretDown}></FontAwesomeIcon></div>
                            </div>:
                            <div className="account_area" style={{display:'flex'}}>
                                <div className={(!member.groom.bank && !member.groom.fatherBank && !member.groom.motherBank)?'hide':'account_category'} onClick={()=>{
                                    if(accountBtn !== 'g'){
                                        setAccountBtn('g');
                                    }else{
                                        setAccountBtn(null);
                                    }
                                }} style={setting.orderCouple === 'orderGroom'?{order:1}:{order:2}}>신랑측 <FontAwesomeIcon icon={(accountBtn==='g')?faCaretUp:faCaretDown}></FontAwesomeIcon></div>
                                <div className={(!member.bride.bank && !member.bride.fatherBank && !member.bride.motherBank)?'hide':'account_category'} onClick={()=>{
                                    if(accountBtn !== 'b'){
                                        setAccountBtn('b');
                                    }else{
                                        setAccountBtn(null);
                                    }
                                }} style={setting.orderCouple === 'orderGroom'?{order:2}:{order:1}}>신부측 <FontAwesomeIcon icon={(accountBtn==='b')?faCaretUp:faCaretDown}></FontAwesomeIcon></div>
                            </div>
                        }
                        <ul className={(accountBtn === 'g' || no === '186')?'active':''}>
                            <li className={(!member.groom.bank)?'hide':''}>
                                <div>
                                    <div style={{textAlign:'left', fontSize:`${fontSize[fontIndex]-1.6}em`}}>{member.groom.name} 신랑</div>
                                    <div style={{textAlign:'left'}}>{member.groom.bank} {member.groom.account}</div>
                                </div>
                                <div>
                                    <button onClick={()=>{handleCopyClipBoard(member.groom.account)}}>복사</button>
                                    <div className={(member.groom.kakao)?'':'hide'}>
                                        <a href={member.groom.kakao}>
                                            <img src="./img/card/kakao_pay.svg" alt="카카오페이" width={50}/>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li className={(!member.groom.fatherBank)?'hide':''}>
                                <div>
                                    <div style={{textAlign:'left', fontSize:`${fontSize[fontIndex]-1.6}em`}}>{member.groom.fatherName} 신랑아버지</div>
                                    <div style={{textAlign:'left'}}>{member.groom.fatherBank} {member.groom.fatherAccount}</div>
                                </div>
                                <div>
                                    <button onClick={()=>{handleCopyClipBoard(member.groom.fatherAccount)}}>복사</button>
                                    <div className={(member.groom.fatherKakao)?'':'hide'}>
                                        <a href={member.groom.fatherKakao}>
                                            <img src="./img/card/kakao_pay.svg" alt="카카오페이" width={50}/>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li className={(!member.groom.motherBank)?'hide':''}>
                                <div>
                                    <div style={{textAlign:'left', fontSize:`${fontSize[fontIndex]-1.6}em`}}>{member.groom.motherName} 신랑어머니</div>
                                    <div style={{textAlign:'left'}}>{member.groom.motherBank} {member.groom.motherAccount}</div>
                                </div>
                                <div>
                                    <button onClick={()=>{handleCopyClipBoard(member.groom.motherAccount)}}>복사</button>
                                    <div className={(member.groom.motherKakao)?'':'hide'}>
                                        <a href={member.groom.motherKakao}>
                                            <img src="./img/card/kakao_pay.svg" alt="카카오페이" width={50}/>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {
                        no === '186' &&
                        <div className="account_area" style={{display:'flex'}}>
                            <div className={(!member.bride.bank && !member.bride.fatherBank && !member.bride.motherBank)?'hide':'account_category'} style={setting.orderCouple === 'orderGroom'?{order:2}:{order:1}}>신부측 <FontAwesomeIcon icon={(accountBtn==='b')?faCaretUp:faCaretDown}></FontAwesomeIcon></div>
                        </div>
                    }
                    <div>
                        <ul className={(accountBtn === 'b' || no === '186')?'active':''}>
                            <li className={(!member.bride.bank)?'hide':''}>
                                <div>
                                    <div style={{textAlign:'left', fontSize:`${fontSize[fontIndex]-1.6}em`}}>{member.bride.name} 신부</div>
                                    <div style={{textAlign:'left'}}>{member.bride.bank} {member.bride.account}</div>
                                </div>
                                <div>
                                    <button onClick={()=>{handleCopyClipBoard(member.bride.account)}}>복사</button>
                                    <div className={(member.bride.kakao)?'':'hide'}>
                                        <a href={member.bride.kakao}>
                                            <img src="./img/card/kakao_pay.svg" alt="카카오페이" width={50}/>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li className={(!member.bride.fatherBank)?'hide':''}>
                                <div>
                                    <div style={{textAlign:'left', fontSize:`${fontSize[fontIndex]-1.6}em`}}>{member.bride.fatherName} 신부아버지</div>
                                    <div style={{textAlign:'left'}}>{member.bride.fatherBank} {member.bride.fatherAccount}</div>
                                </div>
                                <div>
                                    <button onClick={()=>{handleCopyClipBoard(member.bride.fatherAccount)}}>복사</button>
                                    <div className={(member.bride.fatherKakao)?'':'hide'}>
                                        <a href={member.bride.fatherKakao}>
                                            <img src="./img/card/kakao_pay.svg" alt="카카오페이" width={50}/>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li className={(!member.bride.motherBank)?'hide':''}>
                                <div>
                                    <div style={{textAlign:'left', fontSize:`${fontSize[fontIndex]-1.6}em`}}>{member.bride.motherName} 신부어머니</div>
                                    <div style={{textAlign:'left'}}>{member.bride.motherBank} {member.bride.motherAccount}</div>
                                </div>
                                <div>
                                    <button onClick={()=>{handleCopyClipBoard(member.bride.motherAccount)}}>복사</button>
                                    <div className={(member.bride.motherKakao)?'':'hide'}>
                                        <a href={member.bride.motherKakao}>
                                            <img src="./img/card/kakao_pay.svg" alt="카카오페이" width={50}/>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* 화환보내기 */}
                {
                    no !== '186' ?
                    <div className='sec8' style={{order:ordering[7]}}>
                        <h2>Flower</h2>
                        <h3 style={{marginTop:0, marginBottom:20}}>화환보내기</h3>
                        <img src="./img/main/main4_img1.svg" alt="화환보내기 이미지" />
                        { checkDate(member.place.date) ? 
                        <Popup trigger={ <button className="btn">화환보내기</button>} position="center" modal closeOnDocumentClick={false}>
                        {close => (
                            <div className={"modal card theme"+member.theme} style={{width:'100%'}}>
                                <button className="close" onClick={close}>&times;</button>
                                <h2 className="header">화환보내기</h2>
                                <div className="content ">
                                    <div style={{display:'flex',justifyContent:'center'}}>
                                        <label htmlFor="radioGroom" 
                                        style={setting.orderCouple === 'orderGroom'?{
                                            display:'flex',
                                            flexDirection:'column',
                                            alignItems:'center',
                                            order:1
                                        }:{
                                            display:'flex',
                                            flexDirection:'column',
                                            alignItems:'center',
                                            order:2
                                        }}><img src="https://cdn-icons-png.flaticon.com/512/5500/5500120.png" alt="" width={70} style={{marginBottom:'10px'}}/> 신랑측
                                            <input type="radio" name="flower" id="radioGroom" value={'groom'} onChange={(e) => handleFlower(e)} style={{width:100,outline:'none'}}/>
                                        </label>
                                        <label htmlFor="radioBride"
                                        style={setting.orderCouple === 'orderGroom'?{
                                            display:'flex',
                                            flexDirection:'column',
                                            alignItems:'center',
                                            order:2
                                        }:{
                                            display:'flex',
                                            flexDirection:'column',
                                            alignItems:'center',
                                            order:1
                                        }}><img src="https://cdn-icons-png.flaticon.com/512/2474/2474441.png" alt="" width={70} style={{marginBottom:'10px'}}/> 신부측
                                            <input type="radio" name="flower" value={'bride'} id="radioBride" onChange={(e) => handleFlower(e)} style={{width:100,outline:'none'}}/>
                                        </label>
                                    </div>
                                    {(flower)?
                                    <div>
                                        <select name="" id="" onChange={(e)=>{handleCelebrate(e)}} style={{
                                            height: '50px',
                                            textAlign: 'center',
                                            borderColor: '#ccc',
                                            width: '250px'
                                        }}>
                                            <option value=" ">경조사어를 선택해주세요</option>
                                            {(flower.type === 'groom')?
                                            <option value="祝結婚">祝結婚 - (축결혼)</option>
                                            :
                                            <option value="祝華婚">祝華婚 - (축화혼)</option>
                                            }
                                            <option value="결혼을 진심으로 축하드립니다.">결혼을 진심으로 축하드립니다.</option>
                                            <option value="결혼을 축하하며 행복을 기원합니다.">결혼을 축하하며 행복을 기원합니다.</option>
                                        </select>
                                    </div>
                                    :''}
                                    <div>
                                        {(flower)?
                                        <div onClick={discord} href='#'>
                                            <button className="btn" style={{ margin: '20px auto', display: 'block', width:'100%', maxWidth:'250px'}}>화환 종류 고르기</button>
                                        </div>
                                        :''}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>
                    : 
                    <button className="btn">종료된 예식입니다</button>
                    }
                    </div>:
                    <div className='sec8' style={{order:ordering[7]}}>
                        <h2>Flower</h2>
                        <h3 style={{marginTop:0, marginBottom:20}}>화환</h3>
                        <p style={{marginBottom: 20}}>성당 결혼식은 화환, 꽃바구니, 화분등</p>
                        <p>일체 반입이 금지되오니, 정중히 사절합니다.</p>
                    </div>
                }

                {/* 축하메세지 */}
                {setting.hideMessage !== 'Y' &&
                <div className='sec9' style={{order:ordering[8]}}>
                    <img src="https://cdn-icons-png.flaticon.com/512/3708/3708773.png" alt="" width={40}/>
                    <h2>Celebrate</h2>
                    <h3 style={{marginTop:0, marginBottom:20}}>축하메세지</h3>
                    <ul>
                        {replyList.map((item, idx)=>{
                            return(
                                <li className="msg_list" key={idx} >
                                    <div>
                                        <p style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.9}em`}}>{item.name}</p>
                                        <p style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.9}em`}}>
                                        <Popup trigger={<button style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.9}em`}}>삭제</button>} position="center" modal nested>
                                            {close => (
                                                <div className="modal">
                                                    <button className="close" onClick={close}>&times;</button>
                                                    <div className="content"> 
                                                        <label 
                                                        style={{display:'flex',flexDirection:'column',alignItems:'center'}}htmlFor="replyDelPw">비밀번호<input type="password" name="replyDelPw" id="replyDelPw" autoComplete="off" onChange= {(e)=> {handleInputChange('replyDelPw',e)}}/></label> 
                                                        <button className='btn' style={{width:'45%',margin:'0 auto',display:'block'}} onClick={()=>{
                                                            delReply(item)
                                                            close();
                                                        }}>확인</button>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                        </p>
                                    </div>
                                    <div style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.9}em`}}>{item.content}</div>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="write_area">
                        <ul>
                            <li style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.9}em`}}>
                                <label htmlFor="replyWriter" style={{textAlign:'left'}}>작성자</label>
                                <input type="text"  autoComplete="off" name="replyWriter" id="replyWriter" value={replyWriter} onChange={(e)=>handleInputChange('reply',e)}/>
                            </li>
                            <li style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.9}em`}}>
                                <label htmlFor="replyPw" style={{textAlign:'left'}}>비밀번호</label>
                                <input type="password" autoComplete="off" name="replyPw" id="replyPw" value={replyPw} onChange={(e)=>handleInputChange('reply',e)}/>
                            </li>
                            <li style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.9}em`}}>
                                <label htmlFor="replyContent" style={{textAlign:'left'}}>내용</label>
                                <textarea name="replyContent" id="replyContent" cols="30" rows="5" value={replyContent} onChange={(e)=>handleInputChange('reply',e)}></textarea>
                            </li>
                        </ul>
                        
                        <button className="btn" onClick={()=>{
                            if(!replyWriter){
                                toast.error('작성자를 적어주세요!');
                                document.querySelector('#replyWriter').focus();
                            }else if(!replyPw){
                                toast.error('비밀번호를 적어주세요!');
                                document.querySelector('#replyPw').focus();
                            }else if(!replyContent){
                                toast.error('내용을 적어주세요!');
                                document.querySelector('#replyContent').focus();
                            }else{
                                postReply();
                                onReset('reply');
                            }
                        }}>작성</button>
                    </div>
                </div>}

                {/* 대표사진 이미지 노출 */}
                {thumb
                && member.theme !== '8' 
                && member.theme !== '9' 
                && member.theme !== '11' 
                && member.theme !== '13' 
                && member.theme !== '14'?
                <div style={{order:9}}>
                    <div style={{
                        background:`url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${photoKeyList[thumb]}) center/contain no-repeat`,
                        width:'100%',
                        height:'600px'
                    }}>
                    </div>
                </div>
                :
                ''
                }

                {/* 위치 */}
                <div className='sec10' style={{order:ordering[9]}}>
                    <h2>Location</h2>
                    <h3 style={{marginTop:0, marginBottom:20}}>위치</h3>
                    <ul>
                        <li style={{fontSize:`${fontSize[fontIndex]-1.6}em`}}>{member.place.place}</li>
                        <li className={(!member.place.detail)?'hide':''} style={{fontSize:`${fontSize[fontIndex]-1.8}em`}}>{member.place.detail}</li>
                        <li className={(!member.place.telNo)?'hide':''} style={{fontSize:`${fontSize[fontIndex]-1.8}em`}}>{member.place.telNo}</li>
                        <li style={{fontSize:`${fontSize[fontIndex]-1.8}em`}}>{member.place.address}</li>
                    </ul>

                    <Map
                        center={mapState.center}
                        style={{ width: "100%", height: "360px" }}
                        level={1}
                        draggable={false}
                        zoomable={false}
                        >
                        <MapMarker position={mapState.center}>
                        </MapMarker>
                    </Map>
                    <div className="kakao_map_btn">
                        <a href={`https://map.kakao.com/link/map/${mapState.center.lat},${mapState.center.lng}`}><img src="./img/card/kakao_map.svg" alt="카카오맵"/></a>
                    </div>
                    <div>
                        <div className={(member.place.info)?'place_info':'hide'} style={{width:'100%',textAlign:'center'}}>
                            <h2>Information</h2>
                            <h3 style={{marginTop:0, marginBottom:20}}>정보</h3>
                            <pre style={{padding:'0 16px', textAlign:'center', margin:'0 auto', lineHeight:'30px' ,fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.8}em`, color:`var(--color-theme${member.theme})`, whiteSpace: 'break-spaces'}}> 
                                {member.place.info}
                            </pre>
                        </div>
                    </div>
                </div>

                {/* 참석여부 */}
                {setting.hideAttend !== 'Y' &&
                <div className='sec11' style={{order:ordering[10]}}>
                    <h2>Attendance</h2>
                    <h3 style={{marginTop:0, marginBottom:20}}>참석</h3>
                    <Popup trigger={ <button className="btn">참석 여부 알리기</button>} position="center" modal closeOnDocumentClick={false}>
                        {close => (
                            <div className="modal">
                                <button className="close" onClick={close}>&times;</button>
                                <div className="header">참석 여부</div>
                                <div className={`content card   attendance theme${member.theme}`}> 
                                <ul>
                                    <li style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.8}em`}}>
                                        <label htmlFor="attendance_name" style={{textAlign:'left'}}>이름</label>
                                        <input type="text" name="attendance_name" id="attendance_name" value={attendance_name}  onChange={(e)=>handleInputChange('attendance',e)}/>
                                    </li>
                                    <li style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.8}em`}}>
                                        <label htmlFor="attendance_phone" style={{textAlign:'left'}}>휴대전화</label>
                                        <input type="tel" name="attendance_phone" id="attendance_phone" value={attendance_phone}  onChange={(e)=>handleInputChange('attendance',e)}/>
                                    </li>
                                    <li style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.8}em`}}>
                                        <span>참석여부</span>
                                        <label htmlFor="attendance_attended_true">참석
                                        <input type="radio" name="attendance_attended" id="attendance_attended_true" value='y' onClick={(e)=>{
                                            setMealChk(true);
                                            handleInputChange('attendance',e);
                                        }}/></label>
                                        <label htmlFor="attendance_attended_false">불참석
                                        <input type="radio" name="attendance_attended" id="attendance_attended_false" value='n' onClick={(e)=>{
                                            setMealChk(false);
                                            handleInputChange('attendance',e);
                                        }}/></label>
                                    </li>
                                    <li className={(mealChk)?'':'hide'} style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.8}em`}}>
                                        <label htmlFor="attendance_meals" style={{textAlign:'left'}}>식사여부</label>
                                        <input type="number" name="attendance_meals" id="attendance_meals" min={0} value={attendance_meals} placeholder='식사인원의 숫자만 기입해주세요' onChange={(e)=>handleInputChange('attendance',e)}/>
                                    </li>
                                    <li className="agree" style={{flexDirection:'column',alignItems:'flex-start'}}>
                                    <p>개인정보 수집 및 이용동의(필수)</p>
                                    <span>
                                        참석여부 전달을 위한 개인정보 수집 및 이용에 동의해주세요.(성함, 연락처)<br />
                                        개인정보는 청첩장 이용 종료시까지 수집되며, 이후에는 삭제됩니다.
                                    </span>
                                    <div style={{alignSelf:'center'}}>
                                        <label htmlFor="own_agree" className="own_txt">
                                        <input type="checkbox" name="own_agree" value="" id="own_agree" onChange={()=>{setAgreeChk(!agreeChk)}}/>
                                        동의합니다.</label>
                                    </div>
                                    </li>
                                    <li style={{fontFamily:member.font, fontSize:`${fontSize[fontIndex]-1.6}em`}}>
                                        <textarea name="" id="" cols="30" rows="10" readOnly style={{fontFamily:'GowunBatang-Regular',lineHeight: '28px'}}    
                                        defaultValue={window.Indata.personalAgree} className={(agreeBtn)?'':'hide'}></textarea>
                                    </li>
                                </ul>
                                <button className="btn" onClick={()=>{
                                    if(!attendance_name){
                                        toast.error('작성자를 적어주세요!');
                                        document.querySelector('#attendance_name').focus();
                                    }else if(!attendance_phone){
                                        toast.error('휴대전화를 적어주세요!');
                                        document.querySelector('#attendance_phone').focus();
                                    }else if(!attendance_attended){
                                        toast.error('참석여부를 체크해주세요');
                                    }else if(!agreeChk){
                                        toast.error('개인정보제공을 동의해주세요!');
                                    }else{
                                        onReset('attendance');
                                        postattendance();
                                        close();
                                    }}}>신랑신부에게 전달</button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>}

                {/* 공유하기 */}
                {setting.hideShare !== 'Y' &&
                <div className='sec11' style={{order:12}}>
                    <h2 className="hide">공유하기</h2>
                    <div style={{width:'100%',display:'flex'}}>
                        <button className='btn' onClick={()=>{
                            shareKakao(
                                window.location.href,
                                `${setting.orderCouple === 'orderGroom'? member.groom.name : member.bride.name}님과 ${setting.orderCouple === 'orderGroom'? member.bride.name : member.groom.name}님의 모바일청첩장`,
                                `https://babacard.co.kr/babacard/api/v1/wedding/photo/${photoKeyList[thumb]}`,
                                `장소 : ${member.place.place} ${member.place.detail}\n시간 : ${member.place.date} / ${member.place.time}`
                            )}}>카카오톡 공유</button>
                        <button className='btn' onClick={()=>{handleCopyClipBoard(window.location.href);}}>URL 복사</button>
                    </div>
                </div>}
            </section>
            <CardFooter />
            <Toaster 
                    position="bottom-center"
                    toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                        background: '#363636',
                        color: '#fff',
                        },
                    
                        // Default options for specific types
                        success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                        },

                        error: {
                        style: {
                            background: 'red',
                        },
                        },
                    }}
                />
        </Fragment>
    )
}