import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import Main from './main';
import MakeCard from './makeCard';
import Login from './login';
import Admin from './admin';
import Card from './card';
import Logout from './logout';
import ScrollTop from './js/scrollTop';
import LoginAdmin from './loginAdmin';
import ModifyCard from './modifyCard';
import InfoCard from './infoCard';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const setVh = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
};
window.addEventListener('resize', setVh);
setVh();

root.render(
    <Fragment>
        <Router>
            <ScrollTop />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/makeCard" element={<MakeCard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/loginAdmin" element={<LoginAdmin />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/modifyCard" element={<ModifyCard />} />
                <Route path="/infoCard" element={<InfoCard />} />
                <Route path="/card" element={<Card />} />
                <Route path="*"
                    element={
                        <main style={{ padding: "1rem" }}>
                        <p>없는 페이지입니다.</p>
                        </main>
                    }
                />
            </Routes>
        </Router>
    </Fragment>
);