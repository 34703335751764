import React, { Fragment, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Thumbs } from 'swiper';
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

export default function Gallery (props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    // 갤러리타입 스와이프
    if(props.type === 'swipe'){
        return(
            <Fragment>
                <Swiper
                    slidesPerView={1}
                    modules={[Thumbs]}
                    thumbs={{ swiper: thumbsSwiper }}
                    >
                    {props.photoKeyList.map((item, idx)=>{
                        return(
                            <SwiperSlide key={idx}>
                                <div>
                                    <div style={{
                                        background:`url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${item}) no-repeat`,
                                        backgroundPosition:'center',
                                        backgroundSize: props.coverArr.includes(item.toString())? 'cover' : 'contain',
                                        width:'100%',
                                        height:'600px',
                                        maxWidth:'600px',
                                        margin:'0 auto'
                                    }}>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <Swiper 
                    modules={[Thumbs]}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                    slidesPerView={5}
                    style={{maxWidth:'600px'}}
                    >
                    {props.photoKeyList.map((item, idx)=>{
                        return(
                            <SwiperSlide key={idx}>
                                <div>
                                    <div style={{
                                        background:`url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${item}) center/cover no-repeat`,
                                        width:'20vw',
                                        maxWidth:'100px',
                                        height:'100px'
                                    }}>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Fragment>
        )
    // 갤러리타입 셀
    }else{
        return(
            <Fragment>
                <div
                style={{
                    display:'flex',
                    flexWrap:'wrap',
                    alignItems:'center',
                }}>
                    {props.photoKeyList.map((item, idx)=>{
                        return(
                        <Popup trigger={
                            <div className='cardGallery' style={{
                                background:`url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${item}) center/cover no-repeat`,
                                width:'100%',
                                height:'33.33333vw',
                                maxHeight: '170px',
                                flexBasis:'33.33333%',
                            }}>
                            </div>} position="center" modal  key={idx}>
                        {close => (
                            <div className="modal">
                                <button className="close" onClick={close}>&times;</button>
                                <Swiper
                                    slidesPerView={1}
                                    modules={[Thumbs]}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    initialSlide={props.photoKeyList.findIndex((e) => e === item)}
                                    >
                                    {props.photoKeyList.map((item, idx)=>{
                                        return(
                                            <SwiperSlide key={idx}>
                                                <div>
                                                    <div style={{
                                                        background:`url(https://babacard.co.kr/babacard/api/v1/wedding/photo/${item})no-repeat`,
                                                        backgroundPosition:'center',
                                                        backgroundSize: props.coverArr.includes(item.toString())? 'cover' : 'contain',
                                                        width:'100%',
                                                        height:'600px',
                                                        maxWidth:'600px',
                                                        margin:'0 auto'
                                                    }}>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        )}
                        </Popup>
                        )
                    })}
                </div>
            </Fragment>
        )
    }
}