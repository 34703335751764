import React, {useCallback} from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export function Effect(props){
    const effect = [
        {
            "autoPlay": true,
            "background": {
                "color": {
                "value": "#000000"
                },
                "opacity": 0.01
            },
            "defaultThemes": {},
            "delay": 0,
            "fullScreen": {
            "enable": true,
            "zIndex": 999
            },
            "detectRetina": true,
            "duration": 0,
            "fpsLimit": 120,
            "interactivity": {
            "detectsOn": "parent",
            "modes": {
                "attract": {
                "distance": 200,
                "duration": 0.4,
                "easing": "ease-out-quad",
                "factor": 1,
                "maxSpeed": 50,
                "speed": 1
                },
                "bounce": {
                "distance": 200
                },
                "bubble": {
                "distance": 400,
                "duration": 0.3,
                "mix": false,
                "opacity": 1,
                "size": 4,
                "divs": {
                    "distance": 200,
                    "duration": 0.4,
                    "mix": false,
                    "selectors": []
                }
                },
                "connect": {
                "distance": 80,
                "links": {
                    "opacity": 0.5
                },
                "radius": 60
                },
                "grab": {
                "distance": 400,
                "links": {
                    "blink": false,
                    "consent": false,
                    "opacity": 0.5
                }
                },
                "push": {
                "default": true,
                "groups": [],
                "quantity": 4
                },
                "remove": {
                "quantity": 2
                },
                "repulse": {
                "distance": 200,
                "duration": 0.4,
                "factor": 100,
                "speed": 1,
                "maxSpeed": 50,
                "easing": "ease-out-quad",
                "divs": {
                    "distance": 200,
                    "duration": 0.4,
                    "factor": 100,
                    "speed": 1,
                    "maxSpeed": 50,
                    "easing": "ease-out-quad",
                    "selectors": []
                }
                },
                "slow": {
                "factor": 3,
                "radius": 200
                },
                "trail": {
                "delay": 1,
                "pauseOnStop": false,
                "quantity": 1
                },
                "light": {
                "area": {
                    "gradient": {
                    "start": {
                        "value": "#ffffff"
                    },
                    "stop": {
                        "value": "#000000"
                    }
                    },
                    "radius": 1000
                },
                "shadow": {
                    "color": {
                    "value": "#000000"
                    },
                    "length": 2000
                }
                }
            }
            },
            "particles": {
            "bounce": {
                "horizontal": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.1
                },
                "value": 1
                },
                "vertical": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.1
                },
                "value": 1
                }
            },
            "collisions": {
                "absorb": {
                "speed": 2
                },
                "bounce": {
                "horizontal": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0.1
                    },
                    "value": 1
                },
                "vertical": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0.1
                    },
                    "value": 1
                }
                },
                "enable": false,
                "mode": "bounce",
                "overlap": {
                "enable": true,
                "retries": 0
                }
            },
            "color": {
                "value": "#fff",
                "animation": {
                "h": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                },
                "s": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                },
                "l": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                }
                }
            },
            "move": {
                "angle": {
                "offset": 0,
                "value": 90
                },
                "attract": {
                "distance": 200,
                "enable": false,
                "rotate": {
                    "x": 600,
                    "y": 1200
                }
                },
                "center": {
                "x": 50,
                "y": 50,
                "mode": "percent",
                "radius": 0
                },
                "decay": 0,
                "distance": {},
                "direction": "bottom",
                "drift": 0,
                "enable": true,
                "gravity": {
                "acceleration": 9.81,
                "enable": false,
                "inverse": false,
                "maxSpeed": 50
                },
                "path": {
                "clamp": true,
                "delay": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": 0
                },
                "enable": false,
                "options": {}
                },
                "random": false,
                "size": false,
                "speed": 2,
                "spin": {
                "acceleration": 0,
                "enable": false
                },
                "straight": false,
                "trail": {
                "enable": false,
                "length": 10,
                "fill": {}
                },
                "vibrate": false,
                "warp": false
            },
            "number": {
                "density": {
                "enable": true,
                "width": 1920,
                "height": 1080
                },
                "limit": 0,
                "value": 300
            },
            "opacity": {
                "random": {
                "enable": false,
                "minimumValue": 0.1
                },
                "value": {
                "min": 0.8,
                "max": 1
                },
                "animation": {
                "count": 0,
                "enable": false,
                "speed": 1,
                "decay": 0,
                "sync": false,
                "destroy": "none",
                "startValue": "random",
                "minimumValue": 0.1
                }
            },
            "reduceDuplicates": false,
            "shadow": {
                "blur": 5,
                "color": {
                "value": "#d6d6d6"
                },
                "enable": true,
                "offset": {
                "x": 0,
                "y": 0
                }
            },
            "shape": {
                "type": "circle"
            },
            "size": {
                "random": {
                "enable": true,
                "minimumValue": 1
                },
                "value": {
                "min": 1,
                "max": 10
                },
                "animation": {
                "count": 0,
                "enable": false,
                "speed": 40,
                "decay": 0,
                "sync": false,
                "destroy": "none",
                "startValue": "random",
                "minimumValue": 0.1
                }
            },
            "zIndex": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": 0,
                "opacityRate": 1,
                "sizeRate": 1,
                "velocityRate": 1
            },
            "life": {
                "count": 0,
                "delay": {
                "random": {
                    "enable": false,
                    "minimumValue": 0
                },
                "value": 0,
                "sync": false
                },
                "duration": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.0001
                },
                "value": 0,
                "sync": false
                }
            },
            "rotate": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": 0,
                "animation": {
                "enable": false,
                "speed": 0,
                "decay": 0,
                "sync": false
                },
                "direction": "clockwise",
                "path": false
            },
            "destroy": {
                "bounds": {},
                "mode": "none",
                "split": {
                "count": 1,
                "factor": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": 3
                },
                "rate": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": {
                    "min": 4,
                    "max": 9
                    }
                },
                "sizeOffset": true,
                "particles": {}
                }
            },
            "roll": {
                "darken": {
                "enable": false,
                "value": 0
                },
                "enable": false,
                "enlighten": {
                "enable": false,
                "value": 0
                },
                "mode": "vertical",
                "speed": 25
            },
            "tilt": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": 0,
                "animation": {
                "enable": false,
                "speed": 0,
                "decay": 0,
                "sync": false
                },
                "direction": "clockwise",
                "enable": false
            },
            "twinkle": {
                "lines": {
                "enable": false,
                "frequency": 0.05,
                "opacity": 1
                },
                "particles": {
                "enable": false,
                "frequency": 0.05,
                "opacity": 1
                }
            },
            "wobble": {
                "distance": 5,
                "enable": false,
                "speed": {
                "angle": 50,
                "move": 10
                }
            },
            "orbit": {
                "animation": {
                "count": 0,
                "enable": false,
                "speed": 1,
                "decay": 0,
                "sync": false
                },
                "enable": false,
                "opacity": 1,
                "rotation": {
                "random": {
                    "enable": false,
                    "minimumValue": 0
                },
                "value": 45
                },
                "width": 1
            },
            "links": {
                "blink": false,
                "color": {
                "value": "#ffffff"
                },
                "consent": false,
                "distance": 500,
                "enable": false,
                "frequency": 1,
                "opacity": 0.4,
                "shadow": {
                "blur": 5,
                "color": {
                    "value": "#000"
                },
                "enable": false
                },
                "triangles": {
                "enable": false,
                "frequency": 1
                },
                "width": 2,
                "warp": false
            },
            "repulse": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": 0,
                "enabled": false,
                "distance": 1,
                "duration": 1,
                "factor": 1,
                "speed": 1
            }
            },
            "pauseOnBlur": false,
            "pauseOnOutsideViewport": false,
            "smooth": false,
            "zLayers": 100
        },
        {
            "autoPlay": true,
            "background": {
            "color": {
                "value": "none"
            },
            "image": "",
            "position": "",
            "repeat": "",
            "size": "",
            "opacity": 1
            },
            "backgroundMask": {
            "composite": "destination-out",
            "cover": {
                "color": {
                "value": "#fff"
                },
                "opacity": 1
            },
            "enable": false
            },
            "defaultThemes": {},
            "delay": 0,
            "fullScreen": {
            "enable": false,
            "zIndex": 9999
            },
            "detectRetina": true,
            "duration": 0,
            "fpsLimit": 120,
            "interactivity": {
            "detectsOn": "parent",
            "events": {
                "onClick": {
                "enable": false,
                "mode": []
                },
                "onDiv": {
                "selectors": [],
                "enable": false,
                "mode": [],
                "type": "circle"
                },
                "onHover": {
                "enable": false,
                "mode": [],
                "parallax": {
                    "enable": false,
                    "force": 2,
                    "smooth": 10
                }
                },
                "resize": {
                "delay": 0.5,
                "enable": true
                }
            },
            "modes": {
                "attract": {
                "distance": 200,
                "duration": 0.4,
                "easing": "ease-out-quad",
                "factor": 1,
                "maxSpeed": 50,
                "speed": 1
                },
                "bounce": {
                "distance": 200
                },
                "bubble": {
                "distance": 200,
                "duration": 0.4,
                "mix": false,
                "divs": {
                    "distance": 200,
                    "duration": 0.4,
                    "mix": false,
                    "selectors": []
                }
                },
                "connect": {
                "distance": 80,
                "links": {
                    "opacity": 0.5
                },
                "radius": 60
                },
                "grab": {
                "distance": 100,
                "links": {
                    "blink": false,
                    "consent": false,
                    "opacity": 1
                }
                },
                "push": {
                "default": true,
                "groups": [],
                "quantity": 4
                },
                "remove": {
                "quantity": 2
                },
                "repulse": {
                "distance": 200,
                "duration": 0.4,
                "factor": 100,
                "speed": 1,
                "maxSpeed": 50,
                "easing": "ease-out-quad",
                "divs": {
                    "distance": 200,
                    "duration": 0.4,
                    "factor": 100,
                    "speed": 1,
                    "maxSpeed": 50,
                    "easing": "ease-out-quad",
                    "selectors": []
                }
                },
                "slow": {
                "factor": 3,
                "radius": 200
                },
                "trail": {
                "delay": 1,
                "pauseOnStop": false,
                "quantity": 1
                },
                "light": {
                "area": {
                    "gradient": {
                    "start": {
                        "value": "#ffffff"
                    },
                    "stop": {
                        "value": "#000000"
                    }
                    },
                    "radius": 1000
                },
                "shadow": {
                    "color": {
                    "value": "#000000"
                    },
                    "length": 2000
                }
                }
            }
            },
            "manualParticles": [],
            "particles": {
            "bounce": {
                "horizontal": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.1
                },
                "value": 1
                },
                "vertical": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.1
                },
                "value": 1
                }
            },
            "collisions": {
                "absorb": {
                "speed": 2
                },
                "bounce": {
                "horizontal": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0.1
                    },
                    "value": 1
                },
                "vertical": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0.1
                    },
                    "value": 1
                }
                },
                "enable": false,
                "mode": "bounce",
                "overlap": {
                "enable": true,
                "retries": 0
                }
            },
            "color": {
                "value": "#f7d4d4",
                "animation": {
                "h": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                },
                "s": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                },
                "l": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                }
                }
            },
            "groups": {},
            "move": {
                "angle": {
                "offset": 0,
                "value": 90
                },
                "attract": {
                "distance": 200,
                "enable": false,
                "rotate": {
                    "x": 3000,
                    "y": 3000
                }
                },
                "center": {
                "x": 50,
                "y": 50,
                "mode": "percent",
                "radius": 0
                },
                "decay": 0,
                "distance": {},
                "direction": "none",
                "drift": 0,
                "enable": false,
                "gravity": {
                "acceleration": 9.81,
                "enable": false,
                "inverse": false,
                "maxSpeed": 50
                },
                "path": {
                "clamp": true,
                "delay": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": 0
                },
                "enable": false,
                "options": {}
                },
                "outModes": {
                "default": "out",
                "bottom": "out",
                "left": "out",
                "right": "out",
                "top": "out"
                },
                "random": false,
                "size": false,
                "speed": 2,
                "spin": {
                "acceleration": 0,
                "enable": false
                },
                "straight": false,
                "trail": {
                "enable": false,
                "length": 10,
                "fill": {}
                },
                "vibrate": false,
                "warp": false
            },
            "number": {
                "density": {
                "enable": true,
                "width": 1920,
                "height": 1080
                },
                "limit": 0,
                "value": 200
            },
            "opacity": {
                "random": {
                "enable": false,
                "minimumValue": 0.1
                },
                "value": {
                "min": 0.1,
                "max": 1
                },
                "animation": {
                "count": 0,
                "enable": true,
                "speed": 0.25,
                "decay": 0,
                "sync": false,
                "destroy": "none",
                "startValue": "random"
                }
            },
            "reduceDuplicates": false,
            "shadow": {
                "blur": 0,
                "color": {
                "value": "#000"
                },
                "enable": false,
                "offset": {
                "x": 0,
                "y": 0
                }
            },
            "shape": {
                "options": {},
                "type": "circle"
            },
            "size": {
                "random": {
                "enable": false,
                "minimumValue": 1
                },
                "value": 9,
                "animation": {
                "count": 0,
                "enable": false,
                "speed": 5,
                "decay": 0,
                "sync": false,
                "destroy": "none",
                "startValue": "random"
                }
            },
            "stroke": {
                "width": 0
            },
            "zIndex": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": 0,
                "opacityRate": 1,
                "sizeRate": 1,
                "velocityRate": 1
            },
            "life": {
                "count": 0,
                "delay": {
                "random": {
                    "enable": false,
                    "minimumValue": 0
                },
                "value": 0,
                "sync": false
                },
                "duration": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.0001
                },
                "value": 0,
                "sync": false
                }
            },
            "rotate": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": {
                "min": 0,
                "max": 360
                },
                "animation": {
                "enable": true,
                "speed": 30,
                "decay": 0,
                "sync": false
                },
                "direction": "random",
                "path": false
            },
            "destroy": {
                "bounds": {},
                "mode": "none",
                "split": {
                "count": 1,
                "factor": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": 3
                },
                "rate": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": {
                    "min": 4,
                    "max": 9
                    }
                },
                "sizeOffset": true,
                "particles": {}
                }
            },
            "roll": {
                "darken": {
                "enable": true,
                "value": 25
                },
                "enable": true,
                "enlighten": {
                "enable": false,
                "value": 0
                },
                "mode": "vertical",
                "speed": {
                "min": 5,
                "max": 15
                }
            },
            "tilt": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": {
                "min": 0,
                "max": 360
                },
                "animation": {
                "enable": true,
                "speed": 30,
                "decay": 0,
                "sync": false
                },
                "direction": "random",
                "enable": true
            },
            "twinkle": {
                "lines": {
                "enable": false,
                "frequency": 0.05,
                "opacity": 1
                },
                "particles": {
                "enable": false,
                "frequency": 0.05,
                "opacity": 1
                }
            },
            "wobble": {
                "distance": 30,
                "enable": true,
                "speed": {
                "angle": {
                    "min": -7,
                    "max": 7
                },
                "move": 10
                }
            },
            "orbit": {
                "animation": {
                "count": 0,
                "enable": false,
                "speed": 1,
                "decay": 0,
                "sync": false
                },
                "enable": false,
                "opacity": 1,
                "rotation": {
                "random": {
                    "enable": false,
                    "minimumValue": 0
                },
                "value": 45
                },
                "width": 1
            },
            "links": {
                "blink": false,
                "color": {
                "value": "#fff"
                },
                "consent": false,
                "distance": 100,
                "enable": false,
                "frequency": 1,
                "opacity": 1,
                "shadow": {
                "blur": 5,
                "color": {
                    "value": "#000"
                },
                "enable": false
                },
                "triangles": {
                "enable": false,
                "frequency": 1
                },
                "width": 1,
                "warp": false
            },
            "repulse": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": 0,
                "enabled": false,
                "distance": 1,
                "duration": 1,
                "factor": 1,
                "speed": 1
            }
            },
            "pauseOnBlur": false,
            "pauseOnOutsideViewport": false,
            "responsive": [],
            "smooth": false,
            "style": {},
            "themes": [],
            "zLayers": 100,
            "emitters": []
        },
        {
            "autoPlay": true,
            "background": {
            "color": {
                "value": "none"
            },
            },
            "backgroundMask": {
            "composite": "destination-out",
            "cover": {
                "color": {
                "value": "#fff"
                },
                "opacity": 1
            },
            "enable": false
            },
            "defaultThemes": {},
            "delay": 0,
            "fullScreen": {
            "enable": false,
            "zIndex": 9999
            },
            "detectRetina": true,
            "duration": 0,
            "fpsLimit": 120,
            "interactivity": {
            "detectsOn": "parent",
            "events": {
                "onClick": {
                "enable": false,
                "mode": []
                },
                "onDiv": {
                "selectors": [],
                "enable": false,
                "mode": [],
                "type": "circle"
                },
                "onHover": {
                "enable": false,
                "mode": [],
                "parallax": {
                    "enable": false,
                    "force": 2,
                    "smooth": 10
                }
                },
                "resize": {
                "delay": 0.5,
                "enable": true
                }
            },
            "modes": {
                "attract": {
                "distance": 200,
                "duration": 0.4,
                "easing": "ease-out-quad",
                "factor": 1,
                "maxSpeed": 50,
                "speed": 1
                },
                "bounce": {
                "distance": 200
                },
                "bubble": {
                "distance": 200,
                "duration": 0.4,
                "mix": false,
                "divs": {
                    "distance": 200,
                    "duration": 0.4,
                    "mix": false,
                    "selectors": []
                }
                },
                "connect": {
                "distance": 80,
                "links": {
                    "opacity": 0.5
                },
                "radius": 60
                },
                "grab": {
                "distance": 100,
                "links": {
                    "blink": false,
                    "consent": false,
                    "opacity": 1
                }
                },
                "push": {
                "default": true,
                "groups": [],
                "quantity": 4
                },
                "remove": {
                "quantity": 2
                },
                "repulse": {
                "distance": 200,
                "duration": 0.4,
                "factor": 100,
                "speed": 1,
                "maxSpeed": 50,
                "easing": "ease-out-quad",
                "divs": {
                    "distance": 200,
                    "duration": 0.4,
                    "factor": 100,
                    "speed": 1,
                    "maxSpeed": 50,
                    "easing": "ease-out-quad",
                    "selectors": []
                }
                },
                "slow": {
                "factor": 3,
                "radius": 200
                },
                "trail": {
                "delay": 1,
                "pauseOnStop": false,
                "quantity": 1
                },
                "light": {
                "area": {
                    "gradient": {
                    "start": {
                        "value": "#ffffff"
                    },
                    "stop": {
                        "value": "#000000"
                    }
                    },
                    "radius": 1000
                },
                "shadow": {
                    "color": {
                    "value": "#000000"
                    },
                    "length": 2000
                }
                }
            }
            },
            "manualParticles": [],
            "particles": {
            "bounce": {
                "horizontal": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.1
                },
                "value": 1
                },
                "vertical": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.1
                },
                "value": 1
                }
            },
            "collisions": {
                "absorb": {
                "speed": 2
                },
                "bounce": {
                "horizontal": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0.1
                    },
                    "value": 1
                },
                "vertical": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0.1
                    },
                    "value": 1
                }
                },
                "enable": false,
                "mode": "bounce",
                "overlap": {
                "enable": true,
                "retries": 0
                }
            },
            "color": {
                "value": "#ffadad",
                "animation": {
                "h": {
                    "count": 0,
                    "enable": true,
                    "offset": 0,
                    "speed": 50,
                    "decay": 0,
                    "sync": false
                },
                "s": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                },
                "l": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                }
                }
            },
            "groups": {},
            "move": {
                "angle": {
                "offset": 0,
                "value": 90
                },
                "attract": {
                "distance": 200,
                "enable": false,
                "rotate": {
                    "x": 3000,
                    "y": 3000
                }
                },
                "center": {
                "x": 50,
                "y": 50,
                "mode": "percent",
                "radius": 0
                },
                "decay": 0.1,
                "distance": {},
                "direction": "none",
                "drift": 0,
                "enable": true,
                "gravity": {
                "acceleration": 10,
                "enable": true,
                "inverse": false,
                "maxSpeed": 50
                },
                "path": {
                "clamp": true,
                "delay": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": 0
                },
                "enable": false,
                "options": {}
                },
                "outModes": {
                "default": "destroy",
                "bottom": "destroy",
                "left": "destroy",
                "right": "destroy",
                "top": "none"
                },
                "random": false,
                "size": false,
                "speed": {
                "min": 10,
                "max": 20
                },
                "spin": {
                "acceleration": 0,
                "enable": false
                },
                "straight": false,
                "trail": {
                "enable": false,
                "length": 10,
                "fill": {}
                },
                "vibrate": false,
                "warp": false
            },
            "number": {
                "density": {
                "enable": false,
                "width": 1920,
                "height": 1080
                },
                "limit": 0,
                "value": 0
            },
            "opacity": {
                "random": {
                "enable": false,
                "minimumValue": 0.1
                },
                "value": {
                "min": 0,
                "max": 1
                },
                "animation": {
                "count": 0,
                "enable": true,
                "speed": 2,
                "decay": 0,
                "sync": false,
                "destroy": "min",
                "startValue": "max",
                "minimumValue": 0
                }
            },
            "reduceDuplicates": false,
            "shadow": {
                "blur": 0,
                "color": {
                "value": "#000"
                },
                "enable": false,
                "offset": {
                "x": 0,
                "y": 0
                }
            },
            "shape": {
                "options": {},
                "type": "square"
            },
            "size": {
                "random": {
                "enable": true,
                "minimumValue": 2
                },
                "value": {
                "min": 2,
                "max": 4
                },
                "animation": {
                "count": 0,
                "enable": false,
                "speed": 5,
                "decay": 0,
                "sync": false,
                "destroy": "none",
                "startValue": "random"
                }
            },
            "stroke": {
                "width": 0,
                "color": {
                "value": "",
                "animation": {
                    "h": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 0,
                    "decay": 0,
                    "sync": false
                    },
                    "s": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                    },
                    "l": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                    }
                }
                }
            },
            "zIndex": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": 0,
                "opacityRate": 1,
                "sizeRate": 1,
                "velocityRate": 1
            },
            "life": {
                "count": 1,
                "delay": {
                "random": {
                    "enable": false,
                    "minimumValue": 0
                },
                "value": 0,
                "sync": false
                },
                "duration": {
                "random": {
                    "enable": false,
                    "minimumValue": 0.0001
                },
                "value": 5,
                "sync": true
                }
            },
            "rotate": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": {
                "min": 0,
                "max": 360
                },
                "animation": {
                "enable": true,
                "speed": 60,
                "decay": 0,
                "sync": false
                },
                "direction": "random",
                "path": false
            },
            "destroy": {
                "bounds": {},
                "mode": "none",
                "split": {
                "count": 1,
                "factor": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": 3
                },
                "rate": {
                    "random": {
                    "enable": false,
                    "minimumValue": 0
                    },
                    "value": {
                    "min": 4,
                    "max": 9
                    }
                },
                "sizeOffset": true,
                "particles": {}
                }
            },
            "roll": {
                "darken": {
                "enable": true,
                "value": 25
                },
                "enable": true,
                "enlighten": {
                "enable": false,
                "value": 0
                },
                "mode": "vertical",
                "speed": {
                "min": 15,
                "max": 25
                }
            },
            "tilt": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": {
                "min": 0,
                "max": 360
                },
                "animation": {
                "enable": true,
                "speed": 60,
                "decay": 0,
                "sync": false
                },
                "direction": "random",
                "enable": true
            },
            "twinkle": {
                "lines": {
                "enable": false,
                "frequency": 0.05,
                "opacity": 1
                },
                "particles": {
                "enable": false,
                "frequency": 0.05,
                "opacity": 1
                }
            },
            "wobble": {
                "distance": 30,
                "enable": true,
                "speed": {
                "angle": {
                    "min": -15,
                    "max": 15
                },
                "move": 10
                }
            },
            "orbit": {
                "animation": {
                "count": 0,
                "enable": false,
                "speed": 1,
                "decay": 0,
                "sync": false
                },
                "enable": false,
                "opacity": 1,
                "rotation": {
                "random": {
                    "enable": false,
                    "minimumValue": 0
                },
                "value": 45
                },
                "width": 1
            },
            "links": {
                "blink": false,
                "color": {
                "value": "#fff"
                },
                "consent": false,
                "distance": 100,
                "enable": false,
                "frequency": 1,
                "opacity": 1,
                "shadow": {
                "blur": 5,
                "color": {
                    "value": "#000"
                },
                "enable": false
                },
                "triangles": {
                "enable": false,
                "frequency": 1
                },
                "width": 1,
                "warp": false
            },
            "repulse": {
                "random": {
                "enable": false,
                "minimumValue": 0
                },
                "value": 0,
                "enabled": false,
                "distance": 1,
                "duration": 1,
                "factor": 1,
                "speed": 1
            }
            },
            "pauseOnBlur": false,
            "pauseOnOutsideViewport": false,
            "responsive": [],
            "smooth": false,
            "style": {},
            "themes": [],
            "zLayers": 100,
            "emitters": {
            "autoPlay": true,
            "fill": true,
            "life": {
                "wait": false,
                "count": 0,
                "delay": 0.4,
                "duration": 0.1
            },
            "rate": {
                "quantity": 150,
                "delay": 0.1
            },
            "shape": "square",
            "startCount": 0,
            "size": {
                "mode": "percent",
                "height": 0,
                "width": 0
            },
            "particles": {},
            "position": {}
            }
        }
    ]
    
    const particlesInit = async (main) => {
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };

    const particlesLoaded = useCallback(async container => {
        
    }, []);
    if(parseInt(props.effect)){
        return(
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={
                    effect[props.effect-1]
                }
            />
        )
    }else{
        return false;
    }
}

export const MemoEffect = React.memo(Effect);