import React, { useEffect, useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export default function Player(props) {
    const selectNum = props.music;

    const player = useRef(); // -1-
    useEffect(() => {
        player.current.audio.current.play();
    }, [selectNum]);

    return (
        <AudioPlayer
        autoPlay = {true}
        loop = {true}
        src={`./audio/music${selectNum}.mp3`}
        hasDefaultKeyBindings = {false}
        showJumpControls = {false}
        showDownloadProgress = {false}
        showFilledProgress = {false}
        volume={1}
        ref={player} // -2-
        />
    );
}