import React, { Fragment } from 'react'
import Header from './header';
import Footer from './footer';
import AOS from 'aos';
import './data/data';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// Direct React component imports
import {  Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay, Scrollbar } from 'swiper';
// Styles must use direct files imports
import 'swiper/swiper.min.css'; // core Swiper
import 'swiper/swiper-bundle.min.css'; // core Swiper
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faPlay, faCircleExclamation, faCircleXmark, faEnvelopeOpenText, faCalendarDays, faPersonWalkingArrowRight, faShare, faPeopleCarryBox, faImages, faWallet, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export default function Main(){
    AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 0, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 0, // the delay on throttle used while scrolling the page (advanced)

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 10, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 1000, // values from 0 to 3000, with step 50ms
        easing: 'ease-in-out', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });

    let mainSlideData = window.Indata.mainSlideData;
    let etcList = [
        {
            title:'동영상',
            icon:faVideo,
        },
        {
            title:'배경음악',
            icon:faPlay,
        },
        {
            title:'안내사항',
            icon:faCircleExclamation,
        },
        {
            title:'확대방지',
            icon:faCircleXmark,
        },
        {
            title:'축하메세지',
            icon:faEnvelopeOpenText,
        },
        {
            title:'D-day',
            icon:faCalendarDays,
        },
        {
            title:'화환보내기',
            icon:faPeopleCarryBox,
        },
        {
            title:'공유하기',
            icon:faShare,
        },
        {
            title:'갤러리',
            icon:faImages,
        },
        {
            title:'송금하기',
            icon:faWallet,
        },
        {
            title:'참석확인',
            icon:faPersonWalkingArrowRight,
        },
        {
            title:'식사확인',
            icon:faCircleCheck,
        },

    ]
    let fontList = window.Indata.fontList;
    return(
        <Fragment>
            <Header />
            <section className='main_sec1 inner_section'>
            <h1 className='hide'>메인배너</h1>
                <div className="section_inner">
                    <Swiper
                        modules={[Scrollbar, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        scrollbar={{ draggable: true }}
                        autoplay
                        >
                        {mainSlideData.map((item, idx)=>{
                            return(
                                <SwiperSlide key={idx}>
                                    {({ isActive }) => (
                                        <div className={(isActive)? 'main_slide main_slide1 ani' : 'main_slide main_slide1'}>
                                            <div>
                                                {item.title.map((item2, idx)=>{
                                                    return(
                                                        <h2 key={idx}>{item2}</h2>
                                                    )
                                                })}
                                                {item.description.map((item2, idx)=>{
                                                    return(
                                                        <h3 key={idx}>{item2}</h3>
                                                    )
                                                })}
                                                <button className={(item.button)? 'btn btn_reverse' : 'hide'} onClick={()=>{window.location.href='https://babacompany.co.kr'}}>{item.button}</button>
                                            </div>
                                            <div>
                                                <img src={item.img} alt={'메인슬라이드'+(idx+1)}/>
                                            </div>
                                        </div>
                                    )}
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </section>

            <section className='main_sec2'>
                <h1 className="hide">섹션2</h1>
                <div style={{flexBasis:'50%'}}>
                    <h2 data-aos="fade-up">다양한</h2>
                    <h2 data-aos="fade-up">디자인 샘플제공</h2>
                    <h3 data-aos="fade-up" data-aos-delay="200">톡톡튀는 원색부터</h3> 
                    <h3 data-aos="fade-up" data-aos-delay="200">파스텔톤의 스킨까지</h3> 
                    <h3 data-aos="fade-up" data-aos-delay="200">바바만의 다양한 디자인을 제공합니다.</h3>
                </div>
                <div style={{flexBasis:'50%',display:'flex',justifyContent:'center'}}>
                    <img data-aos="fade-in"  data-aos-duration="1000" src="./img/main/main2_img1.png" alt="" />
                </div>
            </section>

            <section className='main_sec3'>
                <h1 className="hide">섹션3</h1>
                <div>
                    <h2 data-aos="fade-up">간편하고 다양한</h2>
                    <h2 data-aos="fade-up">서비스 제공</h2>
                    <h3 data-aos="fade-up" data-aos-delay="200">축의금 송금하기와 카카오맵을 연동한 길찾기 서비스,</h3>
                    <h3 data-aos="fade-up" data-aos-delay="200">최대 30장까지 업로드 할 수 있는 갤러리 등</h3>
                    <h3 data-aos="fade-up" data-aos-delay="200">다양한 서비스가 제공됩니다.</h3>
                </div>
                <div>
                    <img data-aos="fade-in"  data-aos-duration="1000" src="./img/main/main3_img1.svg" alt="" />
                </div>
            </section>

            <section className='main_sec main_sec4 inner_section'>
                <div className="block"></div>
                <div className="section_inner">
                    <h1 className="hide">섹션4</h1>
                    <div>
                        <h2 data-aos="fade-up">불편함 NO!</h2>
                        <h2 data-aos="fade-up">원클릭 화환 보내기!</h2>
                        <h3 data-aos="fade-up" data-aos-delay="200">특별한날 하객들이 신랑, 신부에게 소중한 마음을 </h3>
                        <h3 data-aos="fade-up" data-aos-delay="200">전달해드릴 수 있도록 주문 방식을 간소화하였습니다.</h3>
                    </div>
                    <div>
                        <img data-aos="fade-in"  data-aos-duration="1000" src="./img/main/main4_img1.svg" alt="화환보내기 이미지" />
                    </div>
                </div>
                <div className="block"></div>
            </section>

            <section className='main_sec main_sec5 inner_section'>
                <div className="block"></div>
                <div className="section_inner">
                    <h1 className="hide">섹션5</h1>
                    <div>
                        <h2 data-aos="fade-up">우리에게 어울리는</h2>
                        <h2 data-aos="fade-up">글꼴로 청첩창을!</h2>
                        <h3 data-aos="fade-up" data-aos-delay="200">다채로운 끌꼴로 회원님만의 차별화된</h3>
                        <h3 data-aos="fade-up" data-aos-delay="200">청첩장을 구성 하실 수 있습니다.</h3>
                    </div>
                    <div className='font_area'>
                        {fontList.map((item, idx)=>{
                            return(
                                <div data-aos="fade-up" data-aos-delay={idx*100} className="font_title" key={idx} style={{fontFamily:item.title_en}}>{item.title_ko}체</div>
                            )
                        })}
                    </div>
                </div>
                <div className="block"></div>
            </section>

            <section className='main_sec reverse main_sec6 inner_section'>
                <div className="block"></div>
                <div className="section_inner">
                    <h1 className="hide">섹션6</h1>
                    <div>
                        <h2 data-aos="fade-up">바바만의</h2>
                        <h2 data-aos="fade-up">다양한 옵션!</h2>
                        <h3 data-aos="fade-up" data-aos-delay="200">바바 모바일 청첩장만의 다양한 옵션들로</h3>
                        <h3 data-aos="fade-up" data-aos-delay="200">당신만의 하나뿐인 청첩장을 만들 수 있습니다.</h3>
                    </div>
                    <div>
                        <ul className='etc_list'>
                            {etcList.map((item, idx)=>{
                                if(idx === 6){
                                    return(
                                        <li data-aos="fade-up" data-aos-delay={idx*30} key={idx}>
                                            <div>
                                                <div style={{width:60,height:60,fontSize:36,color:'#828282'}}>
                                                    <img src="./img/main/etc_flower.png" alt="화환보내기" />
                                                </div>
                                                <p>{item.title}</p>
                                            </div>
                                        </li>
                                    )
                                }
                                else{
                                    return(
                                        <li data-aos="fade-up" data-aos-delay={idx*30} key={idx}>
                                            <div>
                                                <div style={{width:60,height:60,fontSize:36,color:'#828282'}}>
                                                    <FontAwesomeIcon icon={item.icon}></FontAwesomeIcon>
                                                </div>
                                                <p>{item.title}</p>
                                            </div>
                                        </li>
                                    )
                                }
                                
                            })}
                        </ul>
                    </div>
                </div>
                <div className="block"></div>
            </section>

            <section className='main_sec reverse main_sec7 inner_section'>
                <div className="block"></div>
                <div className="section_inner">
                    <h1 className="hide">섹션7</h1>
                    <div>
                        <h2 data-aos="fade-up">나만의 스타일로</h2>
                        <h2 data-aos="fade-up">청첩장 꾸미기!</h2>
                        <h3 data-aos="fade-up" data-aos-delay="200">나만의 스타일과 기호에 따라 청첩장 내부 순서를</h3>
                        <h3 data-aos="fade-up" data-aos-delay="200">바꿀 수 있습니다.</h3>
                    </div>
                    <div>
                        <ul data-aos="fade-up" data-aos-delay="400">
                            <li>인사말</li>
                            <li>갤러리</li>
                            <li>비디오</li>
                            <li>축의금</li>
                        </ul>
                    </div>
                </div>
                <div className="block"></div>
            </section>
            <Footer />
        </Fragment>
    )
}